/** @jsxImportSource theme-ui */

import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useLayoutEffect,
  useCallback,
  useContext
} from "react"
import { useTranslation } from "react-i18next"
import graphql from "babel-plugin-relay/macro"
import _ from "lodash"
import { v4 as uuid } from "uuid"
import {
  usePaginationFragment,
  useMutation,
  useSubscription
} from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleNotch,
  faArrowUp,
  faEllipsisVertical,
  faFolder,
  faStar,
  faUser,
  faTimes,
  faHeadset,
  faRobot,
  faLink,
  faChevronLeft,
  faUserTag,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons"
import { Flex, Box } from "theme-ui"
import ReactTooltip from "../ReactTooltip/ReactTooltip"
import Select from "react-select"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ContentLoader from "react-content-loader"
import { useHistory } from "yarr"
import qs from "query-string"

import ChatVisualizer from "../ChatVisualizer/ChatVisualizer"
import MemberContainer from "../MemberContainer/MemberContainer"
import {
  ConversationHistorySubscription,
  ConversationHistoryChatStatusSubscription,
  ConversationHistoryCreateChatMutation,
  ConversationHistoryCreateInternalChatMutation,
  ConversationHistorySignUrlMutation,
  ConversationHistoryToggleMemberLiveChatMutation,
  ConversationHistoryAssignThreadMutation,
  ConversationHistoryUpdateThreadFolderMutation,
  readThreadMutation,
  unreadThreadMutation,
} from "./ConversationHistoryQuery"

import * as Styles from "./ConversationHistoryStyles"
import IconContainer from "../IconContainer/IconContainer"
import IntegrationIconDisplay from "../IntegrationIconDisplay/IntegrationIconDisplay"
import { generalStyles } from "../../CommonStyles"
import Tag from "../Tag/Tag"
import ScopedTag from "../ScopedTag/ScopedTag"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import PopperContainer from "../Radiate/PopperContainer/PopperContainer"
import { MAX_FILES_TO_UPLOAD } from "../ConversationHistoryPreview/ConversationHistoryPreview"

import theme from "../../stylesheets/theme"
import { IconButton } from "../../pages/Inbox/InboxStyles"
import { useTransition } from "react"
import { LOAD_MORE_CHAT_QUANTITY, FOLLOW_UP_COLOR } from "../../const"
import Modal from "../Radiate/Modal/Modal"
import TextOptionModal from "../TextOptionModal/TextOptionModal"
import { blobToFile, useAudioRecorder } from "../../hooks/useAudioRecorder"
import iconFolderMove from "../../public/img/icon-folder-move.svg"
import { ChatFooter } from "./ChatFooter"
import { UserContext } from "../../contexts/UserContext"
import { ReplyToContext } from "../ChatVisualizer/ReplyPreview/ReplyPreview"
import { GROUP_PREFIX } from "../../const"


const WOZTELL_URL = process.env.REACT_APP_WOZTELL_URL
const OWN_DOMAIN = process.env.REACT_APP_OWN_DOMAIN
const ROUTER_BASE = process.env.REACT_APP_ROUTER_BASE

const DEFAULT_CHAT_PAGE_SIZE = 10

const HeaderLoader = (props) => (
  <ContentLoader
    speed={2}
    width={410}
    height={60}
    viewBox="0 0 410 60"
    backgroundColor="#e6e6e6"
    foregroundColor="#f0f0f0"
    {...props}
  >
    <rect x="48" y="34" rx="5" ry="5" width="100" height="10" />
    <circle cx="20" cy="30" r="20" />
    <rect x="48" y="14" rx="6" ry="6" width="160" height="12" />
  </ContentLoader>
)

let readThreadClientMutationId = 0
let unreadThreadClientMutationId = 0

const ConversationHistory = ({
  mobile,
  show,
  threadId,
  onChangeNavHint,
  onClose = () => {},
  ...props
}) => {
  const { t } = useTranslation("common")
  const { location } = useHistory()
  const query = qs.parse(location.search)
  const embedded = query.embed === "woztell"
  const { isSuperAdmin, isSuperAuditor } = useContext(UserContext)
  const withoutThreadId = !threadId
  const [loading, startTransition] = useTransition()
  const {
    data,
    loadPrevious,
    hasPrevious,
    isLoadingPrevious,
    refetch
  } = usePaginationFragment(
    graphql`
      fragment ConversationHistory_userViewer on UserScope
      @argumentDefinitions(
        last: { type: "Int", defaultValue: 10 }
        first: { type: "Int", defaultValue: 0 }
        before: { type: "String" }
        after: { type: "String" }
        threadId: { type: "ID" }
        withoutThreadId: { type: "Boolean!" }
      )
      @refetchable(queryName: "ConversationHistoryPagination") {
        users {
          wUserId
          firstName
          lastName
          email {
            email
          }
        }
        wUserId
        thread (
          threadId: $threadId 
        ) @skip(if: $withoutThreadId) {
          threadId
          unreadCount
          title
          folderId
          folder {
            wChannelIds
            isPrivate
            folderId
            name
            color
            userIds
            users {
              wUserId
              firstName
              lastName
              email {
                email
              }
              channels {
                wChannelId
              }
            }
          }
          member {
            id
            meta
            externalId
            externalProfile
            inboxProfile {
              firstName
              lastName
              name
              profilePicUrl
            }
            liveChat
          }
          wMemberId
          wChannelId
          wChannel {
            name
            integrationIcon
            integrationName
            integrationId
            environments
            wChannelId
            wAppId
            supportedMediaTypes {
              audio {
                codec
                type
              }
            }
          }
          inputActions {
            integrationIcon
            integrationName
            integrationDescription
            integrationId
            integrationBuild
            iframeContext {
              payload
              signedContext
            }
            contextToken
            customActions {
              url
              body
              headers
              method
              description
              modalSchema
              modalView
              title
              type
              modalWidth
            }
          }
          resourceTemplates(for:["BOT", "MEMBER"]) {
            _id 
            preview
            replyToPreview
            alias
            for
          }
          wUserId
          user {
            wUserId
            email {
              email
              verified
            }
            firstName
            lastName
          }
          etag
          chats (
            last: $last,
            first: $first,
            before: $before,
            after: $after,
          ) @connection(key: "ConversationHistory_chats") {
            edges {
              node {
                _id
                threadId
                messageEvent
                replyToMessageEvent
                createdAt
                deliveredAt
                failedAt
                readAt
                sentAt
                from
                internal
                wUserId
                error
                mentions {
                  wUserId
                  firstName
                  lastName
                  email {
                    email
                  }
                }
                agent {
                  name
                  icon
                }
                user {
                  profilePic
                  email {
                    email
                  }
                }
                meta
                clientMutationId
              }
            }
          }
        }
        role
        foldersForThread (
          threadId: $threadId 
        ) @skip(if: $withoutThreadId) {
          folderId
          name
        }
        usersForThread (
          threadId: $threadId
        ) @skip(if: $withoutThreadId) {
          wUserId
          firstName
          lastName
          profilePic
          email {
            email
          }
        }
      }
    `,
    props.data.userViewer
  )

  const cachedOnMarkUnread = useCallback((numberOfUnreadMessages) => {
    unreadThread({
      threadId: data?.thread?.threadId,
      unreadCount: numberOfUnreadMessages,
    })
  }, [data?.thread?.threadId])

  const cachedOnResendFunction = useCallback(({ clientMutationId, messageEvent }) => {
    sendEvent({
      type: messageEvent?.type,
      data: messageEvent?.data,
      clientMutationId
    })
  }, [])

  function passFolderNameThroughTranslation(folder) {
    const id = folder?.folderId
    if (id === "main") {
      return t?.("folders.main")
    }
    if (id === "done") {
      return t?.("folders.done")
    }
    if (id === "spam") {
      return t?.("folders.spam")
    }
    return folder?.name
  }

  const propsChats = (data?.thread?.chats?.edges || []).map(o => o.node)
  const [newMessagesCount, setNewMessagesCount] = useState(0)
  const scrollTargetRef = useRef(null)
  const autoscrolled = useRef(false)
  const dragndropSupport = useRef(false)
  const prevScrollTop = useRef(null)
  const textEditorRef = useRef()
  const [initialized, setInitialized] = useState(false)
  const [loadingMoreChats, setLoadingMoreChats] = useState(false)
  const [isDraggingOverTextArea, setIsDraggingOverTextArea] = useState(false)
  const [textOptions, setTextOptions] = useState([])
  const [openTextOptionModal, setOpenTextOptionModal] = useState(false)
  const [chats, setChats] = useState(propsChats)
  const [statusChats, setStatusChats] = useState([])
  const [tempDuplicatedChats, setTempDuplicatedChats] = useState([])
  const [showMoveFolderDropDown, setShowMoveFolderDropDown] = useState(false)
  const [showAssignDropDown, setShowAssignDropDown] = useState(false)
  const [showSendOptions, setShowSendOptions] = useState(false)
  const [openMemberInfo, setOpenMemberInfo] = useState(false)
  const [moveFolderError, setMoveFolderError] = useState("")
  const [assignedError, setAssignedError] = useState("")
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [showTopButtonsWrapper, setShowTopButtonsWrapper] = useState(false)
  const [ownUserId, setOwnUserId] = useState(data?.wUserId)
  const [copyPermalinkText, setCopyPermalinkText] = useState(t?.("chat.copy_permalink"))
  const [filesToUpload, setFilesToUpload] = useState(null)
  const [previews, setPreviews] = useState(null)
  const [isAudioEnabled, setIsAudioEnabled] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [failedToUploadError, setFailedToUploadError] = useState({ error: false, message: "" })
  const [tooManyFilesAttachedError, setTooManyFilesAttachedError] = useState({ error: false, message: "" })
  const [isSendingAudio, setIsSendingAudio] = useState(false)
  const [mentions, setMentions] = useState([])
  const [randomKey, setRandomKey] = useState(uuid())
  const [replyToContext, setReplyToContext] = useState(null)
  let usersForMention = data?.thread?.folder?.users || []
  const threadFolderId = data?.thread?.folder?.folderId
  if (!data?.thread?.folder?.isPrvate && threadFolderId !== "main" && threadFolderId !== "spam" && threadFolderId !== "done") {
    usersForMention = usersForMention.filter(o => {
      const threadFolderChannelIds = data?.thread?.folder?.wChannelIds || []
      const userChannelIds = o.channels.map(c => c.wChannelId)
      return _.intersection(threadFolderChannelIds, userChannelIds).length
    })
  }

  const {
    startRecording,
    stopRecording,
    cancelRecording,
    togglePauseResume,
    isRecording,
    isPaused,
    audioUrl,
    errorName,
    fileTooLarge,
    isLoadingAudio,
    recordingBlob,
    mimeType,
    blobDuration,
  } = useAudioRecorder({
      setIsAudioEnabled,
      setIsSendingAudio,
    })

  const isReadyToSendAudio = isAudioEnabled && !isRecording && audioUrl && !fileTooLarge && !errorName


  function getRecordingTextToDisplay() {
    if (isRecording && !isPaused) return t?.("audio_recorder.recording")
    else if (isRecording && isPaused) return t?.("audio_recorder.paused")
    return t?.("audio_recorder.stopped")
  }
  
  const [commitChat] = useMutation(
    ConversationHistoryCreateChatMutation
  )
  const [commitInternalChat] = useMutation(
    ConversationHistoryCreateInternalChatMutation
  )
  const [commitUpdateThreadFolder, isInFlightUpdateThreadFolder] = useMutation(
    ConversationHistoryUpdateThreadFolderMutation
  )
  const [commitAssignThread, isInFlightAssignThread] = useMutation(
    ConversationHistoryAssignThreadMutation
  )
  const [commitSignUrl] = useMutation(
    ConversationHistorySignUrlMutation
  )
  const [commitLiveChat, isInFlightLiveChat] = useMutation(
    ConversationHistoryToggleMemberLiveChatMutation
  )
  const [commitReadThread] = useMutation(
    readThreadMutation
  )
  const [commitUnreadThread] = useMutation(
    unreadThreadMutation
  )

  useEffect(() => {
    const div = document.createElement("div")
    const support =  (("draggable" in div) || ("ondragstart" in div && "ondrop" in div)) && "FileReader" in window
    dragndropSupport.current = support
  }, [])
  
  useEffect(() => {
    if (data?.wUserId) {
      setOwnUserId(data?.wUserId)
    }
  }, [])

  useEffect(() => {
    setChats((prevChats) => {
      // console.log("propsChats", propsChats)
      const processedPropsChats = _.differenceBy(propsChats, tempDuplicatedChats, "_id")
      // console.log("processedPropsChats", processedPropsChats)
      const newChatsFromProps = _.differenceBy(processedPropsChats, prevChats, "_id")
      // console.log("newChatsFromProps", newChatsFromProps)
      if (newChatsFromProps?.[0]?.threadId !== prevChats?.[0]?.threadId) {
        // switched to different thread
        return propsChats
      }
      if (newChatsFromProps?.length) {
        let clonedChats = _.cloneDeep(prevChats)
        const chatsToUnshift = []
        newChatsFromProps.forEach((o, index) => {
          if (o?.clientMutationId) {
            const targetIndex = prevChats.findIndex(c => c.clientMutationId === o.clientMutationId)
            // console.log("prevChats", JSON.stringify(prevChats, null, 2))
            // console.log("targetIndex", targetIndex)
            if (targetIndex > -1) {
              clonedChats[targetIndex] = o
            } else {
              clonedChats.push(o)
            }
          } else if (clonedChats.findIndex(c => c._id === o._id) > -1) {
            // newChats already in state, no need to push to state
            setTempDuplicatedChats((prev) => [...prev, o])
          } else if (o.createdAt < clonedChats?.[0]?.createdAt) {
            // When new chats are from loading previous conversation history, push to array chatsToUnshift to retain the order and unshift them afterwards
            chatsToUnshift.push(o)
          } else {
            clonedChats.push(o)
          }
        })
        if (chatsToUnshift?.length) {
          clonedChats.unshift(...chatsToUnshift)
        }
        return clonedChats
      }
      return prevChats
    })
  }, [JSON.stringify(propsChats.map(o => o?._id))])

  useLayoutEffect(() => {
    if (prevScrollTop.current) {
      const newScrollTop = chatVisualizerRef.current?.offsetHeight
      if (body?.current) {
        body.current.scrollTop = newScrollTop - prevScrollTop.current + 20
      }
    }
  }, [JSON.stringify(chats.map(o => o?._id))])

  useEffect(() => {
    setStatusChats([])
    setTempDuplicatedChats([])
    startTransition(() => {
      refetch({
        threadId: threadId,
        withoutThreadId: !threadId
      }, {
        fetchPolicy: "network-only",
      })
    })
    autoscrolled.current = false
    setInitialized(false)
    setNewMessagesCount(0)
    setIsUploading(false)
    setPreviews(null)
    setFilesToUpload(null)
    cancelRecording()
  }, [threadId, refetch])

  function scrollNewMessagesIntoPosition() {
    if (scrollTargetRef.current) {
      const newMessageElPosition = scrollTargetRef.current.getBoundingClientRect().top
      if (chatVisualizerRef.current.getBoundingClientRect().height > body.current.getBoundingClientRect().height) {
        if (newMessageElPosition - body.current.getBoundingClientRect().top < (body.current.getBoundingClientRect().height / 2)) {
          const scrollPosition = (-chatVisualizerRef.current.getBoundingClientRect().top + newMessageElPosition + 48) - (body.current.getBoundingClientRect().height / 2)
          body.current.scrollTo({
            top: scrollPosition,
            behavior: "smooth"
          })
        }
      }
    }
  }

  useEffect(() => {
    if (!initialized && threadId) {
      if (data?.thread?.unreadCount) {
        setNewMessagesCount(data.thread.unreadCount)
        if (data.thread.unreadCount >= DEFAULT_CHAT_PAGE_SIZE) {
          loadPrevious(data.thread.unreadCount - DEFAULT_CHAT_PAGE_SIZE + 10, {
            onComplete: (error) => {
              if (error) {
                console.log("error", error)
                setInitialized(true)
              } else {
                setTimeout(() => {
                  scrollNewMessagesIntoPosition()
                  readThread({
                    threadId: data.thread.threadId,
                    immediate: true
                  })
                  setInitialized(true)
                }, 3000)
              }
            }
          })
        } else {
          setTimeout(() => {
            scrollNewMessagesIntoPosition()
            readThread({
              threadId: data.thread.threadId,
              immediate: true
            })
            setInitialized(true)
          }, 2000)
        }
      } else if (data?.thread?.threadId) {
        console.log("has threadId")
        setTimeout(() => {
          setInitialized(true)
        }, 1000)
      }
    }
  }, [initialized, data?.thread?.unreadCount, data?.thread?.threadId, threadId])

  useEffect(() => {
    setSelectedUser(data?.thread?.wUserId ?? null)
  }, [data?.thread?.wUserId])

  const readThread = ({
    threadId,
    immediate = false,
  }) => {
    if (threadId) {
      const mutationParams = {
        variables: {
          input: {
            clientMutationId: readThreadClientMutationId++,
            threadId,
            immediate,
          },
        },
        onCompleted: (response, errs) => {
          if (!response.readThread?.error) {
            console.log("read thread error", response.readThread?.error?.message)
          }
        },
        onError: (err) => {
          const errorMsg = err.res?.json?.errors[0]?.message || err
          console.log("read thread error", errorMsg)
        }
      }
      commitReadThread(mutationParams)
    }
  }

  const unreadThread = ({
    threadId,
    unreadCount,
  }) => {
    if (threadId) {
      const mutationParams = {
        variables: {
          input: {
            clientMutationId: unreadThreadClientMutationId++,
            threadId,
            unreadCount,
          },
        },
        onCompleted: (response, errs) => {
          if (response.unreadThread?.error) {
            console.log("Unread thread error", response.unreadThread?.error?.message)
          } else {
            onClose()
          }
        },
        onError: (err) => {
          const errorMsg = err.res?.json?.errors[0]?.message || err
          console.log("Unread thread error", errorMsg)
        }
      }
      commitUnreadThread(mutationParams)
    }
  }

  const memoizedValue = useMemo(() => {
    if (threadId) {
      return {
        variables: { threadId: threadId },
        subscription: ConversationHistorySubscription,
        updater: (store, data) => {
          const userViewer = store.getRoot().getLinkedRecord("userViewer")
          const thread = userViewer.getLinkedRecord("thread", { threadId })
          const chatsConnection = ConnectionHandler.getConnection(
            thread,
            "ConversationHistory_chats"
          )
          const newChat = store.getRootField("chat")
          const edge = ConnectionHandler.createEdge(store, chatsConnection, newChat, "ChatEdge")
          ConnectionHandler.deleteNode(chatsConnection, newChat.getDataID())
          ConnectionHandler.insertEdgeAfter(chatsConnection, edge)
          // readThread({ threadId })
        }
      }
    }
    return {
      variables: {
        threadId: "",
      },
      subscription: ConversationHistorySubscription
    }
  }, [threadId])

  const chatStatusMemoizedValue = useMemo(() => {
    if (threadId) {
      return {
        variables: { threadId: threadId },
        subscription: ConversationHistoryChatStatusSubscription,
        updater: (store, data) => {
          setStatusChats((c) => [...c, data?.chatStatus])
        }
      }
    }
    return {
      variables: {
        threadId: "",
      },
      subscription: ConversationHistoryChatStatusSubscription
    }
  }, [threadId])

  useSubscription(memoizedValue)
  useSubscription(chatStatusMemoizedValue)

  const body = useRef(null)
  const chatVisualizerRef = useRef(null)

  const loadMoreButton = ({ isWhatsappCloudThread }) => {
    if (!chats?.length) {
      return null
    }
    if (isLoadingPrevious) {
      return (
        <Styles.LoadMoreConversation
          isWhatsappCloudThread={isWhatsappCloudThread}
        >
          <FontAwesomeIcon
            icon={faCircleNotch}
            spin
          />
        </Styles.LoadMoreConversation>
      )
    }
    if (hasPrevious) {
      return (
        <Styles.LoadMoreConversation
          isWhatsappCloudThread={isWhatsappCloudThread}
          onClick={() => {
            setLoadingMoreChats(true)
            // console.log("curr", chatVisualizerRef)
            // console.log("offsetHeight", chatVisualizerRef.current?.offsetHeight)
            const oldScrollTop = _.clone(chatVisualizerRef.current?.offsetHeight)
            prevScrollTop.current = oldScrollTop
            loadPrevious(
              LOAD_MORE_CHAT_QUANTITY,
              {
                onComplete: (error) => {
                  setTimeout(() => {
                    setLoadingMoreChats(false)
                  }, 1000)
                  if (error) {
                    console.log("error", error)
                  }
                }
              }
            )
          }}
        >
          <FontAwesomeIcon
            className="icon"
            icon={faArrowUp}
          />
          <span>{t?.("chat.tap_to_load_more_conversations")}</span>
        </Styles.LoadMoreConversation>
      )
    }
    return (
      <Styles.LoadMoreConversation
        isWhatsappCloudThread={isWhatsappCloudThread}
      >{t?.("chat.start_of_conversation")}</Styles.LoadMoreConversation>
    )
  }

  const uploadFiles = async (selectedFiles) => {
    setIsUploading(true)
    const signUrl = (file) => {
      // console.log("file", file)
      return new Promise((resolve, reject) => {
        let clientMutationId = 0
        commitSignUrl({
          variables: {
            input: {
              fileName: file.name,
              fileType: file.type,
              wChannelId: data?.thread?.wChannelId,
              wMemberId: data?.thread?.wMemberId,
              clientMutationId: clientMutationId++
            },
          },
          onCompleted: (response, errs) => {
            // console.log("response", response)
            resolve(response?.signUrl?.signUrl)
          },
          onError: (err) => {
            console.log(err)
            reject(err)
          }
        })
      })
    }
    const urls = await Promise.all(selectedFiles.map(file => signUrl(file)))
    try {
      const uploads = await Promise.all(urls.map((o, i) => {
        console.log("o", o)
        const form = new FormData()
        _.forEach(o.fields, (value, key) => {
          form.append(key, value)
        })
        form.append("file", selectedFiles[i])
        return fetch(o.url, { method: "POST", body: form })
          .then((res) => {
            if (res.status === 204) {
              const split = o.fields.key.split("/")
              const encodedSplit = split.map(o => encodeURIComponent(o))
              return Promise.resolve({
                url: `${o.url}/${encodedSplit.join("/")}`,
                fileName: split[split.length - 1],
                fileType: selectedFiles[i].type,
                size: selectedFiles[i].size,
              })
            }
            return null
          })
      }))
      let fileType = "FILE"
      // console.log("uploads", uploads)
      uploads.forEach((obj) => {
        switch (obj.fileType) {
          case "image/png":
          case "image/jpeg":
          case "image/gif":
          case "image/bmp":
          case "image/vnd.microsoft.icon":
          case "image/image/svg+xml":
          case "image/tiff":
          case "image/webp":
            fileType = "IMAGE"
            break
          case "audio/aac":
          case "audio/x-midi":
          case "audio/midi":
          case "audio/mpeg":
          case "audio/ogg":
          case "audio/ogg; codecs=opus":
          case "audio/opus":
          case "audio/wav":
          case "audio/webm":
          case "audio/3gpp":
          case "audio/3gpp2":
          case "audio/x-m4a":
          case "audio/webm;codecs=opus":
          case "audio/webm;codecs=OPUS":
          case "audio/webm;codecs=pcm":
          case "audio/webm;codecs=PCM":
          case "audio/mp4":
            fileType = "AUDIO"
            break
          case "video/x-msvideo":
          case "video/mpeg":
          case "video/ogg":
          case "video/mp2t":
          case "video/webm":
          case "video/3gpp":
          case "video/3gpp2":
          case "video/quicktime":
            fileType = "VIDEO"
            break
          default:
            break
        }
        sendEvent({
          type: fileType,
          supportedMediaTypes: data?.thread?.wChannel?.supportedMediaTypes,
          fileType: obj.fileType,
          data: {
            url: obj.url
          }
        })
      })
    } catch (err) {
      console.error(err)
    } finally {
      setIsUploading(false)
      setPreviews(null)
      setFilesToUpload(null)
    }
  }

  const insertErrorToChats = ({ clientMutationId, error, chats }) => {
    let failed = chats.find((o) => {
      return o.clientMutationId === clientMutationId
    })
    // console.log("failed", failed)
    const index = chats.findIndex(o => o.clientMutationId === clientMutationId)
    // console.log("index", index)
    // failed.error = error
    const failedChat = {
      ...failed,
      createChatError: error
    }
    // console.log("failedChat", failedChat)
    const modChats = [
      ...chats.slice(0, index),
      failedChat,
      ...chats.slice(index + 1)
    ]
    return modChats
  }

  const assignThread = ({ userId }) => {
    return new Promise((resolve, reject) => {
      let clientMutationId = 0
      let variables = {
        threadId: threadId,
        clientMutationId: clientMutationId++,
        etag: data?.thread?.etag,
        wUserId: userId
      }
      const mutationParams = {
        variables: {
          input: variables,
        },
        onCompleted: (response, errs) => {
          if (!response.assignThread?.error) {
            setShowMoveFolderDropDown(false)
            setShowAssignDropDown(false)
            setMoveFolderError("")
            setAssignedError("")
            setSelectedFolder(null)
            setSelectedUser(null)
            resolve()
          }
        },
        onError: (err) => {
          const errorMsg = err.res?.json?.errors[0]?.message || err
          console.log("errorMsg", errorMsg)
          setAssignedError(errorMsg)
          alert(errorMsg)
          reject(errorMsg)
        }
      }
      commitAssignThread(mutationParams)
    })
  }

  const updateFolder = ({ folderId }) => {
    let clientMutationId = 0
    let variables = {
      threadId: threadId,
      clientMutationId: clientMutationId++,
      etag: data?.thread?.etag,
      folderId
    }
    const mutationParams = {
      variables: {
        input: variables,
      },
      onCompleted: (response, errs) => {
        if (!response.updateThreadFolder?.error) {
          setShowMoveFolderDropDown(false)
          setShowAssignDropDown(false)
          setMoveFolderError("")
          setAssignedError("")
          setSelectedFolder(null)
          setSelectedUser(null)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        setMoveFolderError(errorMsg)
      }
    }
    commitUpdateThreadFolder(mutationParams)
  }

  const toggleLiveChat = (liveChat) => {
    return new Promise((resolve, reject) => {
      let clientMutationId = 0
      let variables = {
        clientMutationId: clientMutationId++,
        liveChat,
        wMemberId: data?.thread?.wMemberId
      }

      const mutationParams = {
        variables: {
          input: variables,
        },
        onCompleted: (response, errs) => {
          if (!response.toggleLiveChat?.error) {
            resolve(response.toggleLiveChat)
          }
        },
        onError: (err) => {
          const errorMsg = err.res?.json?.errors[0]?.message || err
          console.error(errorMsg)
          reject(errorMsg)
        }
      }
      commitLiveChat(mutationParams)
    })
  }

  const sendEvent = ({
    type,
    data,
    clientMutationId, // from resend
    fileType,
    supportedMediaTypes,
  }) => {
    const messageEvent = {
      type,
      data,
    }

    if (replyToContext?.messageId) {
      messageEvent.data.reply_to = replyToContext.messageId
    }
    let newClientMutationId
    if (clientMutationId) {
      newClientMutationId = clientMutationId
    } else {
      newClientMutationId = uuid()
    }
    let internalChat = false
    if (type === "TEXT" && /^###.+/.test(data?.text ?? "")) {
      // is internal comment
      internalChat = true
      let splitTextByNewLine = data?.text?.split("\n")
      splitTextByNewLine[0] = /^###(.+)/.exec(splitTextByNewLine[0])[1]

      const chatContent = splitTextByNewLine.join("\n")
      messageEvent.data.text = chatContent
    }
    let isFileTypeAudio = type === "AUDIO" || false
    let isFileTypeSupported = false

    if (isFileTypeAudio) {
      isFileTypeSupported = checkIsFileTypeSupported({
        fileType,
        supportedAudioTypesInIntegration: supportedMediaTypes?.audio
      })
    }

    setChats((prevChats) => {
      let modChats = prevChats
      if (clientMutationId) {
        // is resend
        modChats = prevChats.filter(o => o?.clientMutationId !== newClientMutationId)
      }
      modChats = [
        ...modChats,
        {
          createdAt: new Date().getTime(),
          messageEvent: {
            ...messageEvent,
            ...(replyToContext?.messageId ? {
              replyTo: {
                messageId: replyToContext?.messageId,
              },
            }: {}),
          },
          ...(replyToContext?.messageId ? {
            replyToMessageEvent: replyToContext,
          } : {}),
          wUserId: ownUserId,
          from: internalChat ? "AGENT" : "BOT",
          threadId,
          internal: internalChat,
          clientMutationId: newClientMutationId,
          mentions,
        }
      ]
      return modChats
    })
    setReplyToContext(null)
    setMentions([])

    const createChatInput = {
      threadId,
      messageEvent,
      clientMutationId: newClientMutationId
    }

    if (isFileTypeAudio && !isFileTypeSupported) {
      createChatInput.transcoding = true
      createChatInput.fromType = {
        type: fileType?.split(";")?.[0] ?? "audio/mpeg",
        codec: fileType?.split(";")?.[1]?.split("=")[1] ?? null,
      }
      createChatInput.toType = supportedMediaTypes?.audio[0]
      createChatInput.url = messageEvent?.data?.url
    }
    const mutationParams = {
      variables: {
        input: createChatInput,
      },
      updater: (store) => {
        const rootField = store.getRootField(internalChat ? "createInternalChat" : "createChat")
        const error = rootField.getLinkedRecord("error")
        if (!error) {
          const userViewer = store.getRoot().getLinkedRecord("userViewer")
          const thread = userViewer.getLinkedRecord("thread", { threadId })
          let chatsConnection
          chatsConnection = ConnectionHandler.getConnection(
            thread,
            "ConversationHistory_chats"
          )
          
          const newChat = rootField.getLinkedRecord("chat")
          // console.log("newChat", newChat)
          newChat.setValue(newClientMutationId, "clientMutationId")
          // console.log("clientMutationId", newClientMutationId)
          if (chatsConnection) {
            const edge = ConnectionHandler.createEdge(store, chatsConnection, newChat, "ChatEdge")
            ConnectionHandler.insertEdgeAfter(chatsConnection, edge)
          }
        }
      },
      onCompleted: (response, errs) => {
        // console.log("onCompleted response", response)
        if (!response.createChat?.error) {
        } else {
          setChats((prevChats) => {
            return insertErrorToChats({ clientMutationId: newClientMutationId, error: internalChat ? response?.createInternalChat?.error?.message : response?.createChat?.error?.message, chats: prevChats })
          })
        }
      },
      onError: (err) => {
        console.log("chat error", err.res?.json?.errors[0]?.message)
        setChats((prevChats) => {
          return insertErrorToChats({
            clientMutationId: newClientMutationId,
            error: {
              message: err.res?.json?.errors[0]?.message
            },
            chats: prevChats
          })
        })
      }
    }
    if (internalChat) {
      commitInternalChat(mutationParams)
    } else {
      commitChat(mutationParams)
    }
  }

  // Detects if device is on iOS 
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test(userAgent) && ("standalone" in window.navigator) && (window.navigator.standalone)
  }

  const checkIsFileTypeSupported = ({
    fileType,
    supportedAudioTypesInIntegration
  }) => {
    let isSupported = false
    const seperateFileType = fileType.split(";")
    const audioType = supportedAudioTypesInIntegration?.find((o) => {
      if (seperateFileType?.length === 2) {
        return (o.type === seperateFileType[0]) && (o.codec === seperateFileType[1]?.split("=")[1])
      }
      return o.type === seperateFileType[0]
    })
    if (audioType) {
      isSupported = true
    } else {
      isSupported = false
    }
    console.log("is filetype supported", isSupported)

    return isSupported

  }

  const folderOptions = (data?.foldersForThread || []).map((f) => {
    return ({
      label: f.name,
      value: f.folderId,
      isDisabled: f.folderId === data.thread.folderId
    })
  })

  const userOptions = (data?.usersForThread || []).map((f) => {
    return ({
      label: f.wUserId === data.wUserId ? `${f?.email?.email} (${t?.("chat.you")})` : f?.email?.email,
      value: f.wUserId,
    })
  })

  let tooltipMessage
  if (data?.thread?.wUserId) {
    tooltipMessage = `${t?.("chat.assigned_to")}: ${data?.thread?.user?.email?.email}`
    if (data.thread.wUserId === data.wUserId) {
      tooltipMessage = `${t?.("chat.assigned_to")}: ${t?.("chat.you")} (${data?.thread?.user?.email?.email})`
    }
    tooltipMessage = `${tooltipMessage}<br/>${t?.("chat.click_to_reassign")}`
  } else {
    tooltipMessage = t?.("chat.assign_to")
  }
    
  const displayAssignedButton = (
    <Styles.AssignedToButton
      color="#405DE6"
      className="assigned-to-button"
      loading={isInFlightAssignThread}
      disabled={isInFlightLiveChat || isInFlightUpdateThreadFolder}
      sx={{ marginRight: "8px" }}
      icon={faUserTag}
      inverted={!data?.thread?.wUserId}
      primary={!!data?.thread?.wUserId}
      data-tooltip-html={tooltipMessage}
      data-tooltip-id="chat-section-tooltip"
      onClick={() => {
        if (!showAssignDropDown) {
          setShowAssignDropDown(true)
        } else {
          setShowAssignDropDown(false)
        }
      }}
    />
  )

  let woztellUrlFin
  const woztellUrlSplit = WOZTELL_URL.split("||")
  woztellUrlFin = woztellUrlSplit[0]

  let shortcutUrl
  if (embedded) {
    shortcutUrl = `${woztellUrlFin}/inbox?thread=${threadId}`
  } else {
    shortcutUrl = `${OWN_DOMAIN}${ROUTER_BASE}/inbox?thread=${threadId}`
  }
  

  const convertFilesToBlobs = async (files) => {
    const promisesOfFiles = Array.from(files).map(file => {
      return new Promise(resolve => {
        let fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.addEventListener("load", () => {
          resolve({
            blob: fileReader.result,
            name: file.name,
            size: file.size,
            type: file.type,
            fileExtension: file.name.split(".").pop()
          })
        })
      })
    })

    const previewsOfFilesUploadedByClient = await Promise.all(promisesOfFiles)
    setPreviews(previewsOfFilesUploadedByClient.slice(0, MAX_FILES_TO_UPLOAD))
  }

  const assignPopup = ({ member, isSuperAdmin, isSuperAuditor }) => {
    const shouldDisableConfirm = isInFlightLiveChat || isInFlightUpdateThreadFolder || selectedUser === data?.thread?.wUserId
    return (
      <Styles.MoveFolderContainer
        showError={assignedError}
      >
        <div className="label">{t?.("chat.assign_to")}</div>
        <div className="separator"></div>
        <Box p={3}>
          <Select
            isDisabled={isSuperAdmin || isSuperAuditor}
            options={userOptions}
            placeholder={t?.("placeholders.select_user")}
            styles={generalStyles}
            value={userOptions.find(f => f.value === selectedUser)}
            onChange={(value) => {
              setSelectedUser(value ? value.value : null)
            }}
            isClearable
          />
          <NewBoxButton
            sx={{
              marginTop: "8px",
              width: "100%",
            }}
            color="#445be3"
            primary
            text={t?.("buttons.confirm")}
            loading={isInFlightAssignThread}
            disabled={shouldDisableConfirm}
            onClick={async () => {
              await assignThread({ userId: selectedUser })
              if (selectedUser) {
                // assigning
                if (!member?.liveChat) {
                  // eslint-disable-next-line no-restricted-globals
                  const c = confirm(t?.("live_chat.confirm_turn_on_live_chat"))
                  if (c) {
                    toggleLiveChat(true)
                  }
                }
              } else {
                // unassigning
                if (member?.liveChat) {
                  // eslint-disable-next-line no-restricted-globals
                  const c = confirm(t?.("live_chat.confirm_change_to_bot_mode"))
                  if (c) {
                    toggleLiveChat(false)
                  }
                }
              }
            }}
          />
          <div className="error">{assignedError}</div>
        </Box>
      </Styles.MoveFolderContainer>
    )
  }

  const moveFolderPopup = ({ isSuperAdmin, isSuperAuditor }) => (
    <Styles.MoveFolderContainer
      showError={moveFolderError}
    >
      <div className="label">{t?.("chat.move_to")}</div>
      <div className="separator"></div>
      <Box p={3}>
        <Select
          isDisabled={isSuperAdmin || isSuperAuditor}
          options={folderOptions}
          placeholder={t?.("placeholders.select_folder")}
          styles={generalStyles}
          value={folderOptions.find(f => f.value === selectedFolder)}
          onChange={(value) => {
            setSelectedFolder(value.value)
          }}
        />
        <NewBoxButton
          style={{ marginTop: "8px", width: "100%" }}
          primary
          text={t?.("buttons.confirm")}
          disabled={!selectedFolder || isInFlightAssignThread || isInFlightLiveChat}
          loading={isInFlightUpdateThreadFolder}
          onClick={() => {
            updateFolder({ folderId: selectedFolder })
          }}
        />
        <div className="error">{moveFolderError}</div>
      </Box>
    </Styles.MoveFolderContainer>
  )

  const header = () => {
    if (loading) {
      return (
        <Styles.Header className="chat-header" sx={{ justifyContent: "flex-start" }}>
          {mobile && (
            <IconButton
              className="mobile-screen-back left-button"
              icon={faChevronLeft}
              borderless
              noBG
              primary
              onClick={onClose}
              sx={{
                mr: "10px"
              }}
            />
          )}
          <HeaderLoader />
        </Styles.Header>
      )
    }
    
    if (data?.thread?.member?.externalId) {
      const member = data.thread.member
      let memberDisplayName = member?.externalId
      if (data?.thread?.title) {
        memberDisplayName = data.thread.title
      } else if (member?.inboxProfile?.firstName) {
        memberDisplayName = member?.inboxProfile?.firstName
        if (member?.inboxProfile?.lastName) {
          memberDisplayName = `${member?.inboxProfile?.firstName} ${member?.inboxProfile?.lastName}`
        }
      } else if (member?.inboxProfile?.name) {
        memberDisplayName = member?.inboxProfile?.name
      } else if (member?.externalProfile?.firstName) {
        memberDisplayName = member?.externalProfile?.firstName 
      } else if (member?.externalProfile?.lastName) {
        memberDisplayName = member?.externalProfile?.lastName
      } else if (member?.externalProfile?.first_name) {
        memberDisplayName = member?.externalProfile?.first_name
      } else if (member?.externalProfile?.last_name) {
        memberDisplayName = member?.externalProfile?.last_name
      } else if (member?.externalProfile?.name) {
        memberDisplayName = member?.externalProfile?.name
      } else if (member?.externalProfile?.username) {
        memberDisplayName = member?.externalProfile?.username
      }
      return (
        <Styles.Header className="chat-header">
          <Flex sx={{ alignItems: "center", flexGrow: 1 }}>
            {mobile && (
              <IconButton
                className="mobile-screen-back left-button"
                icon={faChevronLeft}
                borderless
                noBG
                primary
                onClick={onClose}
                sx={{
                  mr: "10px"
                }}
              />
            )}
            <IconContainer
              className="chat-header-profile-pic"
              url={member?.inboxProfile?.profilePicUrl}
              size="S"
              name={memberDisplayName}
              showGroupPlaceholderIcon={data?.thread?.member?.externalId?.startsWith(GROUP_PREFIX)}
            />
            <Box sx={{ width: "calc(100% - 40px)", px: 2 }}>
              <Styles.MemberName
                sx={{ mb: 1 }}
                className="member-name"
                mobile={mobile}
                title={memberDisplayName}
              >{memberDisplayName}</Styles.MemberName>
              <div className="channel-name-wrapper" sx={{ mb: 1 }}>
                <IntegrationIconDisplay
                  icon={data.thread?.wChannel?.integrationIcon}
                  name={data.thread?.wChannel?.integrationName}
                  size="16px"
                />
                <div
                  className="channel-name"
                  title={data.thread?.wChannel?.name}
                >{data.thread?.wChannel?.name}</div>
              </div>
              <Flex sx={{ alignItems: "center", flexShrink: 0, flexWrap: "wrap", gap: "4px" }}>
                <Tag backgroundColor={data.thread?.folder?.color} color="#ffffff">
                  {passFolderNameThroughTranslation(data.thread?.folder)}
                </Tag>
                {data?.thread?.wUserId && (
                  <ScopedTag backgroundColor={FOLLOW_UP_COLOR} color="#ffffff" scope={t?.("chat.assigned")}>{data.thread?.user?.email?.email}</ScopedTag>
                )}
                {!mobile && (
                  <CopyToClipboard
                    text={shortcutUrl}
                    onCopy={() => {
                      setCopyPermalinkText(t?.("tooltips.copied"))
                      setTimeout(() => {
                        setCopyPermalinkText(t?.("chat.copy_permalink"))
                      }, 1000)
                    }}
                  >
                    <Flex
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        width: "21px",
                        height: "21px",
                        borderRadius: "4px",
                        transition: "all 0.2s linear",
                        "&:hover": {
                          background: "gray05",
                        }
                      }}
                      data-tooltip-content={copyPermalinkText}
                      data-tooltip-id="chat-section-tooltip"
                    >
                      <FontAwesomeIcon
                        icon={faLink}
                        fixedWidth
                        sx={{
                          fontSize: 1,
                          color: "gray4",
                        }}
                      />
                    </Flex>
                  </CopyToClipboard>
                )}
              </Flex>
            </Box>
          </Flex>
          <Flex sx={{ ml: 2, flexShrink: 0 }} className="chat-top-buttons-wrapper">
            {mobile ?
              (
                <PopperContainer
                  show={showTopButtonsWrapper}
                  modifiers={[
                    {
                      name: "offset",
                      options: {
                        offset: [0, 8],
                      },
                    },
                  ]}
                  display={(
                    <IconButton
                      icon={faEllipsisVertical}
                      noBG
                      borderless
                      onClick={() => {
                        setShowTopButtonsWrapper(!showTopButtonsWrapper)
                      }}
                    />
                  )}
                  placement="bottom-end"
                  onClickOutside={() => {
                    if (showTopButtonsWrapper) {
                      setShowTopButtonsWrapper(false)
                    }
                  }}
                >
                  {() => (
                    <Box
                      sx={{
                        minWidth: "180px",
                        width: "50vw"
                      }}
                    >
                      <Styles.DropdownMenuItem
                        borderless
                        icon={member?.liveChat ? faHeadset : faRobot}
                        text={member?.liveChat ? t?.("live_chat.live_chat_mode") : t?.("live_chat.bot_mode")}
                        loading={isInFlightLiveChat}
                        disabled={isInFlightAssignThread || isInFlightUpdateThreadFolder || isSuperAdmin || isSuperAuditor}
                        onClick={() => {
                          toggleLiveChat(!data?.thread?.member?.liveChat)
                        }}
                        color={member?.liveChat ? "#405DE6" : "#707070"}
                      />
                      <PopperContainer
                        show={showAssignDropDown}
                        modifiers={[
                          {
                            name: "offset",
                            options: {
                              offset: [0, 8],
                            },
                          },
                        ]}
                        displayDisplay="block"
                        display={(
                          <Styles.DropdownMenuItem
                            borderless
                            icon={faStar}
                            text={`${data?.thread?.wUserId ? data?.thread?.user?.email?.email : t?.("chat.assign")}`}
                            onClick={() => {
                              setShowAssignDropDown(!showAssignDropDown)
                            }}
                            color={data?.thread?.wUserId ? "#405DE6" : "#707070"}

                          />
                        )}
                        placement="bottom-end"
                        onClickOutside={() => {
                          if (showAssignDropDown) {
                            setShowAssignDropDown(false)
                            setAssignedError("")
                          }
                        }}
                      >
                        {() => assignPopup({ member, isSuperAdmin, isSuperAuditor })}
                      </PopperContainer>
                      <PopperContainer
                        show={showMoveFolderDropDown}
                        modifiers={[
                          {
                            name: "offset",
                            options: {
                              offset: [0, 8],
                            },
                          },
                        ]}
                        displayDisplay="block"
                        display={(
                          <Styles.DropdownMenuItem
                            borderless
                            icon={faFolder}
                            text="Move"
                            onClick={() => {
                              setShowMoveFolderDropDown(!showMoveFolderDropDown)
                            }}
                            color="#707070"
                          />
                        )}
                        placement="bottom-end"
                        onClickOutside={() => {
                          if (showMoveFolderDropDown) {
                            setShowMoveFolderDropDown(false)
                            setMoveFolderError("")
                            setSelectedFolder(null)
                          }
                        }}
                      >
                        {() => moveFolderPopup({
                          isSuperAdmin,
                          isSuperAuditor,
                        })}
                      </PopperContainer>
                      <Styles.DropdownMenuItem
                        icon={faUser}
                        borderless
                        text={t?.("chat.member_info")}
                        onClick={() => {
                          setOpenMemberInfo(true)
                        }}
                        color="#707070"
                      />
                    </Box>
                  )}
                </PopperContainer>
              )
              :
              (<>
                <Styles.LiveChatButton
                  data-tooltip-content={t?.("live_chat.tooltip_change_to_live_chat_mode")}
                  data-tooltip-id="chat-section-tooltip"
                  isOn={member?.liveChat}
                  icon={faHeadset}
                  text={t?.("live_chat.live_chat_button")}
                  loading={isInFlightLiveChat}
                  disabled={isInFlightAssignThread || isInFlightUpdateThreadFolder || isSuperAdmin || isSuperAuditor || member?.liveChat}
                  onClick={() => {
                    if (member?.liveChat) {
                      return
                    }
                    toggleLiveChat(!data?.thread?.member?.liveChat)
                  }}
                />
                <Styles.LiveChatButton
                  data-tooltip-content={t?.("live_chat.tooltip_change_to_bot_mode")}
                  data-tooltip-id="chat-section-tooltip"
                  isOn={!member?.liveChat}
                  icon={faRobot}
                  text={t?.("live_chat.bot_button")}
                  loading={isInFlightLiveChat}
                  disabled={isInFlightAssignThread || isInFlightUpdateThreadFolder || isSuperAdmin || isSuperAuditor || !member?.liveChat}
                  onClick={() => {
                    if (!member?.liveChat) {
                      return 
                    }
                    toggleLiveChat(!data?.thread?.member?.liveChat)
                  }}
                />
                <PopperContainer
                  show={showAssignDropDown}
                  modifiers={[
                    {
                      name: "offset",
                      options: {
                        offset: [0, 8],
                      },
                    },
                  ]}
                  disabled={isSuperAdmin || isSuperAuditor}
                  display={displayAssignedButton}
                  placement="bottom-end"
                  onClickOutside={() => {
                    if (showAssignDropDown) {
                      setShowAssignDropDown(false)
                      setSelectedUser(data?.thread?.wUserId ?? null)
                      setAssignedError("")
                    }
                  }}
                >
                  {() => assignPopup({ member, isSuperAdmin, isSuperAuditor })}
                </PopperContainer>
                <PopperContainer
                  show={showMoveFolderDropDown}
                  modifiers={[
                    {
                      name: "offset",
                      options: {
                        offset: [0, 8],
                      },
                    },
                  ]}
                  display={(
                    <Styles.MoveToButton
                      inverted
                      text={(
                        <div
                          className="svg-container"
                        >
                          <img
                            src={iconFolderMove}
                            alt="Move To"
                            className="svg-icon"
                          />
                        </div>
                      )}
                      data-tooltip-content={t?.("chat.move_to")}
                      data-tooltip-id="chat-section-tooltip"
                      onClick={() => {
                        if (!showMoveFolderDropDown) {
                          setShowMoveFolderDropDown(true)
                        } else {
                          setShowMoveFolderDropDown(false)
                        }
                      }}
                    />
                  )}
                  placement="bottom-end"
                  onClickOutside={() => {
                    if (showMoveFolderDropDown) {
                      setShowMoveFolderDropDown(false)
                      setMoveFolderError("")
                      setSelectedFolder(null)
                    }
                  }}
                >
                  {() => moveFolderPopup({
                    isSuperAdmin,
                    isSuperAuditor,
                  })}
                </PopperContainer>
                <Styles.MemberInfoButton
                  style={{ marginLeft: "8px" }}
                  icon={faUserCircle}
                  inverted
                  onClick={() => {
                    setOpenMemberInfo(true)
                  }}
                />
                <NewBoxButton
                  sx={{
                    ml: 1,
                  }}
                  borderless
                  icon={faTimes}
                  onClick={onClose}
                />
              </>)
            }
          </Flex>
        </Styles.Header>
      )
    }
    return (
      <Styles.Header className="chat-header"></Styles.Header>
    )
  }

  const bodyContent = () => {
    if (data?.thread?.member?.externalId) {
      return (
        <Styles.Body
          ref={body}
          className={`${isWhatsappCloudThread ? "whatsapp-background-image" : ""}`}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            {loadMoreButton({ isWhatsappCloudThread })}
            <ChatVisualizer
              isLiveChat={data?.thread?.member?.liveChat}
              chatVisualizerRef={chatVisualizerRef}
              loadingHistory={loadingMoreChats}
              loading={!initialized}
              ownUserId={ownUserId}
              chats={chats.filter(o => !o.hidden && o.messageEvent)}
              statusChats={statusChats}
              themeColor={theme.themeColorGreen}
              memberProfile={data?.thread?.member?.inboxProfile}
              externalProfile={data?.thread?.member?.externalProfile}
              channelName={data?.thread?.wChannel?.name}
              resourceTemplates={data?.thread?.resourceTemplates}
              onResend={cachedOnResendFunction}
              app={data?.thread?.wChannel?.wAppId}
              integrationId={data?.thread?.wChannel?.integrationId}
              appIntegration={data?.thread?.wChannel?.environments?.[0]?.appIntegrationId}
              channel={data?.thread?.wChannel?.wChannelId}
              appIntegrationSignature={data?.thread?.wChannel?.environments?.[0]?.signature}
              newMessageRef={scrollTargetRef}
              newMessagesCount={newMessagesCount}
              onMarkUnread={cachedOnMarkUnread}
              setReplyToContext={setReplyToContext}
            />
          </Box>
        </Styles.Body>
      )
    }
    return (
      <Styles.Body ref={body}>
      </Styles.Body>
    )
  }

  const isWhatsappCloudThread = /^whatsapp-cloud/.test(data?.thread?.wChannel?.integrationId)
  return (
    <>
      <Styles.Chat mobile={mobile} show={show} className="chat">
        <Styles.ConversationContainer className="chat-container">
          {header()}
          <div className="content">
            {bodyContent()}
            {(replyToContext) && (
              <ReplyToContext isWhatsappCloudThread={isWhatsappCloudThread} replyToContext={replyToContext} setReplyToContext={setReplyToContext} />
            )}
            <ChatFooter
              key={threadId}
              loading={loading}
              data={data}
              isInFlightAssignThread={isInFlightAssignThread}
              isInFlightLiveChat={isInFlightLiveChat}
              isInFlightUpdateThreadFolder={isInFlightUpdateThreadFolder}
              filesToUpload={filesToUpload}
              isReadyToSendAudio={isReadyToSendAudio}
              isSendingAudio={isSendingAudio}
              isIos={isIos}
              isAudioEnabled={isAudioEnabled}
              isDraggingOverTextArea={isDraggingOverTextArea}
              previews={previews}
              setPreviews={setPreviews}
              setFilesToUpload={setFilesToUpload}
              isUploading={isUploading}
              failedToUploadError={failedToUploadError}
              setFailedToUploadError={setFailedToUploadError}
              tooManyFilesAttachedError={tooManyFilesAttachedError}
              setTooManyFilesAttachedError={setTooManyFilesAttachedError}
              threadId={threadId}
              cancelRecording={cancelRecording}
              startRecording={startRecording}
              isRecording={isRecording}
              togglePauseResume={togglePauseResume}
              isPaused={isPaused}
              stopRecording={stopRecording}
              audioUrl={audioUrl}
              errorName={errorName}
              fileTooLarge={fileTooLarge}
              isLoadingAudio={isLoadingAudio}
              blobDuration={blobDuration}
              getRecordingTextToDisplay={getRecordingTextToDisplay}
              randomKey={randomKey}
              usersForMention={usersForMention}
              mentions={mentions}
              setMentions={setMentions}
              dragndropSupport={dragndropSupport}
              setIsDraggingOverTextArea={setIsDraggingOverTextArea}
              convertFilesToBlobs={convertFilesToBlobs}
              sendEvent={sendEvent}
              setRandomKey={setRandomKey}
              onChangeNavHint={onChangeNavHint}
              setTextOptions={setTextOptions}
              setOpenTextOptionModal={setOpenTextOptionModal}
              setIsAudioEnabled={setIsAudioEnabled}
              textEditorRef={textEditorRef}
              mobile={mobile}
              showSendOptions={showSendOptions}
              setShowSendOptions={setShowSendOptions}
              toggleLiveChat={toggleLiveChat}
              assignThread={assignThread}
              uploadFiles={uploadFiles}
              recordingBlob={recordingBlob}
              blobToFile={blobToFile}
              mimeType={mimeType}
              setIsSendingAudio={setIsSendingAudio}
            />
          </div>
        </Styles.ConversationContainer>

        <Styles.MemberContainer notShow={openMemberInfo} className="member-container">
          {!_.isEmpty(data?.thread?.member) &&
            <MemberContainer
              data={props.data}
              threadId={threadId}
              withoutThreadId={withoutThreadId}
              mobile={mobile}
            />
          }
        </Styles.MemberContainer>
        <Styles.TransitionMemberContainer
          show={openMemberInfo}
          className="transition-member-container"
          mobile={mobile}
        >
          <MemberContainer
            data={props.data}
            threadId={threadId}
            withoutThreadId={withoutThreadId}
            withCloseButton
            mobile={mobile}
            onCloseMemberContainer={() => {
              setOpenMemberInfo(false)
            }}
          />
        </Styles.TransitionMemberContainer>
        <ReactTooltip
          id="chat-section-tooltip"
          place="bottom"
        />
      </Styles.Chat>
      <Modal
        open={openTextOptionModal}
      >
        {() => (
          <TextOptionModal
            textOptions={textOptions}
            onConfirmOption={(text) => {
              textEditorRef?.current?.insertText(text)
              setOpenTextOptionModal(false)
              setTimeout(() => {
                setTextOptions(null)
              }, 0)
            }}
            onClose={() => {
              setOpenTextOptionModal(false)
              setTimeout(() => {
                setTextOptions(null)
              }, 0)
            }}
          />
        )}
      </Modal>
    </>
  )
}

export default ConversationHistory
