/** @jsxImportSource theme-ui */

import React, { useEffect, useTransition, useState } from "react"
import { useTranslation } from "react-i18next"
import graphql from "babel-plugin-relay/macro"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Flex, Box, Image } from "theme-ui"
import { saveAs } from "file-saver"
import {
  useQueryLoader,
  usePreloadedQuery,
  useRefetchableFragment,
} from "react-relay"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSpinner,
  faImage,
  faCirclePlay,
  faFile,
  faMicrophone,
  faPlay,
  faNoteSticky,
} from "@fortawesome/free-solid-svg-icons"
import ExternalLinkOutWrapper from "../../ExternalLinkOutWrapper/ExternalLinkOutWrapper"
import { resourceTemplateTheme } from "../../../stylesheets/theme"

const Error = styled.div`
  font-size: ${(props) => props.theme.textSm};
  color: ${(props) => props.theme.error};
  padding: 8px;
  max-width: 212px;
`

const AudioTemplate = styled.div`
  display: flex;
  
  >.duration-wrapper {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    padding: 0 8px;
    width: 136px;
    box-sizing: border-box;
    position: relative;

    >.line {
      background: #ababab;
      width: 100%;
      height: 1px;
    }

    >.title {
      text-wrap: nowrap;
      position: absolute;
      top: 12px;
      left: 12px;
    }
  }
`

const ImageUrlContainer = styled.a`
  width: 100%;
  display: block;
  background-color: white;
  margin: 0 auto;
  border-radius: 6px;
`

const StyledAudio = styled.audio`
  @media screen and (max-width: 500px) {
    max-width: 212px;
  }
`

const ClickToDownloadWrapper = styled.div`
  ${p => p.isReply && css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  `}
`

const MaxWidthBox = styled(Box)`
  @media screen and (max-width: 500px) {
    max-width: 210px !important;
  }
  ${(props) => {
    if (props.isWhatsappCloudThread && props.from === "BOT") {
      return css`
        background: ${resourceTemplateTheme.bot.whatsapp.colors.textBackgroundWhite};
      `
    }
    return css``
  }}
`

const MediaDownloadQuery = graphql`
  query MediaDownloadQuery ($fileId: ID!, $firstLoad: Boolean!) {
    userViewer {
      ...MediaDownload_userViewer @arguments (fileId: $fileId, firstLoad: $firstLoad)
    }
  }
`

const InnerContainer = ({
  queryRef,
  fileId,
  onLoadUrl,
  type,
  ...props
}) => {

  const data = usePreloadedQuery(
    MediaDownloadQuery,
    queryRef,
  )
  return (
    <InnerMediaDownload
      data={data}
      fileId={fileId}
      type={type}
      onLoadUrl={onLoadUrl}
      {...props}
    />
  )
}
const checkBrowserSupportedType = ({ type }) => {
  if (!type) return []
  const ele = document.createElement(type)
  const typesToCheck = {
    audio: ["audio/mp4", "audio/mpeg", "audio/webm", "audio/aac" ],
  }
  if (!typesToCheck[type]) return []

  const supportedTypes = []

  typesToCheck[type].forEach((t) => {
    const isSupported = ele.canPlayType(t)
    if (isSupported) {
      supportedTypes.push({
        type: t,
      })
    }
  })

  return supportedTypes
}


const InnerMediaDownload = ({
  queryRef,
  fileId,
  onLoadUrl,
  type,
  isConversation = false,
  isWhatsappCloudThread = false,
  ...props
}) => {
  const [loading, startTransition] = useTransition()
  const [fileUrl, setFileUrl] = useState(null)
  const [fileType, setFileType] = useState(null)
  const [error, setError] = useState(null)
  const from = props?.from
  const { t } = useTranslation("common")
  const isReply = props?.isReply ?? false

  // TODO: update transcoding schema when it's ready
  const [
    data,
    refetch
  ] = useRefetchableFragment(
    graphql`
      fragment MediaDownload_userViewer on UserScope
      @argumentDefinitions(
        fileId: { type: "ID!" }
        firstLoad: { type: "Boolean!" }
        expectedMediaTypes: { type: "[FileTypeInput]" }
      )
      @refetchable(queryName: "MediaDownloadRefetchQuery") {
        file (fileId: $fileId, expectedMediaTypes: $expectedMediaTypes) @skip(if: $firstLoad) {
          file {
            fileId
            fileType {
              codec
              default
              type
            }
            url
            size
          }
          error {
            code
            message
          }
        }
      }
    `,
    props.data.userViewer
  )

  const fetchData = () => {
    const variable = {
      fileId,
      firstLoad: false,
    }
    if (type === "audio") {
      variable.expectedMediaTypes = checkBrowserSupportedType({ type: "audio" })
    }
    startTransition(() => {
      refetch(variable, {
        fetchPolicy: "network-only",
      })
    })
  }

  useEffect(() => {
    if (data?.file?.file?.url) {
      const url = data?.file?.file?.url
      setFileUrl(url)
      setFileType(data?.file?.file?.fileType?.type)
      onLoadUrl()
      if (
        !/image/i.test(data?.file?.file?.fileType?.type) &&
        !/video/i.test(data?.file?.file?.fileType?.type) &&
        !/audio/i.test(data?.file?.file?.fileType?.type)
      ) {
        saveAs(url, url.split("/")[url.split("/").length - 1])
      }
    } else if (data?.file?.error || data?.file?.transcodingError) {
      let errorMessage
      if (data?.file?.error) {
        errorMessage = data?.file?.error
      } else if (data?.file?.transcodingError) {
        errorMessage = data?.file?.transcodingError?.code + " " + data?.file?.transcodingError?.message
      }
      setError(errorMessage)
    }
  }, [data?.file?.file?.url, data?.file?.error])

  const renderContent = () => {
    if (error) {
      return (
        <Flex sx={{ height: "100%", alignItems: "center", justifyContent: "center"}}>
          <Error>[{error?.code}] {error?.message}</Error>
        </Flex >
      )
    }
    if (!fileUrl) {
      if (type?.toLowerCase() === "image") {
        return (
          <Flex
            sx={{ height: "100%", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
            onClick={() => {
              fetchData()
            }}
          >
            {loading ?
              <FontAwesomeIcon spin icon={faSpinner} style={{ fontSize: "24px", color: "#ababab" }} />
              :
              <ClickToDownloadWrapper isReply={isReply}>
                <FontAwesomeIcon icon={faImage} style={{ fontSize: "40px", color: "#ababab", marginBottom: "8px" }} />
                <div style={{ fontSize: "0.83333em", color: "#626262", paddingTop: "4px", fontWeight: "bold" }}>{t?.("message_types.media_download.click_to_download")}</div>
              </ClickToDownloadWrapper>
            }
          </Flex>
        )
      } if (type?.toLowerCase() === "sticker") {
        return (
          <Flex
            sx={{ height: "100%", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
            onClick={() => {
              fetchData()
            }}
          >
            {loading ?
              <FontAwesomeIcon spin icon={faSpinner} style={{ fontSize: "24px", color: "#ababab" }} />
              :
              <ClickToDownloadWrapper isReply={isReply}>
                <FontAwesomeIcon icon={faNoteSticky} style={{ fontSize: "40px", color: "#ababab", marginBottom: "8px" }} />
                <div style={{ fontSize: "0.83333em", color: "#626262", paddingTop: "4px", fontWeight: "bold" }}>{t?.("message_types.media_download.click_to_download")}</div>
              </ClickToDownloadWrapper>
            }
          </Flex>
        )
      } else if (type?.toLowerCase() === "video") {
        return (
          <Flex
            sx={{ height: "100%", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
            onClick={() => {
              fetchData()
            }}
          >
            {loading ?
              <FontAwesomeIcon spin icon={faSpinner} style={{ fontSize: "24px", color: "#ababab" }} />
              :
              <ClickToDownloadWrapper isReply={isReply}>
                <FontAwesomeIcon icon={faCirclePlay} style={{ fontSize: "40px", color: "#ababab", marginBottom: "8px" }} />
                <div style={{ fontSize: "0.83333em", color: "#626262", paddingTop: "4px", fontWeight: "bold" }}>{t?.("message_types.media_download.click_to_download")}</div>
              </ClickToDownloadWrapper>
            }
          </Flex>
        )
      } else if (type?.toLowerCase() === "audio") {
        return (
          <Flex
            sx={{ height: "100%", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
            onClick={() => {
              fetchData()
            }}
          >
            {loading ?
              <FontAwesomeIcon spin icon={faSpinner} style={{ fontSize: "24px", color: "#ababab" }} />
              :
              <AudioTemplate>
                <FontAwesomeIcon
                  style={{ fontSize: "16px", color: "#ababab" }}
                  className="audio-icon"
                  icon={faPlay}
                />
                <div className="duration-wrapper">
                  <div className="line" />
                  <div className="title" style={{ fontSize: "0.83333em", color: "#626262", paddingTop: "4px", fontWeight: "bold" }}>{t?.("message_types.media_download.click_to_download")}</div>
                </div>
                <FontAwesomeIcon
                  style={{ fontSize: "16px", color: "#ababab" }}
                  className="audio-icon"
                  icon={faMicrophone}
                />
              </AudioTemplate>
            }
          </Flex>
        )
      } else if (type?.toLowerCase()  === "file") {
        return (
          <Flex
            sx={{ height: "100%", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
            onClick={() => {
              fetchData()
            }}
          >
            {loading ?
              <FontAwesomeIcon spin icon={faSpinner} style={{ fontSize: "24px", color: "#ababab" }} />
              :
              <ClickToDownloadWrapper isReply={isReply} style={{ textAlign: "center" }}>
                <FontAwesomeIcon
                  className="icon"
                  icon={faFile}
                  style={{ fontSize: "40px", color: "#ababab", marginBottom: "8px" }}
                />
                <div className="title" style={{ fontSize: "0.83333em", color: "#626262", paddingTop: "4px", fontWeight: "bold" }}>{t?.("message_types.media_download.click_to_download")}</div>
              </ClickToDownloadWrapper>
            }
          </Flex>
        )
      }
    }
    if (fileUrl && /image/i.test(fileType)) {
      if (isConversation) {
        return (
          <ExternalLinkOutWrapper url={fileUrl} displayLinkOutToLeft={from === "BOT"}>
            <ImageUrlContainer
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                className="media-download-image"
                sx={{
                  width: "100%",
                  verticalAlign: "bottom",
                  borderRadius: "6px",
                  objectFit: "cover",
                }}
                src={fileUrl}
              />
            </ImageUrlContainer>
          </ExternalLinkOutWrapper>
        )
      }
      return (
        <Image
          className="media-download-image"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "6px",
          }}
          src={fileUrl}
        />
      )
    } else if (fileUrl && /video/i.test(fileType)) {
      return (
        <video
          className="media-download-video"
          sx={{ width: "100%", height: "100%", objectFit: "contain" }}
          src={fileUrl}
          controls
        ></video>
      )
    } else if (fileUrl && /audio/i.test(fileType)) {
      return (
        <StyledAudio className="media-download-audio" controls>
          <source src={fileUrl} />
        </StyledAudio>
      )
    } else if (fileUrl) {
      return (
        <div
          className="media-download-file"
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <FontAwesomeIcon
            className="icon"
            icon={faFile}
            style={{ fontSize: "40px", color: "#ababab", marginBottom: "8px" }}
          />
          <div className="title"
            title={fileUrl}
            style={{
              fontSize: "0.83333em",
              color: "#626262",
              paddingTop: "4px",
              fontWeight: "bold",
              textOverflow: "ellipsis",
              overflow: "hidden",
              textAlign: "center",
              wordBreak: "break-all"
            }}
          >
            {fileUrl.split("/")[fileUrl.split("/").length - 1]}
          </div>
        </div>
      )
    }
  }

  return (
    <MaxWidthBox isWhatsappCloudThread={isWhatsappCloudThread} from={from} className="media-download-container" style={{ width: "100%", height: "100%" }}>
      {renderContent()}
    </MaxWidthBox>
  )
}

const MediaDownload = (props) => {
  const [
    queryReference,
    loadQuery,
  ] = useQueryLoader(
    MediaDownloadQuery,
    props.initialQueryRef, /* e.g. provided by router */
  )

  useEffect(() => {
    loadQuery({
      fileId: props.fileId,
      firstLoad: true
    })
  }, [])

  if (queryReference) {
    return (
      <InnerContainer
        queryRef={queryReference}
        fileId={props.fileId}
        {...props}
      />
    )
  }
}

const MediaDownloadWrapper = ({ isConversation, from, isWhatsappCloudThread, isReply }) => ({ ...props }) => (
  <MediaDownload {...props} isConversation={isConversation} from={from} isWhatsappCloudThread={isWhatsappCloudThread} isReply={isReply} />
)


export default MediaDownloadWrapper
