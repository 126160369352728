/** @jsxImportSource theme-ui */

import React, { useEffect, useState, useRef, useMemo } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle, faRobot,
} from "@fortawesome/free-solid-svg-icons"
import styled from "@emotion/styled"
import _ from "lodash"
import Slider from "react-slick"
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel"

import { timestampNormalizer, detectEmailAndUrl } from "../util"
import Text from "../Text/Text"
import Image from "../Image/Image"
import File from "../File/File"
import Audio from "../Audio/Audio"
import Video from "../Video/Video"
import Payload from "../Payload/Payload"
import LiveChatPayload from "../LiveChatPayload/LiveChatPayload"
import Misc from "../Misc/Misc"
import Raw from "../Raw/Raw"
import QuickReplies from "../QuickReplies/QuickReplies"
import MediaDownloadWrapper from "../MediaDownload/MediaDownload"
import ExternalLinkOutWrapper from "../../ExternalLinkOutWrapper/ExternalLinkOutWrapper"
import PopperContainer from "../../Radiate/PopperContainer/PopperContainer"
import PopupMenuCollapseButton from "../PopupMenu/CollapseButton"
import IconContainer from "../../IconContainer/IconContainer"
import { Buffer } from "buffer"
import { MOBILE_BREAKPOINT, MOBILE_MESSAGE_MAX_WIDTH, WHATSAPP_CLOUD_MSG_ON_HOVER_COLOR } from "../../../const"
import { WhatsappMarkdown } from "../WhatsappMarkdown/WhatsappMarkdown"
import { resourceTemplateTheme } from "../../../stylesheets/theme"
import { ReactLive } from "../../../components/ReactLive"
import { ReplyPreview as ReplyPreviewWrapper } from "../ReplyPreview/ReplyPreview"

// const ROUTER_BASE = process.env.REACT_APP_ROUTER_BASE || ""

const unreadColor = "#0063f00f"
const unreadHoverColor = "#0063f005"

const Container = styled.div`
  transition: background 0.1s linear;
  background: ${(props) => props.unread ? unreadColor : "transparent"};
  .popup-menu {
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    background: ${(props) => {
      if (props.isWhatsappCloudThread && !props.unread) {
        return WHATSAPP_CLOUD_MSG_ON_HOVER_COLOR
      }
      return props.unread ? unreadHoverColor : "rgba(255, 255, 255, 0.5)"
    }};
    .popup-menu {
      opacity: 1;
      pointer-events: all;
    }
  }
`

const Row = styled.div`
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .content-info > .content {
      max-width: ${MOBILE_MESSAGE_MAX_WIDTH};
    }
  }
`

const UserBubbleContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  >.raw {
    font-size: ${(props) => props.theme.textSm};
    font-family: 'Courier New', Courier, monospace;
  }
`

const UserBubbleSideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 32px;
  
  .time-display {
    font-size: ${(props) => props.theme.textXs};
    color: #555;
    width: 32px;
  }
`

const BotProfile = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.textXs};
  justify-content: flex-end;
  gap: 8px;
  color: ${(props) => props.theme.gray7};
  padding-left: 32px;
  padding-right: 8px;
  padding-bottom: 4px;

  .bot-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
  }
`

function renderMessage(message) {
  switch (message.type) {
    case "TEXT":
      return (<Text data={message.data} />)
    case "IMAGE":
      return (<Image data={message.data} />)
    case "FILE":
      return (<File data={message.data} />)
    case "AUDIO":
      return (<Audio data={message.data} />)
    case "VIDEO":
      return (<Video data={message.data} />)
    case "MISC":
      return (<Misc data={message.data} />)
    case "LIVE_CHAT_PAYLOAD":
      return (<LiveChatPayload data={message.data} />)
    case "PAYLOAD":
      return (<Payload data={message.data} />)
    default:
      return (<Raw data={message} />)
  }
}

function renderQuickReplies(message) {
  if (message.type === "QUICK_REPLIES" || _.get(message, "data.quickReplies.length") > 0) {
    return (
      <QuickReplies
        data={message.data}
      />
    )
  }
  return null
}

const Bot = ({
  message,
  createdAt,
  clientMutationId,
  readAt,
  deliveredAt,
  failedAt,
  chatId,
  error,
  name,
  email,
  resourceTemplate = null,
  onAction,
  readOnly,
  app,
  appIntegration,
  channel,
  appIntegrationSignature,
  isConversation = false,
  unread,
  messageMenu,
  messageMenuStyle,
  messageMenuPlacement,
  isLiveChat,
  isWhatsappCloudThread,
  ...props
}) => {
  const { t } = useTranslation("common")
  const [openMenu, setOpenMenu] = useState(false)
  const [ contentWidth, setContentWidth] = useState(300)
  const [ rowAlignItems, setRowAlignItems] = useState("flex-start")
  const content = useRef(null)
  const row = useRef(null)

  useEffect(() => {
    if (content?.current) {
      const width = content?.current?.offsetWidth
      if (width < contentWidth) {
        setContentWidth(width)
      }
    }
    setTimeout(() => {
      if (row?.current) {
        if (row?.current?.offsetHeight <= 68) {
          setRowAlignItems("center")
        }
      }
    }, 500)
  }, [content])

  let timestamp = null
  if (timestampNormalizer(message.timestamp)) {
    timestamp = moment(timestampNormalizer(message.timestamp)).format("HH:mm")
  } else if (timestampNormalizer(createdAt)) {
    timestamp = moment(timestampNormalizer(createdAt)).format("HH:mm")
  }

  const payload = {
    app,
    appIntegration,
    channel,
  }
  let encodedPayload = null
  if (Buffer) {
    encodedPayload = Buffer.from(JSON.stringify(payload)).toString("base64")
  }
  const signedContext = `${appIntegrationSignature}.${encodedPayload}`

  const MediaDownload = useMemo(() => {
    return MediaDownloadWrapper({
      isConversation,
      from: "BOT",
      isWhatsappCloudThread,
    })
  }, [
    isConversation,
    isWhatsappCloudThread,
  ]) 

  const ReplyPreview = useMemo(() => {
    return ReplyPreviewWrapper({
      fileId: message?.replyToMessageEvent?.file?.fileId,
      chatId,
      isConversation,
      from: "MEMBER",
      replyToType: message?.replyToMessageEvent?.type,
      replyToFrom: message?.replyToMessageEvent?.from,
      replyWithType: message?.type,
      payload,
      signedContext,
    })
  }, [
      message?.replyToMessageEvent?.file?.fileId,
      chatId,
      isConversation,
      message?.replyToMessageEvent?.type,
      message?.replyToMessageEvent?.from,
      app,
      appIntegration,
      channel,
      signedContext,
    ])

  const scope = useMemo(() => {
    return {
      styled,
      Slider,
      FontAwesomeIcon,
      ResponsiveCarousel,
      serverApiPath: `${process.env.REACT_APP_WOZTELL_URL}/api`,
      MediaDownload,
      WhatsappMarkdown,
      detectEmailAndUrl,
      ReplyPreview,
      data: {
        ...message?.data,
        isReply: message?.isReply,
        replyToPreview: message?.replyToPreview,
        replyToMessageEvent: message?.replyToMessageEvent,
        isConversation,
        from: "BOT",
        isInbox: true,
        ExternalLinkOutWrapper,
      },
      theme: {
        ...resourceTemplateTheme.bot,
        user: {
          ...resourceTemplateTheme.user,
        },
        bot: {
          ...resourceTemplateTheme.bot,
        }
      },
      payload,
      signedContext,
    }
  }, [
    MediaDownload,
    ReplyPreview,
    JSON.stringify(message?.data),
    message?.isReply,
    message?.replyToPreview,
    JSON.stringify(message?.replyToMessageEvent),
    isConversation,
    app,
    appIntegration,
    channel,
    signedContext,
  ])

  if (["REFERRAL", "COMMENT"].indexOf(message.type) > -1) {
    return null
  }
  // console.log("INBOX FRONT-END BOT scope: ", scope)

  const timeInfo = {
    readAt,
    deliveredAt,
    failedAt
  }

  const rowStyle = {
    alignItems: rowAlignItems
  }

  return (
    <Container
      unread={unread}
      onMouseLeave={() => {
        setOpenMenu(false)
      }}
      isWhatsappCloudThread={isWhatsappCloudThread}
    >
      <Row 
        className="row bot"
        ref={row}
        style={rowStyle}
      >
        <div
          className="content-info"
          sx={{
            position: "relative"
          }}
        >
          <MemoizedBotBubbleContent
            resourceTemplate={resourceTemplate}
            content={content}
            isWhatsappCloudThread={isWhatsappCloudThread}
            scope={scope}
            message={message}
          />
          <UserBubbleSideContent>
            {error &&
              <>
                <FontAwesomeIcon
                  className="error-icon"
                  icon={faExclamationTriangle}
                  data-tooltip-content={error}
                  data-tooltip-id="chat-visualizer-tooltip"
                  data-tooltip-place="left"
                />
              </>
            }
            <div
              className="time-display"
              data-tooltip-content={(failedAt || readAt || deliveredAt) ? JSON.stringify(timeInfo) : null}
              data-tooltip-id="chat-visualizer-tooltip"
              data-tt-type="time-display"
              data-tooltip-place="left"
            >
              {timestamp}
            </div>
            {messageMenu && (
              <PopperContainer
                className="popup-menu"
                display={(
                  <PopupMenuCollapseButton
                    onClick={() => {
                      setOpenMenu(true)
                    }}
                  />
                )}
                sx={messageMenuStyle}
                placement={messageMenuPlacement}
                show={openMenu}
                onClickOutside={() => {
                  setOpenMenu(false)
                }}
                addDefaultWrapper={false}
              >
                {() => messageMenu}
              </PopperContainer>
            )}
          </UserBubbleSideContent>
        </div>
      </Row>
      <BotProfile>
        <div className="bot-name">{t?.("chat_message.BOT")}</div>
        <IconContainer
          icon={faRobot}
          size="XS"
          withBorder={false}
          color={isLiveChat ? "#626262" : "#405DE6"}
        />
      </BotProfile>
    </Container>
  )
}

const BotBubbleContentWrapper = ({
  resourceTemplate,
  content,
  isWhatsappCloudThread,
  scope,
  message,
}) => {
  return (
    <UserBubbleContent className="content" ref={content}>
      {resourceTemplate?.preview ?
        <ReactLive
          useV2ReactLive={!isWhatsappCloudThread}
          resourceTemplate={resourceTemplate}
          scope={scope}
        />
        :
        <>
          {renderMessage(message)}
          {renderQuickReplies(message)}
        </>
      }
    </UserBubbleContent>
  )
}

const MemoizedBotBubbleContent = React.memo(BotBubbleContentWrapper, (prevProps, props) => {
  if (!_.isEqual(prevProps.resourceTemplate, props.resourceTemplate)) {
    return false
  }
  if (!_.isEqual(prevProps.content, props.content)) {
    return false
  }
  if (!_.isEqual(prevProps.isWhatsappCloudThread, props.isWhatsappCloudThread)) {
    return false
  }
  if (!_.isEqual(prevProps.message, props.message)) {
    return false
  }
  // scope is memoized, so just compare reference
  if (props.scope !== prevProps.scope) {
    return false
  }
  return true
})

export default React.memo(Bot, (prevProps, props) => {
  if (prevProps.readAt !== props.readAt) {
    return false
  }
  if (prevProps.deliveredAt !== props.deliveredAt) {
    return false
  }
  if (prevProps.failedAt !== props.failedAt) {
    return false
  }
  if (prevProps.error !== props.error) {
    return false
  }
  if (prevProps.chatId !== props.chatId) {
    return false
  }
  if (prevProps.unread !== props.unread) {
    return false
  }
  if (!_.isEqual(prevProps.messageMenu, props.messageMenu)) {
    return false
  }
  return true
})
