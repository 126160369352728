import randomColor from "randomcolor"

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function randomString(length) {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  let result = ""
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

export function timestampNormalizer(timestamp) {
  const split = `${timestamp}`.split(".")
  let result = timestamp
  if (split.length >= 2) {
    if (split[1].length >= 3) {
      return parseFloat(timestamp) * 1000
    }
  }
  if (typeof timestamp === "string") {
    result = parseInt(timestamp, 10)
  }
  if (result && result.toString().length <= 10) {
    result = result * 1000
  }

  return result
}

export function reverseTrimText(text, limit) {
  let modText = text
  if (text?.length >= limit) {
    modText = `...${text.slice(-limit)}`
  }
  return modText
}

export function trimText(text, limit) {
  let modText = text
  if (text?.length >= limit) {
    modText = `${text.slice(0, limit - 1)}...`
  }
  return modText
}

export function renderFolderColor(folderId) {
  let color = null
  switch (folderId) {
  case "main":
    color = "#6544f4"
    break;
  case "done":
    color = "#9674d0"
    break;
  case "followup":
    color = "#3a7cc5"
    break;
  case "spam":
    color = "#27a19b"
    break;
  default:
    color = randomColor({ hue: "green", luminosity: "dark" })
    break;
  }
  return color
}

export const pureLocaleList = [
  {
    value: "af-ZA",
    text: "Afrikaans (South Africa)"
  },
  {
    value: "ar-AR",
    text: "Arabic"
  },
  {
    value: "as-IN",
    text: "Assamese (India)"
  },
  {
    value: "az-AZ",
    text: "Azerbaijani (Azerbaijan)"
  },
  {
    value: "be-BY",
    text: "Belarusian (Belarus)"
  },
  {
    value: "bg-BG",
    text: "Bulgarian (Bulgaria)"
  },
  {
    value: "bn-IN",
    text: "Bangla (India)"
  },
  {
    value: "br-FR",
    text: "Breton (France)"
  },
  {
    value: "bs-BA",
    text: "Bosnian (Bosnia & Herzegovina)\t"
  },
  {
    value: "ca-ES",
    text: "Catalan (Spain)"
  },
  {
    value: "cb-IQ",
    text: "Kurdish (Iraq)"
  },
  {
    value: "co-FR",
    text: "Corsican (France)"
  },
  {
    value: "cs-CZ",
    text: "Czech (Czechia)"
  },
  {
    value: "cx-PH",
    text: "Cebuano (Philippines)"
  },
  {
    value: "cy-GB",
    text: "Welsh (United Kingdom)"
  },
  {
    value: "da-DK",
    text: "Danish (Denmark)"
  },
  {
    value: "de-DE",
    text: "German (Germany)"
  },
  {
    value: "el-GR",
    text: "Greek (Greece)"
  },
  {
    value: "en-GB",
    text: "English (United Kingdom)"
  },
  {
    value: "en-UD",
    text: "English (Upside Down)"
  },
  {
    value: "en-US",
    text: "English (United States)"
  },
  {
    value: "es-ES",
    text: "Spanish (Spain)"
  },
  {
    value: "es-LA",
    text: "Spanish"
  },
  {
    value: "et-EE",
    text: "Estonian (Estonia)"
  },
  {
    value: "eu-ES",
    text: "Basque (Spain)"
  },
  {
    value: "fa-IR",
    text: "Persian (Iran)"
  },
  {
    value: "ff-NG",
    text: "Fulah (Nigeria)"
  },
  {
    value: "fi-FI",
    text: "Finnish (Finland)"
  },
  {
    value: "fo-FO",
    text: "Faroese (Faroe Islands)"
  },
  {
    value: "fr-CA",
    text: "French (Canada)"
  },
  {
    value: "fr-FR",
    text: "French (France)"
  },
  {
    value: "fy-NL",
    text: "Western Frisian (Netherlands)"
  },
  {
    value: "id-ID",
    text: "Indonesia"
  },
  {
    value: "ga-IE",
    text: "Irish (Ireland)"
  },
  {
    value: "gl-ES",
    text: "Galician (Spain)"
  },
  {
    value: "gn-PY",
    text: "Guaraní"
  },
  {
    value: "gu-IN",
    text: "Gujarati (India)"
  },
  {
    value: "ha-NG",
    text: "Hausa (Nigeria)"
  },
  {
    value: "he-IL",
    text: "Hebrew (Israel)"
  },
  {
    value: "hi-IN",
    text: "Hindi (India)"
  },
  {
    value: "hr-HR",
    text: "Croatian (Croatia)"
  },
  {
    value: "hu-HU",
    text: "Hungarian (Hungary)"
  },
  {
    value: "hy-AM",
    text: "Armenian (Armenia)"
  },
  {
    value: "is-IS",
    text: "Icelandic (Iceland)"
  },
  {
    value: "it-IT",
    text: "Italian (Italy)"
  },
  {
    value: "ja-JP",
    text: "Japanese (Japan)"
  },
  {
    value: "ja-KS",
    text: "Japanese (Kansai)"
  },
  {
    value: "jv-ID",
    text: "Javanese"
  },
  {
    value: "ka-GE",
    text: "Georgian (Georgia)"
  },
  {
    value: "kk-KZ",
    text: "Kazakh (Kazakhstan)"
  },
  {
    value: "km-KH",
    text: "Khmer (Cambodia)"
  },
  {
    value: "kn-IN",
    text: "Kannada (India)"
  },
  {
    value: "ko-KR",
    text: "Korean (South Korea)"
  },
  {
    value: "ku-TR",
    text: "Kurdish"
  },
  {
    value: "lt-LT",
    text: "Lithuanian (Lithuania)"
  },
  {
    value: "lv-LV",
    text: "Latvian (Latvia)"
  },
  {
    value: "mg-MG",
    text: "Malagasy (Madagascar)"
  },
  {
    value: "mk-MK",
    text: "Macedonian (Macedonia)"
  },
  {
    value: "ml-IN",
    text: "Malayalam (India)"
  },
  {
    value: "mn-MN",
    text: "Mongolian (Mongolia)"
  },
  {
    value: "mr-IN",
    text: "Marathi (India)"
  },
  {
    value: "ms-MY",
    text: "Malay (Malaysia)"
  },
  {
    value: "mt-MT",
    text: "Maltese (Malta)"
  },
  {
    value: "my-MM",
    text: "Burmese (Myanmar [Burma])"
  },
  {
    value: "nb-NO",
    text: "Norwegian Bokmål (Norway)"
  },
  {
    value: "ne-NP",
    text: "Nepali (Nepal)"
  },
  {
    value: "nl-BE",
    text: "Dutch (Belgium)"
  },
  {
    value: "nl-NL",
    text: "Dutch (Netherlands)"
  },
  {
    value: "nn-NO",
    text: "Norwegian Nynorsk (Norway)"
  },
  {
    value: "or-IN",
    text: "Odia (India)"
  },
  {
    value: "pa-IN",
    text: "Punjabi (India)"
  },
  {
    value: "pl-PL",
    text: "Polish (Poland)"
  },
  {
    value: "ps-AF",
    text: "Pashto (Afghanistan)"
  },
  {
    value: "pt-BR",
    text: "Portuguese (Brazil)"
  },
  {
    value: "pt-PT",
    text: "Portuguese (Portugal)"
  },
  {
    value: "qz-MM",
    text: "Burmese (Zawgyi)"
  },
  {
    value: "ro-RO",
    text: "Romanian (Romania)"
  },
  {
    value: "ru-RU",
    text: "Russian (Russia)"
  },
  {
    value: "rw-RW",
    text: "Kinyarwanda (Rwanda)"
  },
  {
    value: "sc-IT",
    text: "Sardinian (Italy)"
  },
  {
    value: "si-LK",
    text: "Sinhala (Sri Lanka)"
  },
  {
    value: "sk-SK",
    text: "Slovak (Slovakia)"
  },
  {
    value: "sl-SI",
    text: "Slovenian (Slovenia)"
  },
  {
    value: "so-SO",
    text: "Somali (Somalia)"
  },
  {
    value: "sq-AL",
    text: "Albanian (Albania)"
  },
  {
    value: "sr-RS",
    text: "Serbian (Serbia)"
  },
  {
    value: "sv-SE",
    text: "Swedish (Sweden)"
  },
  {
    value: "sw-KE",
    text: "Swahili (Kenya)"
  },
  {
    value: "sz-PL",
    text: "Silesian (Poland)"
  },
  {
    value: "ta-IN",
    text: "Tamil (India)"
  },
  {
    value: "te-IN",
    text: "Telugu (India)"
  },
  {
    value: "tg-TJ",
    text: "Tajik (Tajikistan)"
  },
  {
    value: "th-TH",
    text: "Thai (Thailand)"
  },
  {
    value: "tl-PH",
    text: "Filipino"
  },
  {
    value: "tr-TR",
    text: "Turkish (Turkey)"
  },
  {
    value: "tz-MA",
    text: "Tamazight (Morocco)"
  },
  {
    value: "uk-UA",
    text: "Ukrainian (Ukraine)"
  },
  {
    value: "ur-PK",
    text: "Urdu (Pakistan)"
  },
  {
    value: "uz-UZ",
    text: "Uzbek (Uzbekistan)"
  },
  {
    value: "vi-VN",
    text: "Vietnamese (Vietnam)"
  },
  {
    value: "zh-CN",
    text: "Simplified Chinese (China)"
  },
  {
    value: "zh-HK",
    text: "Traditional Chinese (Hong Kong)"
  },
  {
    value: "zh-TW",
    text: "Traditional Chinese (Taiwan)"
  }
]

export function isJSONString(string) {
  try {
    JSON.parse(string)
  } catch (e) {
    return false
  }
  return true
}

export function localeMatcher(locale, localeToMatch) {
  if (locale[locale.length - 1] === "*") {
    return localeToMatch.toLowerCase().startsWith(locale.substring(0, locale.length - 1).toLowerCase())
  }
  if (/[-_]/.test(locale) && /[-_]/.test(localeToMatch)) {
    return locale.replace(/-/g, "_").toLowerCase() === localeToMatch.replace(/-/g, "_").toLowerCase()
  }
  return locale === localeToMatch
}

export const renderUserNameExternalProfileFirst = ({
  externalProfile,
  inboxProfile
}) => {
  if (!externalProfile && !inboxProfile) {
    return ""
  }

  if (externalProfile?.firstName) {
    return externalProfile?.firstName
  } else if (externalProfile?.lastName) {
    return externalProfile?.lastName 
  } else if (externalProfile?.first_name) {
    return externalProfile?.first_name
  } else if (externalProfile?.last_name) {
    return externalProfile?.last_name
  } else if (externalProfile?.name) {
    return externalProfile?.name
  } else if (externalProfile?.username) {
    return externalProfile?.username
  } else if (inboxProfile?.firstName) {
    return inboxProfile?.firstName
  } else if (inboxProfile?.lastName) {
    return inboxProfile?.lastName
  } else if (inboxProfile?.name) {
    return inboxProfile?.name
  }

  return ""
}

export const renderUserNameInboxProfileFirst = ({
  externalProfile,
  inboxProfile
}) => {
  if (!externalProfile && !inboxProfile) {
    return ""
  }

  if (inboxProfile?.firstName) {
    return inboxProfile?.firstName
  } else if (inboxProfile?.lastName) {
    return inboxProfile?.lastName
  } else if (inboxProfile?.name) {
    return inboxProfile?.name
  } else if (externalProfile?.firstName) {
    return externalProfile?.firstName
  } else if (externalProfile?.lastName) {
    return externalProfile?.lastName
  } else if (externalProfile?.first_name) {
    return externalProfile?.first_name
  } else if (externalProfile?.last_name) {
    return externalProfile?.last_name
  } else if (externalProfile?.name) {
    return externalProfile?.name
  } else if (externalProfile?.username) {
    return externalProfile?.username
  }


  return ""
}

export const renderReplyToUsername = ({
  isBot,
  externalProfile,
  inboxProfile,
  channelName,
}) => {
  if (isBot === undefined) return null

  if (isBot) {
    return channelName
  }
  return renderUserNameInboxProfileFirst({
    externalProfile,
    inboxProfile,
  }) || null
}
