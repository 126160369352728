import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { MessageBubble, detectEmailAndUrl } from "../util"


const Text = ({ data, color, isAgent = false, integrationId }) => {
  const { t } = useTranslation("common")
  const [readMore, setReadMore] = useState(false)
  const [messageText, setMessageText] = useState("")

  const readMoreDisplay = (dataText) => {
    let text = dataText
    if (readMore) {
      text = dataText
    } else if (dataText && dataText.length > 1000) {
      const sliced = dataText.slice(0, 999)
      text = (
        <div className="text-wrapper">
          <div className="text">{sliced}</div>
          <div
            className="read-more"
            onClick={() => {
              setReadMore(true)
            }}
          >
            {t?.("message_types.text.read_more")}
          </div>
        </div>
      )
    }
    return text
  }

  useEffect(() => {
    setMessageText(readMoreDisplay(detectEmailAndUrl(data.text)))
  }, [data.text, readMore])

  const isWhatsappCloudThread = /^whatsapp-cloud/.test(integrationId)

  return (
    <MessageBubble
      isAgent={isAgent}
      color={color}
      className={`message-bubble text ${isWhatsappCloudThread ? "whatsapp-cloud" : ""}`}
      isWhatsappCloudThread={isWhatsappCloudThread}
    >
      {messageText}
    </MessageBubble>
  )
}

export default Text
