import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Flex, Box } from "theme-ui"
import _ from "lodash"
import {
  faTimes,
  faPlus,
  faTrash,
  faQuestionCircle,
  faCamera,
  faCircleNotch
} from "@fortawesome/free-solid-svg-icons"
import {
  useMutation
} from "react-relay"
import Select from "react-select"
import moment from "moment"

import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import IconContainer from "../IconContainer/IconContainer"
import IntegrationIconDisplay from "../IntegrationIconDisplay/IntegrationIconDisplay"

import * as Styles from "./MemberEditModalStyles"

import { MemberEditModalUpdateMemberMutation, MemberEditModalSignUrlMutation } from "./MemberEditModalQuery"
import "react-datepicker/dist/react-datepicker.css"
import DatePickerField from "../Radiate/DatePickerField/DatePickerField"
import NativeSelect from "../Radiate/NativeSelect/NativeSelect"
import TextField from "../Radiate/TextField/TextField"
import TooltipIcon from "../TooltipIcon/TooltipIcon"
import { renderUserNameExternalProfileFirst } from "../util"
import { omitEmptyFieldsBeforeSave, dobValidationCheck, duplicateAliasCheck } from "./MemberEditModalHelper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withTranslatedUtilOptions } from "../../HOC/withTranslatedUtilOptions"

const MemberEditModal = ({
  inboxProfile,
  profile,
  onClose,
  wMemberId,
  i18nLocaleList,
  etag,
  wChannel,
  wChannelId,
  externalId,
  mobile
}) => {
  const { t } = useTranslation("common")

  const [commitUpdateMember, inFlightUpdateMember] = useMutation(MemberEditModalUpdateMemberMutation)
  const [firstName, setFirstName] = useState(inboxProfile?.firstName)
  const [lastName, setLastName] = useState(inboxProfile?.lastName)
  const [profilePicUrl, setProfilePicUrl] = useState(inboxProfile?.profilePicUrl)
  const [locale, setLocale] = useState(inboxProfile?.locale)
  const [phones, setPhones] = useState(inboxProfile?.phones || [])
  const [emails, setEmails] = useState(inboxProfile?.emails || [])
  const [addresses, setAddresses] = useState(inboxProfile?.addresses || [])
  const [dob, setDob] = useState(inboxProfile?.dob || {})
  const [gender, setGender] = useState(inboxProfile?.gender || "")
  const [error, setError] = useState("")
  const [dobValidation, setDobValidation] = useState(true)
  const [website, setWebsite] = useState(inboxProfile?.website || "")
  const [company, setCompany] = useState(inboxProfile?.company || "")
  const [isUploading, setIsUploading] = useState(false)
  const [commitSignUrl] = useMutation(
    MemberEditModalSignUrlMutation
  )
  const uploadFiles = async (selectedFile) => {
    setIsUploading(true)
    const signUrl = (file) => {
      return new Promise((resolve, reject) => {
        let clientMutationId = 0
        commitSignUrl({
          variables: {
            input: {
              fileName: file.name,
              fileType: file.type,
              wChannelId,
              wMemberId,
              clientMutationId: clientMutationId++
            },
          },
          onCompleted: (response, errs) => {
            // console.log("response", response)
            resolve(response?.signUrl?.signUrl)
          },
          onError: (err) => {
            console.log(err)
            reject(err)
          }
        })
      })
    }
    const signedUpload = await signUrl(selectedFile)
    const form = new FormData()
    _.forEach(signedUpload.fields, (value, key) => {
      form.append(key, value)
    })
    form.append("file", selectedFile)
    const finalUrl = await fetch(signedUpload.url, { method: "POST", body: form })
      .then((res) => {
        if (res.status === 204) {
          const split = signedUpload.fields.key.split("/")
          const encodedSplit = split.map(o => encodeURIComponent(o))
          return Promise.resolve(`${signedUpload.url}/${encodedSplit.join("/")}`)
        }
        return null
      })
      setProfilePicUrl(finalUrl)
      setIsUploading(false)
  }

  useEffect(() => {
    if (dob?.year && dob?.month && dob?.date) {
      const result = dobValidationCheck(dob)
      setDobValidation(result)
    }
  }, [JSON.stringify(dob)])


  const updateMemberFunction = ({ profile }) => {
    const modProfile = omitEmptyFieldsBeforeSave(profile)
    let clientMutationId = 0
    let variables = {
      wMemberId: wMemberId,
      etag: etag,
      profile: {
        ...modProfile,
        setProfileArray: true
      },
      clientMutationId: clientMutationId++,
    }
    const mutationParams = {
      variables: {
        input: variables,
      },
      onCompleted: (response, errs) => {
        if (!response.updateMember?.error) {
          onClose()
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        // console.log("errorMsg", errorMsg)
        setError(errorMsg)
      }
    }
    commitUpdateMember(mutationParams)
  }

  const localeOptions = i18nLocaleList.map((locale) => {
    return ({
      label: locale.text,
      value: locale.value
    })
  })

  const genderOptions = [{
    label: t?.("gender_picker.male"),
    value: "male"
  }, {
    label: t?.("gender_picker.female"),
    value: "female"
  }]

  const monthOptions = [{
    label: t?.("month_picker.january"),
    value: 1
  }, {
    label: t?.("month_picker.february"),
    value: 2
  }, {
    label: t?.("month_picker.march"),
    value: 3
  }, {
    label: t?.("month_picker.april"),
    value: 4
  }, {
    label: t?.("month_picker.may"),
    value: 5
  }, {
    label: t?.("month_picker.june"),
    value: 6
  }, {
    label: t?.("month_picker.july"),
    value: 7
  }, {
    label: t?.("month_picker.august"),
    value: 8
  }, {
    label: t?.("month_picker.september"),
    value: 9
  }, {
    label: t?.("month_picker.october"),
    value: 10
  }, {
    label: t?.("month_picker.november"),
    value: 11
  }, {
    label: t?.("month_picker.december"),
    value: 12
  }]

  let daysOfMonth = 0
  if (dob?.year && dob?.month) {
    daysOfMonth = moment().year(dob?.year).month(dob?.month - 1).endOf("month").date().valueOf()
  } 

  let dateOptions = []
  for (let i = 1; i < 32; i++) {
    let disabled = false
    if (daysOfMonth < i && dob?.year && dob?.month) {
      disabled = true
    }
    dateOptions = [
      ...dateOptions,
      {
        label: JSON.stringify(i),
        value: i,
        isDisabled: disabled
      }
    ]
  }

  const thisYear = moment().year()
  let yearOptions = []
  for (let i = thisYear- 100; i < thisYear + 1; i++) {
    yearOptions = [
      ...yearOptions,
      {
        label: JSON.stringify(i),
        value: i
      }
    ]
  }

  const genenalSelectStyle = {
    menu: styles => ({
      ...styles,
      fontSize: "0.85rem",
      maxHeight: "150px"
    }),
    menuList: styles => ({
      ...styles,
      fontSize: "0.85rem",
      maxHeight: "150px"
    }),
    option: styles => ({
      ...styles,
      fontSize: "0.85rem",
    }),
    control: styles => ({
      ...styles,
      fontSize: "0.85rem"
    })
  }

  return (
    <Styles.MemberEditModalContainer
      mobile={mobile}
    >
      <Flex className="header" px={4} py={4} sx={{ alignItems: "center", justifyContent: "space-between" }}>
        <div className="modal-title">{t?.("member_profile.member_info")}</div>
        {/* <FontAwesomeIcon
          className="close-button"
          icon={faTimes}
          onClick={() => {
            onClose()
          }}
        /> */}
      </Flex>
      <div className="separator"></div>
      <Styles.MemberInfoContainer>
        <Flex 
          sx={{
            alignItems: "center",
            flexDirection: mobile ? "column" : "row",
            gap: mobile ? "16px" : "0",
            width: "100%",
          }}
        >
          {/* upload img  */}
          <label
            style={{
              cursor: "pointer",
              marginRight: "16px"
            }}
            htmlFor="upload-image"
          >
            {(!profilePicUrl || isUploading) ? (
              <div
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#929292"
                }}
              >
                <FontAwesomeIcon 
                  style={{
                    color: "white",
                    fontSize: "32px"
                  }}
                  spin={isUploading}
                  icon={isUploading ? faCircleNotch : faCamera}
                />
              </div>

            ) : (
              <IconContainer
                url = { profilePicUrl }
                size = "L"
                name = { firstName }
              />
            )}
            <input
              type="file"
              id="upload-image"
              style={{ display: "none" }}
              accept=".png, .jpg, .jpeg"
              onChange={(e) => {
                const file = e.target.files?.[0]
                if (!file) return
                if (file.size > 2.048 * 1000 * 1000) {
                  const fileSizeCannotBeLargerThan2MB = t?.("member_profile.file_size_limit_error")
                  alert(fileSizeCannotBeLargerThan2MB)
                  return
                }
                if (!/image\/png/i.test(file.type) && !/image\/jpeg/i.test(file.type) && !/image\/jpg/i.test(file.type)) {
                  const fileTypeError = t?.("member_profile.file_type_error")
                  alert(fileTypeError)
                  return
                }
                uploadFiles(file)
              }}
            />
          </label>
          
          <div className="basic-info-container">
            <Styles.InfoContainer>
              <div className="field-label">{t?.("member_profile.channel")}</div>
              <div className="info">
                <IntegrationIconDisplay
                  icon={wChannel?.integrationIcon}
                  name={wChannel?.integrationName}
                  size="20px"
                />
                {wChannel?.integrationName}
              </div>

            </Styles.InfoContainer>
            <Styles.InfoContainer>
              <div className="field-label">{t?.("member_profile.external_id")}</div>
              <div style={{ wordBreak: "break-all" }} className="info">
                {externalId || ""}
              </div>

            </Styles.InfoContainer>
            <Styles.InfoContainer>
              <div className="field-label">
                {t?.("member_profile.user_name")}
                <TooltipIcon
                  fontAwesomeIconName={faQuestionCircle}
                  fontAwesomeIconColor="#707070"
                  iconText={t?.("member_profile.platform_specific_name_tooltip")}
                  backgroundColor={"#3c3c3c"}
                />
              </div>
              <div className="info">
                {renderUserNameExternalProfileFirst({
                  // render name from external profile only
                  inboxProfile: {},
                  externalProfile: profile
                })}
                
              </div>
            </Styles.InfoContainer>
          </div>
        </Flex>
        <Flex py={2}>
          <TextField
            className="member-info-text-field first-name"
            label={t?.("member_profile.first_name")}
            useInput
            value={firstName}
            borderStyle="M"
            placeholder={profile?.firstName || profile?.first_name || profile?.name || profile?.username}
            onChange={(e) => {
              setFirstName(e.target.value)
            }}
          />
          <TextField
            className="member-info-text-field last-name"
            label={t?.("member_profile.last_name")}
            useInput
            value={lastName}
            borderStyle="M"
            onChange={(e) => {
              setLastName(e.target.value)
            }}
          />
        </Flex>
        <Flex py={2}>
          <TextField
            className="member-info-text-field website"
            label={t?.("member_profile.website")}
            useInput
            value={website}
            borderStyle="M"
            onChange={(e) => {
              setWebsite(e.target.value)
            }}
          />
          <TextField
            className="member-info-text-field company"
            label={t?.("member_profile.company")}
            useInput
            value={company}
            borderStyle="M"
            onChange={(e) => {
              setCompany(e.target.value)
            }}
          />
        </Flex>
        <Box py={2}>
          <Styles.LabelContainer>
            <div className="field-label">{t?.("member_profile.emails")}</div>
            <NewBoxButton
              icon={faPlus}
              text={t?.("member_profile.email")}
              primary
              borderless
              onClick={() => {
                const newEmails = [
                  ...emails,
                  {
                    alias: `default${!emails?.length ? "" : emails.length}`,
                    email: ""
                  }
                ]
                setEmails(newEmails)
              }}
            />
          </Styles.LabelContainer>
          {(emails || []).map((email, i) => {
            return (
              <Styles.FieldRow>
                <TextField
                  className="alias-field"
                  placeholder={t?.("member_profile.alias")}
                  borderStyle="M"
                  value={email?.alias}
                  onChange={(e) => {
                    const newEmails = [
                      ...emails.slice(0, i),
                      {
                        alias: e.target.value,
                        email: emails[i]?.email
                      },
                      ...emails.slice(i + 1)
                    ]
                    setEmails(newEmails)
                  }}
                />
                <TextField
                  className="general-text-field"
                  placeholder={t?.("member_profile.email")}
                  borderStyle="M"
                  value={email?.email}
                  onChange={(e) => {
                    const newEmails = [
                      ...emails.slice(0, i),
                      {
                        alias: emails[i]?.alias,
                        email: e.target.value,
                      },
                      ...emails.slice(i + 1)
                    ]
                    setEmails(newEmails)
                  }}
                />
                <NewBoxButton
                  icon={faTrash}
                  borderless
                  onClick={() => {
                    const newEmails = [
                      ...emails.slice(0, i),
                      ...emails.slice(i + 1)
                    ]
                    setEmails(newEmails)
                  }}
                />
              </Styles.FieldRow>
            )
          })}
        </Box>
        {(wChannel?.integrationName !== "WhatsApp Cloud") && (
          <Box py={2}>
            <Styles.LabelContainer>
              <div className="field-label">{t?.("member_profile.phones")}</div>
              <NewBoxButton
                icon={faPlus}
                text={t?.("member_profile.phone")}
                primary
                borderless
                onClick={() => {
                  const newPhones = [
                    ...phones,
                    {
                      alias: `default${!phones?.length ? "" : phones.length}`,
                      number: ""
                    }
                  ]
                  setPhones(newPhones)
                }}
              />
            </Styles.LabelContainer>
            {(phones || []).map((phone, i) => {
              return (
                <Styles.FieldRow>
                  <TextField
                    className="alias-field"
                    placeholder={t?.("member_profile.alias")}
                    borderStyle="M"
                    value={phone?.alias}
                    onChange={(e) => {
                      const newPhones = [
                        ...phones.slice(0, i),
                        {
                          alias: e.target.value,
                          number: phones[i]?.number
                        },
                        ...phones.slice(i + 1)
                      ]
                      setPhones(newPhones)
                    }}
                  />
                  <TextField
                    className="general-text-field"
                    placeholder={t?.("member_profile.phone_number")}
                    borderStyle="M"
                    value={phone?.number}
                    onChange={(e) => {
                      const newPhones = [
                        ...phones.slice(0, i),
                        {
                          alias: phones[i]?.alias,
                          number: e.target.value,
                        },
                        ...phones.slice(i + 1)
                      ]
                      setPhones(newPhones)
                    }}
                  />
                  <NewBoxButton
                    icon={faTrash}
                    borderless
                    onClick={() => {
                      const newPhones = [
                        ...phones.slice(0, i),
                        ...phones.slice(i + 1)
                      ]
                      setPhones(newPhones)
                    }}
                  />
                </Styles.FieldRow>
              )
            })}
          </Box>
        )}
        <Flex py={2}>
          <Box sx={{ width: "50%" }}>
            <div className="field-label">{t?.("member_profile.language")}</div>
            <Styles.MobileUIModal>
              <NativeSelect 
                options={localeOptions}
                value={localeOptions.find(i => i.value === locale)}
                onChange={(value) => {
                  setLocale(value.value)
                }}
              />
            </Styles.MobileUIModal>
            <Styles.DesktopUIModal>
              <Select
                options={localeOptions}
                value={localeOptions.find(i => i.value === locale)}
                onChange={(value) => {
                  setLocale(value.value)
                }}
                styles={genenalSelectStyle}
              />
            </Styles.DesktopUIModal>
          </Box>
          <Box ml={2} sx={{ width: "50%" }}>
            <div className="field-label">{t?.("member_profile.gender")}</div>
            <Styles.MobileUIModal>
              <NativeSelect 
                options={genderOptions}
                value={genderOptions.find(i => i.value === gender)}
                onChange={(value) => {
                  if (value) {
                    setGender(value.value)
                  } else {
                    setGender("")
                  }
                }}
              />
            </Styles.MobileUIModal>
            <Styles.DesktopUIModal>
              <Select
                options={genderOptions}
                value={genderOptions.find(i => i.value === gender)}
                isClearable
                onChange={(value) => {
                  if (value) {
                    setGender(value.value)
                  } else {
                    setGender("")
                  }
                }}
                styles={genenalSelectStyle}
              />
            </Styles.DesktopUIModal>
          </Box>
        </Flex>
        <Box py={2}>
          <div className="field-label">{t?.("member_profile.date_of_birth")}</div>
          <Styles.MobileUIModal>
            <DatePickerField
              selected={dob}
              onChange={(date) => {
                setDob({
                  date: date?.date,
                  month: date?.month,
                  year: date?.year
                });
              }}
            />
          </Styles.MobileUIModal>
          <Styles.DesktopUIModal>
            <Flex>
              <Box sx={{ width: "40%" }}>
                <Select
                  options={monthOptions}
                  placeholder={t?.("member_profile.month")}
                  value={monthOptions.find(i => i.value === dob?.month)}
                  isClearable
                  onChange={(value) => {
                    if (value) {
                      const newDob = {
                        ...dob,
                        month: value.value
                      }
                      setDob(newDob)
                    } else {
                      const newDob = {
                        ...dob,
                        month: null
                      }
                      setDob(newDob)
                    }
                  }}
                  styles={genenalSelectStyle}
                />
              </Box>
              <Box ml={2} sx={{ width: "20%" }}>
                <Select
                  options={dateOptions}
                  placeholder={t?.("member_profile.date")}
                  value={dateOptions.find(i => i.value === dob?.date)}
                  isClearable
                  onChange={(value) => {
                    if (value) {
                      const newDob = {
                        ...dob,
                        date: value.value
                      }
                      setDob(newDob)
                    } else {
                      const newDob = {
                        ...dob,
                        date: null
                      }
                      setDob(newDob)
                    }
                  }}
                  styles={genenalSelectStyle}
                />
              </Box>
              <Box ml={2} sx={{ width: "40%" }}>
                <Select
                  options={yearOptions.reverse()}
                  placeholder={t?.("member_profile.year")}
                  value={yearOptions.find(i => i?.value === dob?.year)}
                  isClearable
                  onChange={(value) => {
                    if (value) {
                      const newDob = {
                        ...dob,
                        year: value.value
                      }
                      setDob(newDob)
                    } else {
                      const newDob = {
                        ...dob,
                        year: null
                      }
                      setDob(newDob)
                    }
                  }}
                  styles={genenalSelectStyle}
                />
              </Box>
            </Flex>
          </Styles.DesktopUIModal>
          {dobValidation === false && <div className="dob-error">{t?.("member_profile.error_date_of_birth_invalid")}</div>}
        </Box>
        <Box py={2}>
          <Styles.LabelContainer>
            <div className="field-label">{t?.("member_profile.addresses")}</div>
            <NewBoxButton
              icon={faPlus}
              text={t?.("member_profile.address")}
              primary
              borderless
              onClick={() => {
                const newAddresses = [
                  ...addresses,
                  {
                    alias: `default${!addresses?.length ? "" : addresses.length}`,
                    line1: "",
                    line2: "",
                    city: "",
                    state: "",
                    country: "",
                    zip: ""
                  }
                ]
                setAddresses(newAddresses)
              }}
            />
          </Styles.LabelContainer>
          {(addresses || []).map((address, i) => {
            return (
              <Styles.AddressField>
                <Flex sx={{ width: "100%", justifyContent: "space-between"}}>
                  <TextField
                    className="alias-field"
                    placeholder={t?.("member_profile.alias")}
                    borderStyle="M"
                    value={address?.alias}
                    onChange={(e) => {
                      const newAddresses = [
                        ...addresses.slice(0, i),
                        {
                          alias: e.target.value,
                          line1: addresses[i]?.line1,
                          line2: addresses[i]?.line2,
                          city: addresses[i]?.city,
                          state: addresses[i]?.state,
                          country: addresses[i]?.country,
                          zip: addresses[i]?.zip
                        },
                        ...addresses.slice(i + 1)
                      ]
                      setAddresses(newAddresses)
                    }}
                  />
                  <NewBoxButton
                    icon={faTrash}
                    borderless
                    onClick={() => {
                      const newAddresses = [
                        ...addresses.slice(0, i),
                        ...addresses.slice(i + 1)
                      ]
                      setAddresses(newAddresses)
                    }}
                  />
                </Flex>
                <TextField
                  className="address-text-field"
                  placeholder={t?.("member_profile.address_line_1")}
                  borderStyle="M"
                  value={address?.line1}
                  onChange={(e) => {
                    const newAddresses = [
                      ...addresses.slice(0, i),
                      {
                        alias: addresses[i]?.alias,
                        line1: e.target.value,
                        line2: addresses[i]?.line2,
                        city: addresses[i]?.city,
                        state: addresses[i]?.state,
                        country: addresses[i]?.country,
                        zip: addresses[i]?.zip
                      },
                      ...addresses.slice(i + 1)
                    ]
                    setAddresses(newAddresses)
                  }}
                />
                <TextField
                  className="address-text-field"
                  placeholder={t?.("member_profile.address_line_2")}
                  borderStyle="M"
                  value={address?.line2}
                  onChange={(e) => {
                    const newAddresses = [
                      ...addresses.slice(0, i),
                      {
                        alias: addresses[i]?.alias,
                        line2: e.target.value,
                        line1: addresses[i]?.line1,
                        city: addresses[i]?.city,
                        state: addresses[i]?.state,
                        country: addresses[i]?.country,
                        zip: addresses[i]?.zip
                      },
                      ...addresses.slice(i + 1)
                    ]
                    setAddresses(newAddresses)
                  }}
                />
                <Flex sx={{ width: "100%" }}>
                  <TextField
                    className="address-text-field left-field"
                    placeholder={t?.("member_profile.city")}
                    borderStyle="M"
                    value={address?.city}
                    onChange={(e) => {
                      const newAddresses = [
                        ...addresses.slice(0, i),
                        {
                          alias: addresses[i]?.alias,
                          city: e.target.value,
                          line1: addresses[i]?.line1,
                          line2: addresses[i]?.line2,
                          state: addresses[i]?.state,
                          country: addresses[i]?.country,
                          zip: addresses[i]?.zip
                        },
                        ...addresses.slice(i + 1)
                      ]
                      setAddresses(newAddresses)
                    }}
                  />
                  <TextField
                    className="address-text-field"
                    placeholder={t?.("member_profile.state")}
                    borderStyle="M"
                    value={address?.state}
                    onChange={(e) => {
                      const newAddresses = [
                        ...addresses.slice(0, i),
                        {
                          alias: addresses[i]?.alias,
                          state: e.target.value,
                          city: addresses[i]?.city,
                          line1: addresses[i]?.line1,
                          line2: addresses[i]?.line2,
                          country: addresses[i]?.country,
                          zip: addresses[i]?.zip
                        },
                        ...addresses.slice(i + 1)
                      ]
                      setAddresses(newAddresses)
                    }}
                  />
                </Flex>
                <Flex>
                  <TextField
                    className="address-text-field left-field"
                    placeholder={t?.("member_profile.country")}
                    borderStyle="M"
                    value={address?.country}
                    onChange={(e) => {
                      const newAddresses = [
                        ...addresses.slice(0, i),
                        {
                          alias: addresses[i]?.alias,
                          country: e.target.value,
                          line1: addresses[i]?.line1,
                          line2: addresses[i]?.line2,
                          city: addresses[i]?.city,
                          state: addresses[i]?.state,
                          zip: addresses[i]?.zip
                        },
                        ...addresses.slice(i + 1)
                      ]
                      setAddresses(newAddresses)
                    }}
                  />
                  <TextField
                    className="address-text-field"
                    placeholder={t?.("member_profile.zip")}
                    borderStyle="M"
                    value={address?.zip}
                    onChange={(e) => {
                      const newAddresses = [
                        ...addresses.slice(0, i),
                        {
                          alias: addresses[i]?.alias,
                          zip: e.target.value,
                          line1: addresses[i]?.line1,
                          line2: addresses[i]?.line2,
                          city: addresses[i]?.city,
                          state: addresses[i]?.state,
                          country: addresses[i]?.country,
         
                        },
                        ...addresses.slice(i + 1)
                      ]
                      setAddresses(newAddresses)
                    }}
                  />
                </Flex>
              </Styles.AddressField>
            )
          })}
        </Box>
      </Styles.MemberInfoContainer>
      <Styles.SaveButtonContainer>
        {error &&
          <div className="error">{error}</div>
        }
        <NewBoxButton
          sx={{ marginRight: "8px" }}
          inverted
          text={t?.("common:buttons.cancel")}
          icon={faTimes}
          onClick={() => {
            onClose()
          }} 
        />
        <NewBoxButton
          text={t?.("common:buttons.save")}
          primary
          loading={inFlightUpdateMember}
          onClick={() => {
            const pass = dobValidationCheck(dob)
            if (!pass && (dob?.date || dob?.month || dob?.year)) {
              const alertDateOfBirthInvalid = t?.("member_profile.alert_date_of_birth_invalid")
              alert(alertDateOfBirthInvalid)
              return
            }
            if (duplicateAliasCheck({ data: emails })) {
              const memberProfileAlertEmailAliasCannotBeDuplicated = t?.("member_profile.alert_email_alias_cannot_be_duplicated")
              alert(memberProfileAlertEmailAliasCannotBeDuplicated)
              return
            }
            if (duplicateAliasCheck({ data: phones })) {
              const memberProfileAlertPhoneAliasCannotBeDuplicated = t?.("member_profile.alert_phone_alias_cannot_be_duplicated")
              alert(memberProfileAlertPhoneAliasCannotBeDuplicated)
              return
            }
            if (duplicateAliasCheck({ data: addresses })) {
              const memberProfileAlertAddressAliasCannotBeDuplicated = t?.("member_profile.alert_address_alias_cannot_be_duplicated")
              alert(memberProfileAlertAddressAliasCannotBeDuplicated)
              return
            }

            const newProfile = {
              firstName,
              lastName,
              website,
              company,
              profilePicUrl,
              locale,
              phones,
              emails,
              addresses,
              gender,
              dob
            }
            updateMemberFunction({ profile: newProfile })
          }}
        />
      </Styles.SaveButtonContainer>
    </Styles.MemberEditModalContainer>
  )
}

export default withTranslatedUtilOptions(MemberEditModal)
