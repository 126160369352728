/** @jsxImportSource theme-ui */

import {
  faReply,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import Menu from "./Menu"
import MenuButton from "./MenuButton"

const MessageMenu = ({
  onMarkUnread,
  onReplyToMessage,
  isWhatsappCloudThread = false,
}) => {
  const { t } = useTranslation("common")
  return (
    <Menu>
      {(onMarkUnread) && (
        <MenuButton
          icon={faRotateLeft}
          onClick={onMarkUnread}
        >{t?.("chat_message.mark_unread")}
        </MenuButton>
      )}
      {(onReplyToMessage && isWhatsappCloudThread) && (
        <MenuButton
          icon={faReply}
          onClick={onReplyToMessage}
        >
          reply to message
        </MenuButton>
      )}
    </Menu>
  )
}

export default MessageMenu
