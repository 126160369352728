import graphql from "babel-plugin-relay/macro"

export const ConversationHistoryCreateChatMutation = graphql`
  mutation ConversationHistoryQuery_createChatMutation (
    $input: CreateChatInput!
  ) {
    createChat(input: $input) {
      clientMutationId
      error {
        message
        code
      }
      chat {
        _id
        messageEvent
        replyToMessageEvent
        createdAt
        deliveredAt
        readAt
        sentAt
        from
        internal
        wUserId
        threadId
        error
        mentions {
          wUserId
          firstName
          lastName
          email {
            email
          }
        }
        agent {
          name
          icon
        }
        user {
          profilePic
          email {
            email
          }
        }
      }
    }
  }
`

export const ConversationHistoryCreateInternalChatMutation = graphql`
  mutation ConversationHistoryQuery_createInternalChatMutation (
    $input: CreateChatInput!
  ) {
    createInternalChat(input: $input) {
      clientMutationId
      error {
        message
        code
      }
      chat {
        _id
        messageEvent
        createdAt
        deliveredAt
        readAt
        sentAt
        from
        internal
        wUserId
        threadId
        error
        mentions {
          wUserId
          firstName
          lastName
          email {
            email
          }
        }
        agent {
          name
          icon
        }
        user {
          profilePic
          email {
            email
          }
        }
      }
    }
  }
`

export const ConversationHistorySignUrlMutation = graphql`
  mutation ConversationHistoryQuery_signUrlMutation ($input: SignUrlInput!) {
   signUrl(input: $input) {
     signUrl
    }
  }
`

export const ConversationHistorySubscription = graphql`
  subscription ConversationHistoryQuerySubscription (
    $threadId: ID!
  ) {
    chat (threadId: $threadId) {
      _id
      messageEvent
      replyToMessageEvent
      createdAt
      deliveredAt
      readAt
      sentAt
      from
      internal
      wUserId
      threadId
      error
      mentions {
        wUserId
        firstName
        lastName
        email {
          email
        }
      }
      agent {
        name
        icon
      }
      user {
        profilePic
        email {
          email
        }
      }
    }
  }
`

export const ConversationHistoryChatStatusSubscription = graphql`
  subscription ConversationHistoryQueryChatStatusSubscription (
    $threadId: ID!
  ) {
    chatStatus (threadId: $threadId) {
      _id
      messageEvent
      createdAt
      deliveredAt
      readAt
      sentAt
      from
      internal
      wUserId
      threadId
      error
      failedAt
      agent {
        name
        icon
      }
      user {
        profilePic
        email {
          email
        }
      }
    }
  }
`

export const ConversationHistoryAssignThreadMutation = graphql`
   mutation ConversationHistoryQuery_assignThreadMutation (
    $input: AssignThreadInput!
  ) {
    assignThread(input: $input) {
      clientMutationId
      thread {
        threadId
        folderId
        folder {
          folderId
          name
        }
        member {
          id
          meta
          externalId
          externalProfile
          inboxProfile {
            firstName
            lastName
            profilePicUrl
          }
          liveChat
        }
        wMemberId
        wChannelId
        wChannel {
          name
          integrationIcon
          integrationName
        }
        inputActions {
          integrationIcon
          integrationName
          integrationDescription
          integrationId
          integrationBuild
          iframeContext {
            payload
            signedContext
          }
          contextToken
          customActions {
            url
            body
            headers
            method
            description
            modalSchema
            modalView
            title
            type
          }
        }
        resourceTemplates {
          preview
          alias
        }
        wUserId
        user {
          wUserId
          email {
            email
            verified
          }
          firstName
          lastName
        }
        etag
      }
    }
  }
`

export const ConversationHistoryUpdateThreadFolderMutation = graphql`
   mutation ConversationHistoryQuery_updateThreadFolderMutation (
    $input: UpdateThreadFolderInput!
  ) {
    updateThreadFolder(input: $input) {
      clientMutationId
      thread {
        threadId
        folderId
        folder {
          folderId
          name
        }
        member {
          id
          meta
          externalId
          externalProfile
          inboxProfile {
            firstName
            lastName
            profilePicUrl
          }
          liveChat
        }
        wMemberId
        wChannelId
        wChannel {
          name
          integrationIcon
          integrationName
        }
        inputActions {
          integrationIcon
          integrationName
          integrationDescription
          integrationId
          integrationBuild
          iframeContext {
            payload
            signedContext
          }
          contextToken
          customActions {
            url
            body
            headers
            method
            description
            modalSchema
            modalView
            title
            type
          }
        }
        resourceTemplates {
          preview
          alias
        }
        wUserId
        user {
          wUserId
          email {
            email
            verified
          }
          firstName
          lastName
        }
        etag
      }
    }
  }
`

export const ConversationHistoryToggleMemberLiveChatMutation = graphql`
  mutation ConversationHistoryQuery_toggleMemberLiveChatMutation (
    $input: ToggleMemberLiveChatInput!
  ) {
    toggleMemberLiveChat(input: $input) {
      clientMutationId
      error {
        message
        code
      }
      member {
        id
        meta
        externalId
        externalProfile
        inboxProfile {
          firstName
          lastName
          profilePicUrl
        }
        liveChat
      }
    }
  }
`

export const readThreadMutation = graphql`
  mutation ConversationHistoryQuery_readThreadMutation (
    $input: ReadThreadInput!
  ) {
    readThread(input: $input) {
      clientMutationId
      ok
      error {
        message
      }
    }
  }
`

export const unreadThreadMutation = graphql`
  mutation ConversationHistoryQuery_unreadThreadMutation (
    $input: UnreadThreadInput!
  ) {
    unreadThread(input: $input) {
      clientMutationId
      ok
      error {
        message
      }
    }
  }
`
