import React from "react"
import { css } from "@emotion/react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "@emotion/styled"
import {
  faMicrophone
} from "@fortawesome/free-solid-svg-icons"
import { MOBILE_MESSAGE_MAX_WIDTH, MOBILE_BREAKPOINT } from "../../../const"
import { resourceTemplateTheme } from "../../../stylesheets/theme"

const AudioBubble = styled.div`
  background-color: ${props => props.isWhatsappCloudThread && props.isAgent ? resourceTemplateTheme.bot.whatsapp.colors.textBackgroundWhite : "#f1f3f4"};
  border-radius: 8px;
  padding: 0;
  max-width: 100%;

  audio {
    ${(props) => {
      if (props.isWhatsappCloudThread && props.isAgent) {
        return css`
          &::-webkit-media-controls-panel {
            background-color: ${resourceTemplateTheme.bot.whatsapp.colors.textBackgroundWhite};
            color: ${resourceTemplateTheme.bot.whatsapp.colors.textForegroundDark};
          }
          &::-webkit-media-controls-mute-button {
            background-color: ${resourceTemplateTheme.bot.whatsapp.colors.textBackgroundWhite};
            color: ${resourceTemplateTheme.bot.whatsapp.colors.textForegroundDark};
          }
          &::-webkit-media-controls-play-button {
            background-color: ${resourceTemplateTheme.bot.whatsapp.colors.textBackgroundWhite};
            color: ${resourceTemplateTheme.bot.whatsapp.colors.textForegroundDark};
          }
          &::-webkit-media-controls-current-time-display {
            background-color: ${resourceTemplateTheme.bot.whatsapp.colors.textBackgroundWhite};
            color: ${resourceTemplateTheme.bot.whatsapp.colors.textForegroundDark};
          }
          &::-webkit-media-controls-time-remaining-display {
            background-color: ${resourceTemplateTheme.bot.whatsapp.colors.textBackgroundWhite};
            color: ${resourceTemplateTheme.bot.whatsapp.colors.textForegroundDark};
          }
          &::-webkit-media-controls-timeline {
            background-color: ${resourceTemplateTheme.bot.whatsapp.colors.textBackgroundWhite};
            color: ${resourceTemplateTheme.bot.whatsapp.colors.textForegroundDark};
          }
          &::-webkit-media-controls-volume-slider {
            background-color: ${resourceTemplateTheme.bot.whatsapp.colors.textBackgroundWhite};
            color: ${resourceTemplateTheme.bot.whatsapp.colors.textForegroundDark};
          }
        `
      }
    }}
    vertical-align: bottom;
     &:focus {
      outline: none;
    }
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      width: ${MOBILE_MESSAGE_MAX_WIDTH};
    }
  }
`

const AudioBubblePlaceholder = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 160px;
  height: 160px;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => {
    if (props.isAgent && props.isWhatsappCloudThread) {
      return resourceTemplateTheme.bot.whatsapp.colors.textBackgroundWhite
    }
    return props.isAgent ? props.theme.themeColorGreen : "#FFFFFF"
  }};

  .icon {
    font-size: 50px;
    color: ${(props) => {
      if (props.isAgent && props.isWhatsappCloudThread) {
        return resourceTemplateTheme.bot.whatsapp.colors.textForegroundLight
      }
      return props.isAgent ? "#ffffff" : props.theme.gray4
    }};
    margin-bottom: 8px;
  }

  span {
    font-size: ${(props) => props.theme.textSm};
    text-align: center;
    color: ${(props) => {
      if (props.isAgent && props.isWhatsappCloudThread) {
        return resourceTemplateTheme.bot.whatsapp.colors.textForegroundDark
      }
      return props.isAgent ? "#ffffff" : props.theme.gray7
    }};
  }
`

const Audio = ({ data, isAgent, integrationId }) => {
  const { t } = useTranslation("common")
  const url = data.url || data.downloadUrl || _.get(data, "attachments[0].url")
  const isWhatsappCloudThread = /^whatsapp-cloud/.test(integrationId)
  if (url) {
    return (
      <AudioBubble isAgent={isAgent} isWhatsappCloudThread={isWhatsappCloudThread}>
        <audio controls>
          <source src={url} />
        </audio>
      </AudioBubble>
    )
  }
  return (
    <AudioBubblePlaceholder isAgent={isAgent} isWhatsappCloudThread={isWhatsappCloudThread}>
      <FontAwesomeIcon
        className="icon"
        icon={faMicrophone}
      />
      <span>{t?.("message_types.audio.audio_cannot_be_loaded")}</span>
    </AudioBubblePlaceholder>
  )
}

export default Audio
