import graphql from "babel-plugin-relay/macro"

export const ThreadsUpdateThreadsFolderMutation = graphql`
  mutation ManageThreadsPopperQuery_updateThreadsFolderMutation (
    $input: UpdateThreadsFolderInput!
  ) {
    updateThreadsFolder(input: $input) {
        clientMutationId
        ok
        followUpActions {
          channelNames
          folderName
        }
        error {
          code
          message
        }
        threads {
          id
        }
        threadsConflictResult {
          conflicts {
          channelId
          channelName
          hiddenCount
          totalCount
          threads {
            threadId
            folderId
            folder {
              folderId
              name
              color
            }
            wMemberId
            wChannelId
            wChannel {
              name
              integrationIcon
              integrationName
            }
            etag
            title
            updatedAt
            lastChatAt
            lastMessage
            lastReadAt
            unreadCount
            title
            member {
              meta
              externalId
              externalProfile
              inboxProfile {
                firstName
                lastName
                name
                profilePicUrl
              }
            }
          }
        }
      }
      backgroundTaskId
      destinationFolder {
        id
        name
        folderId
      }
    }
  }
`
