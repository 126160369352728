/** @jsxImportSource theme-ui */

import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
  faExclamationCircle,
  faRedo,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons"
import styled from "@emotion/styled"
import Slider from "react-slick"
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel"
import { Buffer } from "buffer"
import { timestampNormalizer, detectEmailAndUrl } from "../util"
import Text from "../Text/Text"
import Image from "../Image/Image"
import File from "../File/File"
import Audio from "../Audio/Audio"
import Video from "../Video/Video"
import Payload from "../Payload/Payload"
import LiveChatPayload from "../LiveChatPayload/LiveChatPayload"
import MediaDownloadWrapper from "../MediaDownload/MediaDownload"
import Misc from "../Misc/Misc"
import Raw from "../Raw/Raw"
import MentionText from "../MentionText/MentionText"

import PopperContainer from "../../Radiate/PopperContainer/PopperContainer"
import IconContainer from "../../IconContainer/IconContainer"
import PopupMenuCollapseButton from "../PopupMenu/CollapseButton"
import { WhatsappMarkdown } from "../WhatsappMarkdown/WhatsappMarkdown"
import ExternalLinkOutWrapper from "../../ExternalLinkOutWrapper/ExternalLinkOutWrapper"
import { MOBILE_BREAKPOINT, MOBILE_MESSAGE_MAX_WIDTH, WHATSAPP_CLOUD_MSG_ON_HOVER_COLOR } from "../../../const"
import { resourceTemplateTheme } from "../../../stylesheets/theme"
import { ReactLive } from "../../ReactLive"
import { ReplyPreview as ReplyPreviewWrapper } from "../ReplyPreview/ReplyPreview"

const unreadColor = "#0063f00f"
const unreadHoverColor = "#0063f005"

const Container = styled.div`
  transition: background 0.1s linear;
  background: ${(props) => props.unread ? unreadColor : "transparent"};
  .popup-menu {
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
  background: ${(props) => {
    if (props.isWhatsappCloudThread && !props.unread) {
      return WHATSAPP_CLOUD_MSG_ON_HOVER_COLOR
    }
    return props.unread ? unreadHoverColor : "rgba(255, 255, 255, 0.5)"
  }};
    .popup-menu {
      opacity: 1;
      pointer-events: all;
    }
  }
`

const Row = styled.div`
  padding-bottom: ${(props) => props.hasProfileInfo && "4px"} !important;
`

const UserBubbleContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  >.raw {
    font-size: ${(props) => props.theme.textSm};
    font-family: 'Courier New', Courier, monospace;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    max-width: ${MOBILE_MESSAGE_MAX_WIDTH};
  }
`

const UserBubbleSideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 32px;
  
  .time-display {
    padding: 0 4px;
    font-size: ${(props) => props.theme.textXs};
    color: #555;
  }
`

const RetryButton = styled.div`
  padding: 6px 8px;
  cursor: pointer;
  color: #626262;
  
  
  .redo-icon {
    padding-right: 4px;
  }
`

const ChatAgentProfile = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.textXs};
  justify-content: flex-end;
  gap: 8px;
  color: ${(props) => props.theme.gray7};
  padding-left: 32px;
  padding-right: 8px;
  margin-bottom: 4px;

  .agent-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
  }
`

function renderMessage(message, color, mentions, ownUserId, integrationId) {
  if (mentions?.length > 0) {
    return (
      <MentionText isAgent data={message.data} color={color} mentions={mentions} ownUserId={ownUserId} integrationId={integrationId} />
    )
  }
  switch (message.type) {
    case "TEXT":
      return (<Text isAgent color={color} data={message.data} integrationId={integrationId} />)
    case "IMAGE":
      return (<Image isAgent color={color} data={message.data} integrationId={integrationId} />)
    case "FILE":
      return (<File isAgent color={color} data={message.data} integrationId={integrationId} />)
    case "AUDIO":
      return (<Audio isAgent color={color} data={message.data} integrationId={integrationId} />)
    case "VIDEO":
      return (<Video isAgent color={color} data={message.data} integrationId={integrationId} />)
    case "MISC":
      return (<Misc isAgent color={color} data={message.data} integrationId={integrationId} />)
    case "LIVE_CHAT_PAYLOAD":
      return (<LiveChatPayload isAgent color={color} data={message.data} integrationId={integrationId} />)
    case "PAYLOAD":
      return (<Payload isAgent color={color} data={message.data} integrationId={integrationId} />)
    default:
      return (<Raw data={message} integrationId={integrationId} />)
  }
}

const Agent = ({
  message,
  profilePic,
  noTimeDisplay,
  themeColor,
  createChatError = null,
  createdAt,
  onResend = () => { },
  clientMutationId,
  readAt,
  deliveredAt,
  failedAt,
  chatId,
  error,
  name,
  email,
  byMe,
  unread,
  messageMenu,
  messageMenuStyle,
  messageMenuPlacement,
  isLiveChat,
  mentions,
  ownUserId,
  app,
  appIntegration,
  channel,
  appIntegrationSignature,
  isConversation = false,
  resourceTemplate = null,
  isWhatsappCloudThread,
  integrationId,
  isInternalChat,
  ...props
}) => {
  const { t } = useTranslation("common")
  const [showRetryButton, setShowRetryButton] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)

  let timestamp = null
  if (timestampNormalizer(message.timestamp)) {
    timestamp = moment(timestampNormalizer(message.timestamp)).format("HH:mm")
  } else if (timestampNormalizer(createdAt)) {
    timestamp = moment(timestampNormalizer(createdAt)).format("HH:mm")
  }

  const payload = {
    app,
    appIntegration,
    channel,
  }

  let encodedPayload = null
  if (Buffer) {
    encodedPayload = Buffer.from(JSON.stringify(payload)).toString("base64")
  }

  const signedContext = `${appIntegrationSignature}.${encodedPayload}`

  const MediaDownload = useMemo(() => {
    return MediaDownloadWrapper({
      isConversation,
      from: "BOT",
      isWhatsappCloudThread,
    })
  }, [isConversation, isWhatsappCloudThread])

  const ReplyPreview = useMemo(() => {
    return ReplyPreviewWrapper({
      fileId: message?.replyToMessageEvent?.file?.fileId,
      chatId,
      isConversation,
      from: "MEMBER",
      replyToType: message?.replyToMessageEvent?.type,
      replyToFrom: message?.replyToMessageEvent?.from,
      replyWithType: message?.type,
      payload,
      signedContext,
    })
  },
  [
    message?.replyToMessageEvent?.file?.fileId,
    chatId,
    isConversation,
    message?.replyToMessageEvent?.type,
    message?.replyToMessageEvent?.from,
    app,
    appIntegration,
    channel,
    signedContext,
  ])

  const scope = useMemo(() => {
    return {
      styled,
      Slider,
      FontAwesomeIcon,
      ResponsiveCarousel,
      serverApiPath: `${process.env.REACT_APP_WOZTELL_URL}/api`,
      MediaDownload,
      WhatsappMarkdown,
      detectEmailAndUrl,
      ReplyPreview,
      data: {
        ...message?.data,
        isReply: message?.isReply,
        replyToPreview: message?.replyToPreview,
        replyToMessageEvent: message?.replyToMessageEvent,
        isConversation,
        from: "BOT",
        isInbox: true,
        ExternalLinkOutWrapper,
      },
      theme: {
        ...resourceTemplateTheme.bot,
        user: {
          ...resourceTemplateTheme.user,
        },
        bot: {
          ...resourceTemplateTheme.bot,
        }
      },
      payload,
      signedContext,
    }
  }, [
    MediaDownload,
    ReplyPreview,
    JSON.stringify(message?.data),
    message?.isReply,
    message?.replyToPreview,
    JSON.stringify(message?.replyToMessageEvent),
    isConversation,
    app,
    appIntegration,
    channel,
    signedContext,
  ])

  if (["REFERRAL", "COMMENT"].indexOf(message.type) > -1) {
    return null
  }

  // console.log("INBOX FRONT-END Agent scope: ", scope)
  
  const timeInfo = {
    readAt,
    deliveredAt,
    failedAt
  }

  const renderAgentProfile = () => {
    if (name) {
      return (
        <ChatAgentProfile>
          <div className="agent-name">{name}</div>
          <IconContainer
            icon={faHeadset}
            size="XS"
            name={name}
            color={isLiveChat ? "#405DE6" : "#626262"}
          />
        </ChatAgentProfile>
      )
    } else if (email) {
      return (
        <ChatAgentProfile>
          <div className="agent-name">{email}</div>
          <IconContainer
            icon={faHeadset}
            size="XS"
            name={email}
            color={isLiveChat ? "#405DE6" : "#626262"}
          />
        </ChatAgentProfile>
      )
    }
  }

  return (
    <Container
      isWhatsappCloudThread={isWhatsappCloudThread}
      unread={unread}
      onMouseLeave={() => {
        setOpenMenu(false)
      }}
    >
      <Row 
        className="row agent"
        hasProfileInfo={!byMe}
      >
        <div
          className={`content-info ${noTimeDisplay ? "noTimeDisplay" : ""}`}
          sx={{
            position: "relative"
          }}
        >
          <MemoizedAgentBubbleContent
            resourceTemplate={resourceTemplate}
            isWhatsappCloudThread={isWhatsappCloudThread}
            scope={scope}
            message={message}
            themeColor={themeColor}
            mentions={mentions}
            ownUserId={ownUserId}
            integrationId={integrationId}
            isInternalChat={isInternalChat}
          />
          <UserBubbleSideContent>
            {createChatError &&
              <PopperContainer
                placement="left-start"
                onClickOutside={() => {
                  setShowRetryButton(false)
                }}
                show={showRetryButton}
                display={(
                  <>
                    <FontAwesomeIcon
                      className="retry error-icon"
                      icon={faExclamationCircle}
                      data-tooltip-content={createChatError?.message}
                      data-tooltip-id="chat-visualizer-tooltip"
                      data-tooltip-place="left"
                      onClick={() => {
                        setShowRetryButton(true)
                      }}
                    />
                  </>
                )}
              >
                {() => (
                  <RetryButton
                    onClick={() => {
                      onResend({
                        clientMutationId,
                        messageEvent: message
                      })
                      setShowRetryButton(false)
                    }}
                  >
                    <FontAwesomeIcon
                      className="redo-icon"
                      icon={faRedo}
                    />
                    {t?.("chat_message.retry")}
                  </RetryButton>
                )}
              </PopperContainer>
            }
            {(!createChatError && error) &&
              <>
                <FontAwesomeIcon
                  className="error-icon"
                  icon={faExclamationTriangle}
                  data-tooltip-content={error}
                  data-tooltip-id="chat-visualizer-tooltip"
                  data-tooltip-place="left"
                />
              </>
            }
            {noTimeDisplay ?
              null
              :
              <div
                className="time-display"
                data-tooltip-content={(failedAt || readAt || deliveredAt) ? JSON.stringify(timeInfo) : null}
                data-tooltip-id="chat-visualizer-tooltip"
                data-tt-type="time-display"
                data-tooltip-place="left"
              >
                {timestamp}
              </div>
            }
            {messageMenu && (
              <PopperContainer
                className="popup-menu"
                display={(
                  <PopupMenuCollapseButton
                    onClick={() => {
                      setOpenMenu(true)
                    }}
                  />
                )}
                sx={messageMenuStyle}
                placement={messageMenuPlacement}
                show={openMenu}
                onClickOutside={() => {
                  setOpenMenu(false)
                }}
                addDefaultWrapper={false}
              >
                {() => messageMenu}
              </PopperContainer>
            )}
          </UserBubbleSideContent>
        </div>
      </Row>
      {byMe ? null : renderAgentProfile()}
    </Container>
  )
}


const AgentBubbleContentWrapper = ({
  resourceTemplate,
  isWhatsappCloudThread,
  scope,
  message,
  themeColor,
  mentions,
  ownUserId,
  integrationId,
  isInternalChat,
}) => {
  return (
    <UserBubbleContent>
      {/* if future decide all message type in agent use RT to show preview, then can remove the alias checks */}
      {(resourceTemplate?.preview && !isInternalChat && (resourceTemplate?.alias === "TEXT" || resourceTemplate?.alias === "TEMPLATE" || resourceTemplate?.alias === "CATALOG" || resourceTemplate?.alias === "PRODUCT")) ?
        <ReactLive
          useV2ReactLive={!isWhatsappCloudThread}
          resourceTemplate={resourceTemplate}
          scope={scope}
        />
        :
        <>
          {renderMessage(message, themeColor, mentions, ownUserId, integrationId)}
        </>
      }
    </UserBubbleContent>
  )
}

const MemoizedAgentBubbleContent = React.memo(AgentBubbleContentWrapper, (prevProps, props) => {
  if (!_.isEqual(prevProps.resourceTemplate, props.resourceTemplate)) {
    return false
  }
  if (!_.isEqual(prevProps.isWhatsappCloudThread, props.isWhatsappCloudThread)) {
    return false
  }
  if (!_.isEqual(prevProps.message, props.message)) {
    return false
  }
  if (!_.isEqual(prevProps.themeColor, props.themeColor)) {
    return false
  }
  if(!_.isEqual(prevProps.mentions, props.mentions)) {
    return false
  }
  if (!_.isEqual(prevProps.ownUserId, props.ownUserId)) {
    return false
  }
  if (!_.isEqual(prevProps.integrationId, props.integrationId)) {
    return false
  }
  // scope is memoized, so just compare reference
  if (prevProps.scope !== props.scope) {
    return false
  }
  if (prevProps.isInternalChat !== props.isInternalChat) {
    return false
  }
  return true
})

export default React.memo(Agent, (prevProps, props) => {
  if (prevProps.createChatError !== props.createChatError) {
    return false
  }
  if (prevProps.readAt !== props.readAt) {
    return false
  }
  if (prevProps.deliveredAt !== props.deliveredAt) {
    return false
  }
  if (prevProps.failedAt !== props.failedAt) {
    return false
  }
  if (prevProps.error !== props.error) {
    return false
  }
  if (prevProps.chatId !== props.chatId) {
    return false
  }
  if (prevProps.unread !== props.unread) {
    return false
  }
  if (!_.isEqual(prevProps.messageMenu, props.messageMenu)) {
    return false
  }
  return true
})
