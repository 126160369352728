import React, { useState, useEffect, useTransition, useContext } from "react"
import { useTranslation } from "react-i18next"
import graphql from "babel-plugin-relay/macro"
import {
  useRefetchableFragment,
  useMutation
} from "react-relay"
import _ from "lodash"
import { Flex } from "theme-ui"
import {
  faPlus,
  faPen,
  faTimes,
  faEnvelope,
  faPhone,
  faUserGroup
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CopyToClipboard } from "react-copy-to-clipboard"

import ReactTooltip from "../ReactTooltip/ReactTooltip"
import IconContainer from "../IconContainer/IconContainer"
import IntegrationIconDisplay from "../IntegrationIconDisplay/IntegrationIconDisplay"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import Modal from "../../components/Radiate/Modal/Modal"
import MemberEditModal from "../../components/MemberEditModal/MemberEditModal"
import { LoadingMask } from "../../CommonStyles"
import { UserContext } from "../../contexts/UserContext"

import * as Styles from "./MemberContainerStyles"

import {
  MemberContainerAddTagToMemberMutation,
  MemberContainerRemoveTagFromMemberMutation,
} from "./MemberContainerQuery"

import Note from "./Note"
import { randomString } from "../util"
import { withTranslatedUtilOptions } from "../../HOC/withTranslatedUtilOptions"
import { GROUP_PREFIX } from "../../const"

const MemberContainer = ({
  threadId,
  withoutThreadId,
  onCloseMemberContainer,
  withCloseButton,
  i18nLocaleList,
  mobile,
  ...props
}) => {
  const { isSuperAdmin, isSuperAuditor } = useContext(UserContext)
  const { t } = useTranslation("common")
  const [
    data,
    refetch
  ] = useRefetchableFragment(
    graphql`
      fragment MemberContainer_userViewer on UserScope
      @argumentDefinitions(
        threadId: { type: "ID!" }
        withoutThreadId: { type: "Boolean!" }
      )
      @refetchable(queryName: "MemberContainerRefetchQuery") {
        configuration {
          member {
            tags
          }
        }
        thread (threadId: $threadId
        ) @skip(if: $withoutThreadId) {
          member {
            id
            wMemberId
            wChannelId
            wChannel {
              name
              integrationId
              integrationIcon
              integrationName
            }
            wAppId
            externalId
            externalProfile
            inboxProfile {
              firstName
              lastName
              website
              company
              profilePicUrl
              dob {
                date
                month
                year
              }
              gender
              locale
              phones {
                number
                alias
              }
              emails {
                email
                alias
              }
              addresses {
                alias
                line1
                line2
                city
                state
                country
                zip
              }
            }
            tags
            liveChat
            meta
            notes {
              _id
              content
              updatedAt
              authorId
              author {
                email {
                  email
                }
              }
              etag
            }
            etag
          }
        }
        role
        wUserId
      }
    `,
    props.data.userViewer
  )

  const isGroup = data?.thread?.member?.externalId?.startsWith(GROUP_PREFIX)

  const member = data?.thread?.member || {}
  const [loading, startTransition] = useTransition()
  const [commitAddTag, isInFlightAddTag] = useMutation(MemberContainerAddTagToMemberMutation)
  const [commitRemoveTag, isInFlighRemoveTag] = useMutation(MemberContainerRemoveTagFromMemberMutation)
  const [tagError, setTagError] = useState("")
  const [openMemberEditModal, setOpenMemberEditModal] = useState(false)
  const [memberNotes, setMemberNotes] = useState(member?.notes || [])
  const [copyInfoText, setCopyInfoText] = useState(t?.("tooltips.copy"))
  const memberTagsOption = (_.get(data, "configuration.member.tags") || []).map(tag => ({
    value: tag,
    label: tag
  }))
 
  const {
    tags = [],
    notes = [],
    inboxProfile = {},
    externalProfile = {},
    externalId = "",
    wMemberId,
    etag,
    wChannel = {},
    wChannelId
  } = member

  const firstName = inboxProfile?.firstName || ""
  const lastName = inboxProfile?.lastName || ""
  const name = inboxProfile?.name || ""
  const profilePicUrl = inboxProfile?.profilePicUrl || ""
  const locale = inboxProfile?.locale || ""
  const phones = inboxProfile?.phones || []
  const emails = inboxProfile?.emails || []
  // const dob = profile?.dob || {}
  // const gender = profile?.gender || ""

  useEffect(() => {
    setMemberNotes(notes || [])
  }, [JSON.stringify(notes)])

  const addTagFunction = (tag) => {
    let clientMutationId = 0
    let variables = {
      wMemberId: wMemberId,
      tag,
      clientMutationId: clientMutationId++,
    }
    const mutationParams = {
      variables: {
        input: variables,
      },
      onCompleted: (response, errs) => {
        if (!response.addTagToMember?.error) {
          
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        console.log("errorMsg", errorMsg)
        setTagError(errorMsg)
      }
    }
    commitAddTag(mutationParams)
  }

  const removeTagFunction = (tag) => {
    let clientMutationId = 0
    let variables = {
      wMemberId: wMemberId,
      tag,
      clientMutationId: clientMutationId++,
    }
    const mutationParams = {
      variables: {
        input: variables,
      },
      onCompleted: (response, errs) => {
        if (!response.removeTagFromMember?.error) {

        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        console.log("errorMsg", errorMsg)
        setTagError(errorMsg)
      }
    }
    commitRemoveTag(mutationParams)
  }

  useEffect(() => {
    if (threadId) {
      startTransition(() => {
        refetch({
          threadId: threadId,
          withoutThreadId: !threadId
        }, {
          fetchPolicy: "network-only",
        })
      })
    }
  }, [threadId])

 

  const language = i18nLocaleList.find(o => o.value === locale)
  const tagsList = (tags || []).map((o) => {
    return ({
      label: o,
      value: o
    })
  })

  const generalStyles = {
    menu: styles => ({
      ...styles,
      width: "100%",
      color: "#626262",
      zIndex: 999,
      fontSize: "0.9rem"
    }),
    option: (styles, state) => ({
      ...styles,
      whitespace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      ":hover": {
        ...styles[":hover"],
        overflow: "visible"
      }
    }),
    singleValue: styles => ({
      ...styles,
      color: "#626262",
      fontSize: "0.9rem"
    }),
    valueContainer: styles => ({
      ...styles,
      color: "#626262",
      fontSize: "0.9rem"
    })
  }

  if (loading) {
    return (
      <LoadingMask style={{ height: "100%", width: "100%", background: "#ffffff" }}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </LoadingMask>
    )
  }

  const renderName = () => {
    if (firstName && lastName) {
      return (<Styles.TextM memberName>{firstName}&nbsp;{lastName}</Styles.TextM>)
    } else if (firstName) {
      return (<Styles.TextM memberName>{firstName}</Styles.TextM>)
    } else if (lastName) {
      return (<Styles.TextM memberName>{lastName}</Styles.TextM>)
    } else if (name) {
      return (<Styles.TextM memberName>{name}</Styles.TextM>)
    } else if (externalProfile?.firstName) {
      return (<Styles.TextM memberName>{externalProfile?.firstName}</Styles.TextM>)
    } else if (externalProfile?.lastName) {
      return (<Styles.TextM memberName>{externalProfile?.lastName}</Styles.TextM>)
    } else if (externalProfile?.name) {
      return (<Styles.TextM memberName>{externalProfile?.name}</Styles.TextM>)
    } else if (externalProfile?.username) {
      return (<Styles.TextM memberName>{externalProfile?.username}</Styles.TextM>)
    } 
    return (
      <>
        <Styles.TextM>{t?.("chat_message.user")}</Styles.TextM>
        <Styles.TextS memberName>{externalId}</Styles.TextS>
      </>
    )
  }

  return (
    <Styles.InnerMemberContainer>
      <Styles.MemberInfo>
        {withCloseButton &&
          <Styles.CloseMemberInfoButton
            icon={faTimes}
            borderless
            onClick={() => {
              onCloseMemberContainer()
            }}
          />
        }
        <Styles.EditButton
          borderless
          disabled={isSuperAdmin || isSuperAuditor}
          icon={faPen}
          onClick={() => {
            setOpenMemberEditModal(true)
          }}
        />
        <IconContainer
          url={profilePicUrl}
          size="L"
          name={firstName || externalProfile?.name}
          showGroupPlaceholderIcon={isGroup}
        />
        {renderName()}
        <Styles.ChannelNameAndExternalIdWrapper>
          {!_.isEmpty(wChannel) && (
            <Styles.ChannelRow>
              <IntegrationIconDisplay
                icon={wChannel?.integrationIcon}
                name={wChannel?.integrationName}
                size="20px"
              />
              <div className="channel-name">{wChannel?.name}</div>
            </Styles.ChannelRow>
          )}
          {externalId && /^whatsapp-cloud/.test(wChannel?.integrationId) && (
            <Styles.ChannelRow>
              <FontAwesomeIcon
                style={{
                  marginRight: "4px",
                  marginTop: "4px"
                }}
                icon={isGroup ? faUserGroup : faPhone}
                size="20px"
              />
              <div style={{ wordBreak: "break-all" }} className="channel-name">{externalId}</div>
            </Styles.ChannelRow>
          )}
        </Styles.ChannelNameAndExternalIdWrapper>
        {language?.text && (
          <Styles.TextS className="member-locale">{language?.text}</Styles.TextS>
        )}
        {/* {(!_.isEmpty(dob) && dob?.year && dob?.month && dob?.date) && (
          <Flex>
            <Styles.TextS>Date of Birth | {`${dob.date}-${moment().month(dob.month).format("MMM")}-${dob.year}`}</Styles.TextS>
          </Flex>
          
        )}
        {gender && (
          <Styles.TextS>{gender.charAt(0).toUpperCase() + gender.slice(1)}</Styles.TextS>
        )} */}
        <div className="member-email">
          {(emails || []).map((email) => {
            return (
              <CopyToClipboard
                text={email?.email}
                onCopy={() => {
                  setCopyInfoText(t?.("tooltips.copied"))
                  setTimeout(() => {
                    setCopyInfoText(t?.("tooltips.copy"))
                  }, 1000)
                }}
              >
                <Styles.EmailRow
                  data-tooltip-content={copyInfoText}
                  data-tooltip-id="member-info-copy-tooltip"
                >
                  <FontAwesomeIcon
                    className="icon"
                    icon={faEnvelope}
                  />
                  <div className="email">{email?.email}</div>
                </Styles.EmailRow>
              </CopyToClipboard>
            )
          })}
        </div>
        {!/^whatsapp-cloud/.test(wChannel?.integrationId) && (
          <div className="member-phone">
            {(phones || []).map((phone) => {
              return (
                <CopyToClipboard
                  text={phone?.number}
                  onCopy={() => {
                    setCopyInfoText(t?.("tooltips.copied"))
                    setTimeout(() => {
                      setCopyInfoText(t?.("tooltips.copy"))
                    }, 1000)
                  }}
                >
                  <Styles.EmailRow
                    data-tooltip-content={copyInfoText}
                    data-tooltip-id="member-info-copy-tooltip"
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faPhone}
                    />
                    <div className="email">{phone?.number}</div>
                  </Styles.EmailRow>
                </CopyToClipboard>
              )
            })}
          </div>
        )}
      </Styles.MemberInfo>
      <ReactTooltip
        id="member-info-copy-tooltip"
        place="left"
      />
      <Styles.TagsContainer>
        <div className="title-container">
          <div className="title">{t?.("member_profile.tags")}</div>
          <div className="error">{tagError}</div>
        </div>
        <Styles.StyledCreatable
          options={memberTagsOption}
          placeholder={t?.("member_profile.create_tag_placeholder")}
          styles={generalStyles}
          isMulti
          isDisabled={isInFlightAddTag || isInFlighRemoveTag || isSuperAdmin || isSuperAuditor}
          isClearable={false}
          value={tagsList}
          onChange={(data) => {
            const oldTagsList = member?.tags ?? []
            let newTagsList
            if (!data?.length) {
              newTagsList = []
            } else {
              newTagsList = data.map(o => o.value)
            }
            const addedTag = _.difference(newTagsList, oldTagsList)
            const removedTag = _.difference(oldTagsList, newTagsList)
            if (addedTag?.length) {
              addTagFunction(addedTag[0])
            } else if (removedTag?.length) {
              removeTagFunction(removedTag[0])
            }
          }}
        />
      </Styles.TagsContainer>
      <Styles.NotesContainer>
        <Flex
          className="title-container"
          sx={{ alignItems: "center" }}
        >
          <div className="title">{t?.("notes.notes")}</div>
          {(!isSuperAdmin && !isSuperAuditor) &&
            <NewBoxButton
              icon={faPlus}
              text={t?.("notes.note")}
              primary
              borderless
              disabled={(memberNotes || []).find(n => !n._id)}
              onClick={() => {
                const newNotes = [
                  ...memberNotes,
                  {
                    noteId: randomString(8),
                    content: ""
                  }
                ]
                setMemberNotes(newNotes)
              }}
            />
          }
        </Flex>
        {(memberNotes || []).map((note) => {
          return (
            <Note
              key={note?.noteId || note?._id}
              noteInfo={note}
              wMemberId={wMemberId}
              ownUserId={data?.wUserId}
              onCancelUnsavedNote={(noteId) => {
                const newMemberNotes = memberNotes.filter(o => o.noteId !== noteId)
                setMemberNotes(newMemberNotes)
              }}
            />
          )
        })}
      </Styles.NotesContainer>
      <Modal
        open={openMemberEditModal}
      >
        {({ modalRef }) => (
          <MemberEditModal
            ref={modalRef}
            inboxProfile={inboxProfile}
            profile={externalProfile}
            wChannel={wChannel}
            etag={etag}
            wMemberId={wMemberId}
            externalId={externalId}
            wChannelId={wChannelId}
            mobile={mobile}
            onClose={() => {
              setOpenMemberEditModal(false)
            }}
          />
        )}
      </Modal>
    </Styles.InnerMemberContainer>
  )
}

export default withTranslatedUtilOptions(MemberContainer)
