/** @jsxImportSource theme-ui */

import React, { useState, useEffect, useRef, useMemo } from "react"
import _ from "lodash"
import moment from "moment"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons"
import Slider from "react-slick"
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel"

import { timestampNormalizer } from "../util"
import Audio from "../Audio/Audio"
import ButtonTemplate from "../ButtonTemplate/ButtonTemplate"
import File from "../File/File"
import Carousel from "../Carousel/Carousel"
import Image from "../Image/Image"
import Animation from "../Animation/Animation"
import List from "../List/List"
import Media from "../Media/Media"
import Payload from "../Payload/Payload"
import Text from "../Text/Text"
import Video from "../Video/Video"
import Misc from "../Misc/Misc"
import Raw from "../Raw/Raw"
import MediaDownloadWrapper from "../MediaDownload/MediaDownload"
import IconContainer from "../../IconContainer/IconContainer"
import ExternalLinkOutWrapper from "../../ExternalLinkOutWrapper/ExternalLinkOutWrapper"
import PopperContainer from "../../Radiate/PopperContainer/PopperContainer"
import PopupMenuCollapseButton from "../PopupMenu/CollapseButton"
import { detectEmailAndUrl } from "../../ChatVisualizer/util"
import { Buffer } from "buffer"
import { MOBILE_BREAKPOINT, MOBILE_MESSAGE_MAX_WIDTH, WHATSAPP_CLOUD_MSG_ON_HOVER_COLOR } from "../../../const"
import { resourceTemplateTheme } from "../../../stylesheets/theme"
import {
  ReactLive,
} from "../../ReactLive"
import { ReplyPreview as ReplyPreviewWrapper } from "../ReplyPreview/ReplyPreview"

const unreadColor = "#0063f00f"
const unreadHoverColor = "#0063f005"

const Row = styled.div`
  transition: background 0.1s linear;
  background: ${(props) => props.unread ? unreadColor : "transparent"};

  .popup-menu {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    background: ${(props) => {
    if (props.isWhatsappCloudThread && !props.unread) {
      return WHATSAPP_CLOUD_MSG_ON_HOVER_COLOR
    }
    return props.unread ? unreadHoverColor : "rgba(255, 255, 255, 0.5)"
}};

    .popup-menu {
      opacity: 1;
      pointer-events: all;
    }
  }
  
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .content-info > .content {
      max-width: ${MOBILE_MESSAGE_MAX_WIDTH};
    }
  }
`

function renderMessage(message, options, color) {
  switch (message.type) {
    case "BUTTON":
      return (<ButtonTemplate data={message.data} color={color} />)
    case "FILE":
      return (<File data={message.data} color={color} />)
    case "VIDEO":
      return (<Video data={message.data} color={color} />)
    case "AUDIO":
    case "TEAMWORK_VOICE":
      return (<Audio data={message.data} color={color} />)
    case "IMAGE":
      return (<Image data={message.data} color={color} />)
    case "ANIMATION":
      return (<Animation data={message.data} color={color} />)
    case "ATTACHMENT":
    case "TEXT":
    case "QUICK_REPLIES":
      return (<Text data={message.data} color={color} />)
    case "LIST":
      return (<List data={message.data} color={color} />)
    case "CAROUSEL":
      return (<Carousel data={message.data} itemWidth={options.carouselItemWidth} color={color} />)
    case "MEDIA":
      return (<Media data={message.data} color={color} />)
    case "PAYLOAD":
      return (<Payload data={message.data} color={color} />)
    case "MISC":
      return (<Misc data={message.data} color={color} />)  
    default:
      return (<Raw data={message} />)
  }
}

const User = ({ 
  message,
  profilePic,
  noProfilePic,
  noTimeDisplay,
  themeColor,
  firstName,
  resourceTemplate = null,
  readAt,
  deliveredAt,
  failedAt,
  error,
  app,
  appIntegration,
  channel,
  appIntegrationSignature,
  isConversation = false,
  messageMenu = null,
  messageMenuStyle,
  messageMenuPlacement,
  unread,
  isWhatsappCloudThread,
  chatId,
}) => {
  const [contentWidth, setContentWidth] = useState(300)
  const [rowAlignItems, setRowAlignItems] = useState("flex-start")
  const [openMenu, setOpenMenu] = useState(false)

  const content = useRef(null)
  const row = useRef(null)

  useEffect(() => {
    if (content?.current) {
      const width = content?.current?.offsetWidth
      if (width < contentWidth) {
        setContentWidth(width)
      }
    }
    setTimeout(() => {
      if (row?.current) {
        if (row?.current?.offsetHeight <= 68) {
          setRowAlignItems("center")
        }
      }
    }, 500)
  }, [content])

    const rowStyle = {
      alignItems: rowAlignItems
    }

    const payload = {
      app,
      appIntegration,
      channel,
    }
    let encodedPayload = null
    if (Buffer) {
      encodedPayload = Buffer.from(JSON.stringify(payload)).toString("base64")
    }
    const signedContext = `${appIntegrationSignature}.${encodedPayload}`

    const MediaDownload = useMemo(() => {
      return MediaDownloadWrapper({
        isConversation,
        from: "MEMBER",
        isWhatsappCloudThread,
      })
    }, [
      isConversation,
      isWhatsappCloudThread,
    ])

  const ReplyPreview = useMemo(() => {
      return ReplyPreviewWrapper({
        fileId: message?.replyToMessageEvent?.file?.fileId,
        chatId,
        isConversation,
        from: "MEMBER",
        replyToType: message?.replyToMessageEvent?.type,
        replyToFrom: message?.replyToMessageEvent?.from,
        replyWithType: message?.type,
        payload,
        signedContext,
      })
    }, [
      message?.replyToMessageEvent?.file?.fileId,
      chatId,
      isConversation,
      message?.replyToMessageEvent?.type,
      message?.replyToMessageEvent?.from,
      app,
      appIntegration,
      channel,
      signedContext,
    ])

    const scope = useMemo(() => {
      return {
        styled,
        Slider,
        FontAwesomeIcon,
        ResponsiveCarousel,
        detectEmailAndUrl,
        serverAPIPath: `${process.env.REACT_APP_WOZTELL_URL}/api`,
        ReplyPreview,
        data: {
          ...message?.data,
          isReply: message?.isReply,
          replyToPreview: message?.replyToPreview,
          replyToMessageEvent: message?.replyToMessageEvent,
          isConversation,
          from: "MEMBER",
          isInbox: true,
          ExternalLinkOutWrapper,
        },
        theme: {
          ...resourceTemplateTheme.bot,
          user: {
            ...resourceTemplateTheme.user,
          },
          bot: {
            ...resourceTemplateTheme.bot,
          }
        },
        MediaDownload,
        // do not use "for" as a key for scope since it is a reserved word
        forMember: true,
        payload,
        signedContext,
      }
    }, [
      MediaDownload,
      ReplyPreview,
      JSON.stringify(message?.data),
      message?.isReply,
      message?.replyToPreview,
      JSON.stringify(message?.replyToMessageEvent),
      isConversation,
      app,
      appIntegration,
      channel,
      signedContext,
    ])

    // console.log("INBOX FRONT-END USER SCOPE: ", scope)

    const timeInfo = {
      readAt,
      deliveredAt,
      failedAt
    }
    
    return (
      <Row
        ref={row}
        style={rowStyle}
        className="row user"
        onMouseLeave={() => {
          setOpenMenu(false)
        }}
        unread={unread}
        isWhatsappCloudThread={isWhatsappCloudThread}
      >
        {noProfilePic ?
          null
          :
          <div style={{ marginRight: "8px" }}>
            <IconContainer
              url={profilePic}
              size="S"
              name={firstName}
            />
          </div>
        }
        <div
          className="content-info"
          sx={{
            position: "relative"
          }}
        >
          <div
            className="content"
            ref={content}
          >
            <MemoizedUserBubbleContent
              resourceTemplate={resourceTemplate}
              isWhatsappCloudThread={isWhatsappCloudThread}
              scope={scope}
              themeColor={themeColor}
              message={message}
              contentWidth={contentWidth}
            />
          </div>
          <div>
            {error &&
              <>
                <FontAwesomeIcon
                  className="error-icon"
                  icon={faExclamationTriangle}
                  data-tooltip-content={error}
                  data-tooltip-id="chat-visualizer-tooltip"
                  data-tooltip-place="right"
                />
              </>
            }
            {noTimeDisplay ?
              null
              :
              <div
                className="time-display"
                data-tooltip-content={(failedAt || readAt || deliveredAt) ? JSON.stringify(timeInfo) : null}
                data-tooltip-id="chat-visualizer-tooltip"
                data-tt-type="time-display"
                data-tooltip-place="right"
              >
                {timestampNormalizer(message.timestamp) ? moment(timestampNormalizer(message.timestamp)).format("HH:mm") : null}
              </div>
            }
            {messageMenu && (
              <PopperContainer
                className="popup-menu"
                display={(
                  <PopupMenuCollapseButton
                    onClick={() => {
                      setOpenMenu(true)
                    }}
                  />
                )}
                sx={messageMenuStyle}
                placement={messageMenuPlacement}
                show={openMenu}
                onClickOutside={() => {
                  setOpenMenu(false)
                }}
                addDefaultWrapper={false}
              >
                {() => messageMenu}
              </PopperContainer>
            )}
          </div>
        </div>
      </Row>
    )
}

const UserBubbleContentWrapper = ({
  resourceTemplate,
  isWhatsappCloudThread,
  scope,
  themeColor,
  message,
  contentWidth,
}) => {
  return (
    <>
      {resourceTemplate?.preview ?
        <ReactLive
          useV2ReactLive={!isWhatsappCloudThread}
          resourceTemplate={resourceTemplate}
          scope={scope}
        />
        :
        <>
          {renderMessage(message, { carouselItemWidth: contentWidth }, themeColor)}
        </>
      }
    </>
  )
}

const MemoizedUserBubbleContent = React.memo(UserBubbleContentWrapper, (prevProps, props) => {
  if (!_.isEqual(prevProps.resourceTemplate, props.resourceTemplate)) {
    return false
  }
  if (!_.isEqual(prevProps.isWhatsappCloudThread, props.isWhatsappCloudThread)) {
    return false
  }

  if (!_.isEqual(prevProps.themeColor, props.themeColor)) {
    return false
  }

  if (!_.isEqual(prevProps.message, props.message)) {
    return false
  }

  if (!_.isEqual(prevProps.contentWidth, props.contentWidth)) {
    return false
  }

  // scope is memoized, so just compare reference
  if (prevProps.scope !== props.scope) {
    return false
  }

  return true
})

export default React.memo(User, (prevProps, props) => {
  if (prevProps.readAt !== props.readAt) {
    return false
  }
  if (prevProps.deliveredAt !== props.deliveredAt) {
    return false
  }
  if (prevProps.failedAt !== props.failedAt) {
    return false
  }
  if (prevProps.error !== props.error) {
    return false
  }
  if (prevProps.chatId !== props.chatId) {
    return false
  }
  if (prevProps.unread !== props.unread) {
    return false
  }
  if (!_.isEqual(prevProps.messageMenu, props.messageMenu)) {
    return false
  }
  if (!_.isEqual(prevProps.message, props.message)) {
    return false
  }
  if (!_.isEqual(prevProps.resourceTemplate, props.resourceTemplate)) {
    return false
  }
  if (prevProps.profilePic !== props.profilePic) {
    return false
  }
  if (prevProps.noProfilePic !== props.noProfilePic) {
    return false
  }
  if (prevProps.noTimeDisplay !== props.noTimeDisplay) {
    return false
  }
  if (prevProps.themeColor !== props.themeColor) {
    return false
  }
  if (prevProps.firstName !== props.firstName) {
    return false
  }
  return true
})
