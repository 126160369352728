import React, { useState } from "react"
import styled from "styled-components"
import {
  LiveProvider as LiveProviderV4,
  LivePreview as LivePreviewV4,
  LiveError as LiveErrorV4,
  withLive as withLiveV4,
} from "react-live-v4"

import {
  LiveProvider as LiveProviderV2,
  LivePreview as LivePreviewV2,
  LiveError as LiveErrorV2,
  withLive as withLiveV2,
} from "react-live"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"

const ErrorWrapper = styled.div`
  min-height: 300px;
  margin: 8px 0;
  width: 212px;
  background-color: #fee2e2;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  vertical-align: top;
  white-space: pre-wrap;
  word-break: break-word;
  border-radius: 6px;
  box-sizing: border-box;
  color: ${props => props.theme.gray9};
  font-size: 0.8rem;
  text-align: center;
  padding: 16px;
  pre {
    display: ${props => (props.showError ? "block" : "none")};
    overflow: auto;
    width: 212px;
  }
`

const ErrorText = styled.div`
  font-size: ${props => props.theme.textSm};
  color: ${props => props.theme.gray05};
`
function ReactLiveErrorV4({ live, resourceTemplate }) {
  const [showError, setShowError] = useState(false)
  if (live?.error) {
    console.log("live error: ", live.error)
  }
  if (!live?.error) return null
  return (
    <ErrorWrapper showError={showError}>
      <FontAwesomeIcon color="#f87171" icon="triangle-exclamation" size="5x" />
      <div style={{ textAlign: "center" }}>
        Sorry, something went wrong with dislaying the message.
        Reload the inbox, to try it again.
        If the problem persists, please send an email to
        {" "}
        <a href="mailto:support@woztell.com">support@woztell.com</a>
        {" "}
        with the error details and we&apos;ll fix it.
      </div>
      <NewBoxButton
        text={showError ? "Hide Error Details" : "Show Error Details"}
        onClick={() => setShowError(p => !p)}
      />
      <ErrorText>
        Error occured in the {resourceTemplate?.alias ?? "No Alias"} resource template.
      </ErrorText>
      <LiveErrorV4 />
    </ErrorWrapper>
  )
}

const WrappedReactLiveErrorV4 = withLiveV4(ReactLiveErrorV4)

function ReactLiveErrorV2({ live, resourceTemplate }) {
  const [showError, setShowError] = useState(false)
  if (live?.error) {
    console.log("live error: ", live.error)
  }
  if (!live?.error) return null
  return (
    <ErrorWrapper showError={showError}>
      <FontAwesomeIcon color="#f87171" icon="triangle-exclamation" size="5x" />
      <div style={{ textAlign: "center" }}>
        Sorry, something went wrong with dislaying the message.
        Reload the inbox, to try it again.
        If the problem persists, please send an email to
        {" "}
        <a href="mailto:support@woztell.com">support@woztell.com</a>
        {" "}
        with the error details and we&apos;ll fix it.
      </div>
      <NewBoxButton
        text={showError ? "Hide Error Details" : "Show Error Details"}
        onClick={() => setShowError(p => !p)}
      />
      <ErrorText>
        Error occured in the {resourceTemplate?.alias ?? "No Alias"} resource template.
      </ErrorText>
      <LiveErrorV2 />
    </ErrorWrapper>
  )
}

const WrappedReactLiveErrorV2 = withLiveV2(ReactLiveErrorV2)

function ReactLive({
  resourceTemplate,
  scope,
  useV2ReactLive = true,
}) {
  if (!resourceTemplate || !scope) {
    console.error("resource template or scope not passed to react live")
    console.log({ resourceTemplate, scope })
    return null
  }
  if (useV2ReactLive) {
    return (
      <LiveProviderV2 code={resourceTemplate?.preview} scope={scope} noInline={true}>
        <LivePreviewV2 className={`live-provider-${resourceTemplate?.alias}`} />
        <WrappedReactLiveErrorV2 resourceTemplate={resourceTemplate} />
      </LiveProviderV2>
    )
  }
  return (
    <LiveProviderV4 code={resourceTemplate?.preview} scope={scope} noInline={true} enableTypeScript={false}>
      <LivePreviewV4 className={`live-provider-${resourceTemplate?.alias}`} />
      <WrappedReactLiveErrorV4 resourceTemplate={resourceTemplate} />
    </LiveProviderV4>
  )
}

export {
  ReactLive,
}
