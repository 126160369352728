/**
 * @generated SignedSource<<7c77d1cf6e5904c0d5422f105131dbe8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleMemberLiveChatPayload",
    "kind": "LinkedField",
    "name": "toggleMemberLiveChat",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Member",
        "kind": "LinkedField",
        "name": "member",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalProfile",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MemberProfile",
            "kind": "LinkedField",
            "name": "inboxProfile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profilePicUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "liveChat",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConversationHistoryQuery_toggleMemberLiveChatMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConversationHistoryQuery_toggleMemberLiveChatMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8ed94de3588a0cc17dbf656ba18951ba",
    "id": null,
    "metadata": {},
    "name": "ConversationHistoryQuery_toggleMemberLiveChatMutation",
    "operationKind": "mutation",
    "text": "mutation ConversationHistoryQuery_toggleMemberLiveChatMutation(\n  $input: ToggleMemberLiveChatInput!\n) {\n  toggleMemberLiveChat(input: $input) {\n    clientMutationId\n    error {\n      message\n      code\n    }\n    member {\n      id\n      meta\n      externalId\n      externalProfile\n      inboxProfile {\n        firstName\n        lastName\n        profilePicUrl\n      }\n      liveChat\n    }\n  }\n}\n"
  }
};
})();

node.hash = "c716d67385d6a0601cf48777f7e48912";

module.exports = node;
