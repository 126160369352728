/* eslint-disable import/no-anonymous-default-export */
const textScalerRatio = 1.2

export const resourceTemplateTheme = {
  bot: {
    whatsapp: {
      colors: {
        textForegroundDarkest: "#2e2e2e",
        textForegroundExtraDark: "#3c3c3c",
        textForegroundDark: "#626262",
        textForeground: "#707070",
        textForegroundLight: "#929292",
        textForegroundLightest: "#cccccc",

        textBackgroundWhite: "#f0fdf4",
        textBackgroundLight: "#dcfce7",
        textBackground: "#60ec91",
        textBackgroundDark: "#73ec9f",

        borderColor: "#bbf7d0",

        foregroundPrimary: "#365314",
        notesBorderColor: "#009400",
        notesBackgroundColor: "#e6f6e6",
        templateSectionBackground: "#075E54",
        referralBackground: "#3366ca",

        boxShadowColor: "#bbf7d0",
      },
      replyToColors: {
        textForeground: "#525252",
        textBackground: "#f0fdf4",
        accent: "#4d7c0f",
      }
    }
  },
  user: {
    whatsapp: {
      colors: {
        textForegroundDarkest: "#2e2e2e",
        textForegroundExtraDark: "#3c3c3c",
        textForegroundDark: "#626262",
        textForeground: "#707070",
        textForegroundLight: "#929292",
        textForegroundLightest: "#cccccc",

        textBackgroundWhite: "white",
        textBackgroundLight: "#f1f5f9",
        textBackground: "#e2e8f0",
        textBackgroundDark: "#cbd5e1",

        borderColor: "#ededed",

        foregroundPrimary: "#3046ac",
        notesBorderColor: "#009400",
        notesBackgroundColor: "#e6f6e6",
        templateSectionBackground: "#075E54",
        referralBackground: "#3366ca",

        boxShadowColor: "#ededed",
      },
      replyToColors: {
        textForeground: "#737373",
        textBackground: "#f9fafb",
        accent: "#818cf8",
      }
    }
  },
}

export default {
  text: "#2e2e2e",
  background: "#fff",
  primary: "hsl(215, 61%, 41%)",
  white: "#fff",
  black: "#2e2e2e",
  secondary: "#c0c",
  success: "hsl(94, 48%, 56%)",
  warning: "hsl(46, 100%, 61%)",
  error: "hsl(8, 74%, 49%)",
  dangerText: "#cc0000",
  facebookThemeColor: "linear-gradient(45deg, #0668E1, #0080FB )",
  themeColorDark: "#173753",
  themeColorDarkV2: "#3046ac",
  themeText: "#3366ca",
  themeColor: "#0063f0",
  palerBackground: "#f8f8f8",
  lightButtonIcon: "#b9b9b9",
  lightButtonBg: "#f8f8f8",
  highlight: "#e0e",
  muted: "#f6f6ff",
  paleBackground: "#f0f2f7",
  superPalerBackground: "#f3f3f3",
  themeColorLight: "#1D70A2",
  themeColorLightFocus: "#ecf3ff",
  selectorFocus: "#9d9d9d",
  borderColorDark: "#dbdbdb",
  borderColor: "#ededed",
  borderColorLight: "#dcdcdc",
  modalBoxShadow: "0 7px 8px -4px rgba(0,0,0,0.2), 0 13px 19px 2px rgba(0,0,0,0.14), 0 5px 24px 4px rgba(0,0,0,0.12)",
  cardBoxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.05)",
  modalBorderRadius: "4px",
  messageBubbleBoxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.11)",
  themeColorHighlight: "#66acff",
  themeColorGreen: "#73877B",
  themeColorBlue: "#405DE6",
  commentColor: "#f5f4a1",
  placeholderText: "#bbbbbc",
  gray12: "#1d1d1d",
  gray11: "#272727",
  gray10: "#2e2e2e",
  gray9: "#3c3c3c",
  gray7: "#626262",
  gray6: "#707070",
  gray5: "#929292",
  gray4: "#a5a5a6",
  gray3: "#bbbbbc",
  gray2: "#cccccc",
  gray15: "#e6e6e6",
  gray1: "#e8e7e8",
  gray05: "#f0f0f0",
  gray02: "#f7f7f7",
  gray0: "#fafafa",
  colors: {
    gray12: "#1d1d1d",
    gray11: "#272727",
    gray10: "#2e2e2e",
    gray9: "#3c3c3c",
    gray7: "#626262",
    gray6: "#707070",
    gray5: "#929292",
    gray4: "#a5a5a6",
    gray3: "#bbbbbc",
    gray2: "#cccccc",
    gray15: "#e6e6e6",
    gray1: "#e8e7e8",
    gray05: "#f0f0f0",
    gray02: "#f7f7f7",
    gray0: "#fafafa",
    themeColor: "#0063f0",
    danger: "#cc0000",
  },
  selectorBorder: "hsl(0,0%,80%)",
  mainFont: "Open Sans, Arail, sans-serif",
  textXxxxs: `${1 / (textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  textXxxs: `${1 / (textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  textXxs: `${1 / (textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  textXs: `${1 / (textScalerRatio * textScalerRatio)}em`,
  textSm: `${1 / textScalerRatio}em`,
  textMd: `${1 * textScalerRatio}em`,
  textLg: `${1 * (textScalerRatio * textScalerRatio)}em`,
  textXl: `${1 * (textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  textXxl: `${1 * (textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  textXxxl: `${1 * (textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  textXxxxl: `${1 * (textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  breakpoints: [
    "989px",
    "1400px",
    "1500px",
  ],
  ...resourceTemplateTheme.bot,
  user: {
    ...resourceTemplateTheme.user,
  },
  bot: {
    ...resourceTemplateTheme.bot,
  }
}
