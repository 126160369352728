import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { Image } from "theme-ui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleUser, faUserGroup,
} from "@fortawesome/free-solid-svg-icons"

const ImageContainer = styled.div`
  width: ${(props) => {
    if (props.size === "XS") {
      return "24px"
    }
    if (props.size === "S") {
      return "40px"
    }
    if (props.size === "SM") {
      return "50px"
    }
    if (props.size === "M") {
      return "60px"
    }
    if (props.size === "L") {
      return "80px"
    }
    if (props.size === "XL") {
      return "100px"
    }
    return "40px"
  }};
  height: ${(props) => {
    if (props.size === "XS") {
      return "24px"
    }
    if (props.size === "S") {
      return "40px"
    }
    if (props.size === "SM") {
      return "50px"
    }
    if (props.size === "M") {
      return "60px"
    }
    if (props.size === "L") {
      return "80px"
    }
    if (props.size === "XL") {
      return "100px"
    }
    return "40px"
  }};
  min-width: ${(props) => {
    if (props.size === "XS") {
      return "24px"
    }
    if (props.size === "S") {
      return "40px"
    }
    if (props.size === "SM") {
      return "50px"
    }
    if (props.size === "M") {
      return "60px"
    }
    if (props.size === "L") {
      return "80px"
    }
    if (props.size === "XL") {
      return "100px"
    }
    return "40px"
  }};
  min-height: ${(props) => {
    if (props.size === "XS") {
      return "24px"
    }
    if (props.size === "S") {
      return "40px"
    }
    if (props.size === "SM") {
      return "50px"
    }
    if (props.size === "M") {
      return "60px"
    }
    if (props.size === "L") {
      return "80px"
    }
    if (props.size === "XL") {
      return "100px"
    }
    return "40px"
  }};
  border-radius: ${(props) => {
    if (props.size === "XS") {
      return "12px"
    }
    if (props.size === "S") {
      return "20px"
    }
    if (props.size === "SM") {
      return "25px"
    }
    if (props.size === "M") {
      return "30px"
    }
    if (props.size === "L") {
      return "40px"
    }
    if (props.size === "XL") {
      return "50px"
    }
    return "20px"
  }};
  box-sizing: border-box;
  border: ${(props) => {
  if (props.withUrl && props.withBorder) {
    return `2px solid ${props.theme.lightButtonIcon}`
  } else if (!props.withBorder) {
    return
  } else if (props.color) {
    return `2px solid ${props.color}`
  } else if (props.withUrl) {
    return "2px solid #ffffff"
  }
  return `2px solid ${props.theme.lightButtonIcon}`
  }};

  .image {
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: ${(props) => {
      if (props.size === "XS") {
        return "12px"
      }
      if (props.size === "S") {
        return "20px"
      }
      if (props.size === "SM") {
        return "25px"
      }
      if (props.size === "M") {
        return "30px"
      }
      if (props.size === "L") {
        return "40px"
      }
      if (props.size === "XL") {
        return "50px"
      }
      return "20px"
    }};
  }
 `

const MockImage = styled.div`
  margin-right: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => {
    if (props.size === "XS") {
      return "12px"
    }
    if (props.size === "S") {
      return "20px"
    }
    if (props.size === "SM") {
      return "25px"
    }
    if (props.size === "M") {
      return "30px"
    }
    if (props.size === "L") {
      return "40px"
    }
    if (props.size === "XL") {
      return "50px"
    }
    return "20px"
  }};
  color: #ffffff;
  font-size: ${(props) => {
    if (props.size === "XS") {
      return "0.65rem"
    }
    if (props.size === "S") {
      return props.theme.textSm
    }
    if (props.size === "SM") {
      return props.theme.textMd
    }
    if (props.size === "M") {
      return props.theme.textMd
    }
    if (props.size === "L") {
      return props.theme.textLg
    }
    if (props.size === "XL") {
      return props.theme.textXl
    }
    return props.theme.textSm
  }};
  .icon {
    width: 100%;
    height: 100%;
    color: ${(props) => props?.color || "#b9b9b9"};
  }
`

const IconContainer = ({
  className,
  url,
  icon,
  size,
  name,
  color,
  withBorder,
  showGroupPlaceholderIcon = false,
}) => {

  const [loadError, setLoadError] = useState(false)
  useEffect(() => {
    setLoadError(false)
  }, [url, setLoadError])

  const renderIcon = () => {
    if (url && !loadError) {
      return (
        <Image
          className="image"
          style={{
            maxHeight: "100%",
          }}
          src={url || ""}
          onError={() => {
            setLoadError(true)
          }}
          onLoad={() => {
            setLoadError(false)
          }}
        />
      )
    }
    return (
      <MockImage className="mock-image" size={size} color={color}>
        <FontAwesomeIcon
          className="icon"
          icon={icon || (showGroupPlaceholderIcon ? faUserGroup : faCircleUser)}
          fixedWidth
        />
      </MockImage>
    )
  }

  return (
    <ImageContainer size={size} className={className} color={color} withUrl={!!url} withBorder={withBorder}>
      {renderIcon()}
    </ImageContainer>
  )
}

export default IconContainer
