import styled from "@emotion/styled"
import css from "@emotion/css"
import Color from "color"
import { setLightness } from "polished"

import TextareaAutosize from "react-autosize-textarea"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"

const LUM_THRESHOLD = 0.6

export const LoadMoreConversation = styled.div`
  width: 100%;
  text-align: center;
  padding: 16px 0;
  color: ${(props) => props.isWhatsappCloudThread ? "#666" : "#999"};
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;

  .icon {
    margin-right: 8px;
  }

  span {
    font-size: ${(props) => props.theme.textSm};
  }
`

export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

export const ConversationContainer = styled.div`
  width: calc(100% - 360px);
  height: 100%;
  font-family: sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${(props) => {
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return "#555"
      }
      return "#ffffff"
    }
    return "#ffffff"
  }};

  @media only screen and (max-width: 1400px) {
    width: 100%;
  }

  >.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    ${'' /* font-size: 0.875rem; */}
    background: ${(props) => props.theme.palerBackground};
  }
`
export const DropdownMenuItem = styled(NewBoxButton)`
  font-size: 1em;
  height: 44px;
  width: 100%;
  justify-content: flex-start;

  .text {
    ${(props) => {
      if (props.color) {
        return css`
          color: ${props.color};

          &:hover, &:active , &:focus{
            color: ${props.color} !important;
          }
        `
      }
    }}
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    ${(props) => {
      if (props.color) {
        return css`
          color: ${props.color};

          &:hover, &:active, &:focus {
            color: ${props.color} !important;
          }
        `
      }
    }}
  }
`

export const MemberName = styled.div`
  color: ${(props) => props.theme.gray7};
  max-width: ${(props) => props.mobile ? "220px" : "auto"};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
`

export const Header = styled.div`
  width: 100%;
  box-sizing: border-box;
  min-height: 88px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;

  .channel-name-wrapper {
    font-size: ${(props) => props.theme.textSm};
    color: ${(props) => props.theme.gray7};
    display: flex;
    align-items: center;

    >.channel-name {
      padding-left: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`

export const ProfilePic = styled.div`
  width: ${(props) => props.size ? props.size : "40px"};
  height: ${(props) => props.size ? props.size : "40px"};
  border-radius: ${(props) => props.size ? `calc( ${props.size} / 2)` : "20px"};
  border: 2px solid ${(props) => props.theme.gray1};
  box-sizing: border-box;
  background: white center center no-repeat url("${props => props.url}");
  background-size: cover;
  margin: 0 8px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
  }
`

export const Settings = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 144px;
`

export const Body = styled.div`
  flex-grow: 1;
  position: relative;
  overflow-y: scroll;
  width: 100%;
  box-sizing: border-box;
  color: #000;

  &.whatsapp-background-image {
    background-image: url(https://whatsapp-cloud.woztell.sanuker.com/v3/img/whatsapp-chat-background.png);
  }
`

export const Footer = styled.div`
  flex-grow: 0;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  padding: 16px;
  padding-bottom: ${(props) => props.ios ? "32px" : "16px"};

    >.action-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 8px;

      .left-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .right-wrapper {
        display: flex;
        align-items: center;
      }
    }
`

export const ActionRow = styled.div`
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  border: 1px solid ${props => props.theme.gray1};
  border-radius: 6px;
  background: #ffffff;
  position: relative;
  ${'' /* overflow-x: auto;
  overflow-y: auto; */}

  border-color: ${props => props.highlight ? "#445be3" : props.theme.gray1};
  transition: border-color 0.2s linear;

  >.overlay {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    background: rgba(222, 222, 222, 0.55);
  }

  ${(props) => {
    if (props.$isAudioEnabled) {
      return css`
        border: none;
        border-radius: 45px;
        min-height: 60px;
        max-width: 100%;
        margin: 0 auto;
        overflow-x: visible;

        @media only screen and (min-width: 500px) {
          max-width: 400px;
        }
      `
    }
  }}
`

export const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  ${'' /* position: relative; */}
`

export const TextField = styled(TextareaAutosize)`
  padding: 8px;
  flex-grow: 1;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
  resize: none;
  line-height: 1.33;
  font-family: sans-serif;
  -webkit-tap-highlight-color: transparent;
  background: #ffffff
  border-radius: 6px;

  :disabled {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

export const ButtonWrapper = styled.div`

  >label {
    cursor: ${(props) => props.disabled ? "not-allowed" : "pointer"};
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: 6px;
    color: ${(props) => {
    if (props.disabled) {
      return "#ccc"
    }
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return props.theme.gray5
      }
      return props.color
    }
    return props.theme.gray5
  }};
    width: 34px;
    height: 34px;
    &:hover {
      background: ${(props) => {
    if (props.disabled) {
      return
    }
    return props.theme.gray1;
  }};
      color: ${(props) => {
    if (props.disabled) {
      return
    }
    return "#0084FF"
  }};
    }
  }

  ${(props) => {
    if (props.$isAudioEnabled) {
      return css`
        >label {
          background: ${props.theme.gray1};
          color: #0084FF;
        }
      `
    }
  }}
  .icon {
    width: 16px;
    height: 16px;
  }
`

export const TypingContainer = styled.div`
  text-align: center;
  padding: 10px 10px;
  border-radius: 8px;
  background: #F1F0F0;

  .dot {
    display:inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #999999;
    animation: anim 1.5s infinite;
    margin-right: 2px;

    &:nth-of-type(2) {
      animation-delay: 0.1s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.2s;
    }
  }

  @keyframes anim {
    0% {
      transform: scale(1);
      opacity:1;
    }
    50% {
      transform: scale(0.1);
      opacity: 0.5;
    }

    100% {
      transform: scale(1);
      opacity:1;
    }
  }
`

export const TypingRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;

  .content-info {
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    max-width: calc(80% - 48px);

    @media only screen and (min-width: 801px) {
      max-width: 360px;
    }

    >.content {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
`

export const InfoContent = styled.div`
  padding: 16px;
  height: calc( 100% - 40px);
  overflow: scroll;

`

export const InfoCard = styled.div`
  padding: 16px;
  margin-top: 16px;
  border-radius: 8px;
  display: flex;
  background: ${(props) => {
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return "#555"
      }
      return "rgb(255 255 255 / 20%)"
    }
    return "rgb(255 255 255 / 20%)"
  }};
`

export const InfoWrapper = styled.div`
  font-size: 0.8rem;
  padding-bottom: 4px;

  &:last-child {
    padding-bottom: 0px;
  }
  >.separator {
    width: 100%;
    height: 1px;
    margin-top: 4px;
    margin-bottom: 4px;
    background: ${(props) => {
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return "#555"
      }
      return "rgb(255 255 255 / 20%)"
    }
    return "rgb(255 255 255 / 20%)"
  }};
  }

  &:last-child {
   .separator {
     display: none;
   }
  }
`

export const Info = styled.div`
  font-size: 0.8rem;
  padding-bottom: 4px;
  font-weight: ${(props) => props.bold && "bold"};
  word-break: break-word;

  &:last-child {
    padding-bottom: 0px;
  }

  >.separator {
    width: 100%;
    height: 1px;
    margin-top: 4px;
    background: ${(props) => {
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return "#555"
      }
      return "rgb(255 255 255 / 20%)"
    }
    return "rgb(255 255 255 / 20%)"
  }};
  }

  &:last-child {
   .separator {
      display: none;
    }
  }
`

export const Text = styled.span`
  word-break: break-word;
  flex-shrink: 1;
  flex-wrap: wrap;
`

export const Chat = styled.div`
 height: 100%;
 width: ${(props) => props.mobile ? "100vw" : "100%"};

 ${(props) => {
    if (props.mobile) {
      return css`
        position: fixed;
        top: 0;
        left: 0;
        transition: opacity 0.3s ease, transform 0.3s ease-in-out;
        transform: translateX(100%);
      `
    } else {
      return css`
        display: flex;
        position: relative;
      `
    }
 }}

 ${(props) => {
    if (props.mobile && props.show) {
      return css`
        transform: translateX(0);
      `
    }
 }}
`

export const ErrorMessage = styled.div`
  background: #999999;
  padding: 4px;
  border-radius: 4px;
  opacity: 0.6 ;
  color: #ffffff;
  font-size: 0.675rem;
  transition: transform 1s ease-in-out 0.1s;
  transform: ${(props) => props.open ? "translateY(0px)" : "translateY(30px)"};
   z-index: 98;
`
export const MemberContainer = styled.div`
  width: 360px;
  background: #ffffff;
  border-left: 1px solid ${(props) => props.theme.gray1};

  @media only screen and (max-width: 1400px) {
    display: none;
  }

  display: ${(props) => props.notShow && "none"};
`

export const MoveFolderContainer = styled.div`
  width: 320px;
  height: 145px;

  >.label {
    color: ${(props) => props.theme.gray6};
    padding: 8px;
    font-size: ${(props) => props.theme.textSm};
    font-weight: bold;
  }

  >.separator {
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.gray1};
  }

  .error {
    display: ${(props) => !props.showError && "none"};
    font-size: 0.7rem;
    color: ${(props) => props.theme.error};
    text-align: center;
    height: 16px;
  }
`

export const MemberInfoButton = styled(NewBoxButton)`
  display: none;
  >.icon {
    font-size: 0.8rem;
  }

  @media only screen and (max-width: 1400px) {
    display: unset;
  }

  &:hover {
    background: ${(props) => {
      if (props.inverted) {
        return "rgba(255, 255, 255, 0.1)"
      }
      return props.$on ? props.theme.themeColorBlue : props.theme.gray05
    }};
    .icon {
      color: ${(props) => {
        return props.$on ? "white" : props.theme.themeColorBlue
      }};
    }
  }
`

export const TransitionMemberContainer = styled.div`
  width: ${(props) => props.mobile ? "100vw" : "360px"};
  background: #ffffff;
  border-left: 1px solid ${(props) => props.theme.gray1};
  position: ${(props) => props.mobile ? "fixed" : "absolute"};
  top: 0;
  right: 0;
  overflow-y: hidden;
  min-width: 360px;
  height: 100%;
  transform: ${props => (props.show ? "translateX(0px)" : "translateX(100%)")};
  transition: opacity 0.4s ease, transform 0.4s ease-in-out;
  z-index: 9999;
`

export const Folder = styled.div`
  position: absolute;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  background: ${(props) => props.color && props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

export const AssignedToButton = styled(NewBoxButton)`
  &:focus {
    box-shadow: none;
  }
  >.text {
    @media only screen and (max-width: 1500px) {
      display: none;
    }
  }

  >.icon {
    font-size: 0.8rem;
    
    @media only screen and (max-width: 1500px) {
      margin-right: 0;
    }
 }
 &:hover {
    background: ${(props) => {
    if (props.inverted) {
      return "rgba(255, 255, 255, 0.1)"
    }
    return props.$on ? props.theme.themeColorBlue : props.theme.gray05
  }};
    .icon {
      color: ${(props) => {
    return props.$on ? "white" : props.theme.themeColorBlue
  }};
    }
  }
`

export const MoveToButton = styled(NewBoxButton)`
  width: 32px;
  height: 32px;
  .svg-container {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    .svg-icon {
      width: 100%;
      height: 100%;
      filter: invert(38%) sepia(0%) saturate(2492%) hue-rotate(357deg) brightness(108%) contrast(85%);
    }
  }

  &:hover {
    .svg-container {
      .svg-icon {
        filter: ${(props) => {
          return props.$on ? "invert(100%) sepia(100%) saturate(1%) hue-rotate(178deg) brightness(108%) contrast(101%)" : "invert(45%) sepia(56%) saturate(7323%) hue-rotate(223deg) brightness(92%) contrast(95%)"
        }
      }
    }
  }
`

export const AssignedToButtonWithRemoveButton = styled.div`
  display: flex;
  margin-right: 8px;

  .left-button {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .right-button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
  }

`

export const LiveChatButton = styled(NewBoxButton)`
  margin-right: 8px;
  background: ${(props) => props.isOn && props.theme.themeColorBlue};
  border: 1px solid ${(props) => props.isOn ? props.theme.themeColorBlue : props.theme.gray2};
  padding: 6px 8px;

  >.text {
    color: ${(props) => props.isOn && "#ffffff"};

   @media only screen and (max-width: 1500px) {
      display: none;
    }
  }

  >.icon {
    color: ${(props) => props.isOn && "#ffffff"};
    font-size: 0.8rem;
    width: 14px;
    height: 14px;

    @media only screen and (max-width: 1500px) {
      margin-right: 0;
    }
  }

  .loading-icon {
    color: ${(props) => {
    if (props.isOn) {
      return "#ffffff !important"
    }
    return props.theme.gray10
  }};
  }

  &:hover {
    ${(props) => {
      if (props.isOn) {
        return css`
          background: ${props.disabled ? props.theme.themeColorBlue : setLightness(0.58, props.theme.themeColorBlue)};
          border: ${props.theme.themeColorBlue};

          >.icon, >.text {
            color: "#ffffff";
          }
        `
      } 
      return css`
        >.icon, >.text {
          color: ${!props.disabled && props.theme.themeColorBlue};
        }
      `
    }}
  }

  &:focus {
    outline: 0;
    box-shadow: ${(props) => props.isOn && "0 0 0 0.2rem #c1dbad"};
  }
`

export const CharacterCounter = styled.div`
  color: ${(props) => props.theme.gray5};
  padding: 8px 0;
  margin: 0 8px;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  display: flex;
  cursor: default;
`

export const SendMessageButton = styled.div`
  background: ${(props) => {
    if (props.disabled) {
      return "#ccc"
    }
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return props.theme.themeColorBlue
      }
      return props.color
    }
    return props.theme.themeColorBlue
  }};
  font-size: 14px;
  padding: 10px 8px;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: ${(props) => props.disabled ? "default" : "pointer"};

  &:hover {
    background: ${(props) => {
    if (props.disabled) {
      return "#ccc"
    }
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return setLightness(0.42, props.theme.themeColorBlue)
      }
      return props.color
    }
    return setLightness(0.42, props.theme.themeColorBlue)
  }};
  }

  >span {
    font-size: 0.8rem;
    font-weight: bold;
  }
`

export const Separator = styled.div`
  width: 1px;
  height: 10px;
  background: white;
`

export const SendOptionsButton = styled.div`
  height: 36px;
  width: 35px;
  background: ${(props) => {
    if (props.disabled) {
      return "#ccc"
    }
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return props.theme.themeColorBlue
      }
      return props.color
    }
    return props.theme.themeColorBlue
  }};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => props.disabled ? "default" : "pointer"};

  &:hover {
    background: ${(props) => {
    if (props.disabled) {
      return "#ccc"
    }
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return setLightness(0.42, props.theme.themeColorBlue)
      }
      return props.color
    }
    return setLightness(0.42, props.theme.themeColorBlue)
  }};
  }

  .icon {
    padding: 10px 8px;
  }

  &:before {
    content: "";
    width: 1px;
    height: 20px;
    background: white;
  }
`

export const SendOptionItem = styled.div`
  color: ${(props) => props.theme.gray9};
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  font-size: 14px;

  .icon {
    color: #eeed9d;
    margin-right: 8px;
  }

  &:hover {
    background: ${(props) => setLightness(0.95, props.theme.themeColorGreen)};
  }
`
