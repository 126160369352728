import graphql from "babel-plugin-relay/macro"

export const SavePushTokenMutation = graphql`
  mutation InboxQuery_savePushTokenMutation (
    $input: SavePushTokenInput!
  ) {
    savePushToken(input: $input) {
      ok
      error {
        message
      }
    }
  }
`

export const ToggleNotificationsMutation = graphql`
  mutation InboxQuery_toggleNotificationsMutation (
    $input: ToggleNotificationsInput!
  ) {
    toggleNotifications(input: $input) {
      ok
      error {
        message
      }
    }
  }
`

export const ThreadsUpdateThreadFolderMutation = graphql`
   mutation InboxQuery_updateThreadFolderMutation (
    $input: UpdateThreadFolderInput!
  ) {
    updateThreadFolder(input: $input) {
      clientMutationId
      thread {
        threadId
        folderId
        folder {
          folderId
          name
          color
        }
        wMemberId
        wChannelId
        wChannel {
          name
          integrationIcon
          integrationName
        }
        etag
        title
        updatedAt
        lastChatAt
        lastMessage
        member {
          meta
          externalId
          externalProfile
          inboxProfile {
            firstName
            lastName
            name
            profilePicUrl
          }
        }
      }
    }
  }
`

export const ThreadsUpdateThreadsFolderMutation = graphql`
   mutation InboxQuery_updateThreadsFolderMutation (
    $input: UpdateThreadsFolderInput!
  ) {
    updateThreadsFolder(input: $input) {
      clientMutationId
      ok
      threads {
        threadId
        folderId
        folder {
          folderId
          name
          color
        }
        wMemberId
        wChannelId
        wChannel {
          name
          integrationIcon
          integrationName
        }
        etag
        title
        updatedAt
        lastChatAt
        lastMessage
        member {
          meta
          externalId
          externalProfile
          inboxProfile {
            firstName
            lastName
            name
            profilePicUrl
          }
        }
      }
    }
  }
`

export const DeleteFolderMutation = graphql`
   mutation InboxQuery_deleteFolderMutation (
    $input: DeleteFolderInput!
  ) {
    deleteFolder(input: $input) {
      clientMutationId
      ok
      error {
        message
      }
    }
  }
`

export const MentionSubscription = graphql`
  subscription InboxQuery_mentionSubscription {
    mention {
       mention {
        _id
        mentionedAt
        readAt
        authorId
        thread {
          title
          threadId
          folderId
        }
        author {
          wUserId
          profilePic
          email {
            email
          }
          firstName
          lastName
        }
        chat {
          messageEvent
          mentions {
            wUserId
            firstName
            lastName
            email {
                email
            }
          }
        }
      }
    }
  }
`

export const MentionCountSubscription = graphql`
  subscription InboxQuery_mentionCountSubscription {
    mentionCount {
      count
    }
  }
`

export const BackgroundTaskSubscription = graphql`
  subscription InboxQuery_backgroundTaskSubscription($backgroundTaskId: ID!) {
    backgroundTask(backgroundTaskId: $backgroundTaskId) {
      backgroundTask {
        id
        status
      }
      updateThreads {
          ok
          error {
            code
            message
          }
          threads {
            id
          }
          threadsConflictResult {
            conflicts {
            channelId
            channelName
            hiddenCount
            totalCount
            threads {
              threadId
              folderId
              folder {
                folderId
                name
                color
              }
              wMemberId
              wChannelId
              wChannel {
                name
                integrationIcon
                integrationName
              }
              etag
              title
              updatedAt
              lastChatAt
              lastMessage
              lastReadAt
              unreadCount
              title
              member {
                meta
                externalId
                externalProfile
                inboxProfile {
                  firstName
                  lastName
                  name
                  profilePicUrl
                }
              }
            }
          }
        }
        backgroundTaskId
        destinationFolder {
          id
          name
          folderId
        }
      }
    }
  }
`
