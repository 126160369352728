import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled, { keyframes } from "styled-components"
import theme, { resourceTemplateTheme } from "../../../stylesheets/theme"
import { ReactLive } from "../../ReactLive"
import MediaDownloadWrapper from "../MediaDownload/MediaDownload"
import { useFetch } from "../../../hooks/useFetch"
import { WhatsappMarkdown } from "../WhatsappMarkdown/WhatsappMarkdown"
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import { randomString } from "../../util"
import { MOBILE_BREAKPOINT, MOBILE_MESSAGE_MAX_WIDTH } from "../../../const"

const SPACING = "8px"
const SPACING_LARGE = "16px"
const BORDER_WIDTH = "4px"

const ACCENT_COLOR = resourceTemplateTheme.bot.whatsapp.replyToColors.accent

const BOT_TEXT_COLOR = resourceTemplateTheme.bot.whatsapp.replyToColors.textForeground
const MEMBER_TEXT_COLOR = resourceTemplateTheme.user.whatsapp.replyToColors.textForeground

const BOT_BACKGROUND_COLOR = resourceTemplateTheme.bot.whatsapp.replyToColors.textBackground
const MEMBER_BACKGROUND_COLOR = resourceTemplateTheme.user.whatsapp.replyToColors.textBackground

const FONT_SIZE = "px"

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const ReplyToContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING};
  background-color: ${props => (props.isBot ? BOT_BACKGROUND_COLOR : MEMBER_BACKGROUND_COLOR)};
  border-left: ${BORDER_WIDTH} solid ${ACCENT_COLOR};
  border-radius: ${SPACING};
  gap: ${SPACING};
  flex: 1;
  margin-bottom: 4px;
  opacity: ${p => (p.isBot ? "0.8" : "1")};
  min-width: 200px;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    min-width: auto;
    max-width: ${p => p.isTextReply ? `calc(${MOBILE_MESSAGE_MAX_WIDTH} - 20px)` : MOBILE_MESSAGE_MAX_WIDTH};
  }
`

const ReplyToUserName = styled.div`
  color: ${ACCENT_COLOR};
  font-weight: 600;
  font-size: ${FONT_SIZE};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

const ReplyToContent = styled.div`
  color: ${p => (p.isBot ? BOT_TEXT_COLOR : MEMBER_TEXT_COLOR)};
  * {
    color: ${p => (p.isBot ? BOT_TEXT_COLOR : MEMBER_TEXT_COLOR)} !important;
  }
  div p span {
    color: ${p => (p.isBot ? `${BOT_TEXT_COLOR} !important` : `${MEMBER_TEXT_COLOR} !important`)};
    text-decoration: none;
  }
`

function ReplyPreview({
  fileId,
  chatId,
  isConversation,
  from,
  ...outerProps
}) {
  const MediaDownload = MediaDownloadWrapper({
    fileId,
    chatId,
    isConversation,
    from,
    isReply: true,
  })
  return ({ ...innerProps }) => (
    <ReplyPreviewInner
      MediaDownload={MediaDownload}
      {...outerProps}
      {...innerProps}
    />
  )
}

function ReplyPreviewInner({
  replyToRtPreview,
  data,
  replyToFrom = null,
  replyToType = null,
  replyWithType = null,
  isBot = false,
  MediaDownload,
  payload,
  signedContext,
}) {
  if (!replyToRtPreview) {
    console.error("reply resource template or scope not passed to react live")
    console.log({ replyToRtPreview })
    return null
  }
  const modData = {
    ...data,
    isInbox: true,
  }
  return (
    <ReplyToContainer isBot={isBot} isTextReply={replyWithType === "TEXT"}>
      {(replyToFrom) && (
        <ReplyToUserName>{replyToFrom}</ReplyToUserName>
      )}
      <ReplyToContent isBot={isBot}>
        <ReactLive
          useV2ReactLive={false}
          resourceTemplate={{
            preview: replyToRtPreview,
            alias: `reply-to-${replyToType}`,
          }}
          scope={{
            IconAndMessageType,
            WhatsappMarkdown,
            FontAwesomeIcon,
            styled,
            replyToRtPreview,
            data: modData,
            replyToFrom,
            MediaDownload,
            useFetch,
            payload,
            signedContext,
            theme: {
              ...resourceTemplateTheme.bot,
              user: {
                ...resourceTemplateTheme.user,
              },
              bot: {
                ...resourceTemplateTheme.bot,
              },
            },
          }}
        />
      </ReplyToContent>
    </ReplyToContainer>
  );
}

const ReplyToContextWindow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING};
  background-color: ${MEMBER_BACKGROUND_COLOR};
  border-left: 6px solid ${ACCENT_COLOR};
  gap: ${SPACING};
  max-height: 300px;
  margin-top: ${SPACING};
  margin-left: ${SPACING_LARGE};
  margin-right: ${SPACING_LARGE};
  animation: ${slideUp} 0.2s ease-in-out;
`

const ReplyToContextContent = styled.div`
  color: ${MEMBER_TEXT_COLOR};
  font-size: 12px;
  max-height: 200px;
  overflow: auto;
  div {
    background-color: ${MEMBER_BACKGROUND_COLOR};
    color: ${MEMBER_TEXT_COLOR};
  }
`

const ReplyToContextCloseButtonWrapper = styled.div`
  margin-right: ${SPACING};
  cursor: pointer;
  color: ${theme.gray3};
  &:hover {
    opacity: 0.8;
  }
`

function ReplyToContextCloseButton({ onClick }) {
  return (
    <ReplyToContextCloseButtonWrapper onClick={onClick}>
      <FontAwesomeIcon size="lg" icon={faCircleXmark} />
    </ReplyToContextCloseButtonWrapper>
  )
}


function IconAndMessageType({ heading, faIcon }) {
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}>
      <FontAwesomeIcon size="xs" icon={faIcon} />
      <div style={{ fontSize: "12px" }}>{heading}</div>
    </div>
  )
}

function ReplyToContext({ setReplyToContext, replyToContext, isWhatsappCloudThread }) {
  if (!isWhatsappCloudThread) {
    return null
  }
  const replyToType = replyToContext?.type
  const modData = {
    ...(replyToContext?.data ?? {}),
    isInboxReplyContextPreview: true,
    isInbox: true,
  }

  return (
    <ReplyToContextWindow>
      <ReplyToContextContent>
        <ReactLive
          useV2ReactLive={false}
          resourceTemplate={{
            preview: replyToContext?.messagePreview,
            alias: `reply-to-${replyToType}`,
          }}
          scope={{
            IconAndMessageType,
            // NOTE: Media download is not used but must be in scope
            MediaDownload: () => null,
            // NOTE: payload is not used but must be in scope
            payload: {},
            // NOTE: signedContext is not used but must be in scope
            signedContext: randomString(8),
            // NOTE: replyToFrom is not used but must be in scope
            replyToFrom: null,
            WhatsappMarkdown,
            FontAwesomeIcon,
            useFetch,
            styled,
            data:  modData,
            //replyToFrom,
            theme: {
              ...resourceTemplateTheme.bot,
              user: {
                ...resourceTemplateTheme.user,
              },
              bot: {
                ...resourceTemplateTheme.bot,
              },
            },
          }}
        />
      </ReplyToContextContent>
      <ReplyToContextCloseButton onClick={() => setReplyToContext(null)} />
    </ReplyToContextWindow>
  )
}

export { ReplyPreview, ReplyToContext };
