import graphql from "babel-plugin-relay/macro"

export const MemberEditModalUpdateMemberMutation = graphql`
  mutation MemberEditModalQuery_updateMembertMutation (
    $input: UpdateMemberInput!
  ) {
    updateMember(input: $input) {
      clientMutationId
      error {
        message
        code
      }
      thread {
        etag
        threadId
        wMemberId
        wChannelId
        title
        updatedAt
        lastChatAt
        lastMessage
        member {
          meta
          externalId
          externalProfile
          inboxProfile {
            firstName
            profilePicUrl
          }
        }
      }
      member {
        externalId
        id
        meta
        externalId
        wMemberId
        wChannelId
        wAppId
        externalProfile
        inboxProfile {
          website
          company
          firstName
          lastName
          profilePicUrl
          gender
          dob {
            date
            month
            year
          }
          locale
          phones {
            number
            alias
          }
          emails {
            email
            alias
          }
          addresses {
            alias
            line1
            line2
            city
            state
            country
            zip
          }
        }
        tags
        liveChat
        notes {
          _id
          content
          updatedAt
          authorId
          author {
            email {
              email
            }
          }
          etag
        }
        etag
      }
    }
  }
`

export const MemberEditModalSignUrlMutation = graphql`
  mutation MemberEditModalQuery_signUrlMutation ($input: SignUrlInput!) {
   signUrl(input: $input) {
     signUrl
    }
  }
`
